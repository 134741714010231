.profile {
    padding-left: 10px;
}

.text-color {
    color: #242c5c;
}

.title {
    font-size: 26px;
}

.name-title {
    font-size: 11px;
    margin-top: 20px;
}

.email-title {
    font-size: 11px;
    margin-top: 12px;
}

.email-info {
    font-weight: 500;
    font-size: 16px;
    margin-top: -6px;
}

.name-info {
    font-size: 20px;
    font-weight: 500;
    margin-top: -6px;
}

.default-button {
    color: #3e6fd9;
    width: auto;
    min-width: auto;
}

.default-button:hover {
    color: white;
}

.reset-password-button {
    margin-top: 15px;
}

.edit-profile-button {
    margin-top: 20px;
}
