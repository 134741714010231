.header {
    color: #242c5c;
    font-size: 26px;
}

.promo-code {
    background-color: #e2eafc;
    height: 76px;
    margin-top: 22px;
}

.promo-code span {
    color: #242c5c;
    font-size: 20px;
    padding-left: 33px;
    padding-right: 40px;
    font-weight: 500;
}

.promo-code-button {
    color: #3e6fd9;
    margin-left: 5px;
    width: 90px;
    font-weight: 500;
}

.promo-code-button:hover {
    color: white;
}

.promo-code-field {
    width: 215px;
    font-weight: 500;
    border: 2px #9eb6ec solid;
}

.promo-code-field::placeholder {
    color: #3e6fd9;
    font-weight: 500;
}

.promo-code-field:focus,
.promo-code-field:hover {
    border-color: #3e6fd9;
}

.subscription_block {
    min-height: 310px;
    border-color: #c6cbed;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 28px 33px;
    margin-top: 20px;
}

.header-active-subscription {
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    text-align: start;
    display: flex;
}

.body-active-subscription {
    margin-top: 20px;
    min-height: 220px;
}

.left-body-active-subscription {
    min-width: 255px;
}

.active-plan-header {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #242c5c;
    text-align: start;
    display: flex;
}

.active-plan-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #242c5c;
    list-style-type: '– ';
    min-width: 240px;
}

.active-plan-items {
    margin-top: 10px;
    padding-left: 13px;
}

.credits-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
}

.credits {
    font-size: 30px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    margin-top: 20px;
}

.credits-details {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    width: 120px;
    margin-top: 7px;
}

.credits-buy {
    border-color: #f1666a;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #fde8e9;
    font-size: 16px;
    text-align: left;
    color: #f1666a;
    width: 205px;
    height: 43px;
    font-weight: 500;
    padding-left: 12px;
    margin-top: 29px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mt-20 {
    margin-top: 20px;
}

.credits-buy:hover {
    color: white;
    background-color: #cc4b4f;
}

.billing-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #242c5c;
    width: 140px;
}

.billing-description-right {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-left: -26px;
    width: 140px;
    text-align: right;
    color: #242c5c;
}

.active-plan-body {
    margin-top: 16px;
}

.get-billing-details {
    width: 236px;
    height: 43px;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #3e6fd9;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    margin-top: 10px;
}

.cancel-subscription-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #3e6fd9 !important;
    text-align: start;
    display: flex;
    margin-top: 10px;
}

.cancel-subscription-link:hover {
    cursor: pointer;
}

.border-active-subscription {
    border-left: 2px #eceef5 solid;
    padding-left: 25px;
}

.border-ai-credits {
    border: 1px #c6cbed solid;
    border-radius: 5px;
    padding: 25px;
}

.padding-credits {
    padding-right: 30px;
}

.padding-15 {
    padding: 15px;
}

.bulling-button-dark-blue {
    width: 168px;
    height: 43px;
    font-weight: 500;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #3e6fd9;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
}

.bulling-button-blue {
    color: #3e6fd9;
    margin-left: 5px;
    width: 168px;
    height: 43px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 0;
}

.bulling-button-blue:hover {
    color: white;
}

.billing-left-button {
    margin-left: -20px;
}

.billing-right-button {
    margin-left: 0 !important;
}

.buy-subscription {
    margin-top: 27px;
}

.page {
    min-width: 1200px;
}

.billing-left-blue {
    margin-left: -5px;
}

.ml-10 {
    margin-left: 10px;
}

.plan {
    width: 335px;
    min-height: 540px;
    padding: 27px 36px 27px;
    border-color: #c6cbed;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    filter: drop-shadow(0px 1px 0.5px #c6cbed);
    background: #ffffff;
    margin-top: 10px;
    margin-bottom: 20px;
}

.plan-header {
    font-size: 20px;
    font-weight: normal;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
    font-weight: 500;
}

.plan-amount {
    font-size: 30px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
}

.plan-period {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    margin-left: 12px;
}

.plan-prev {
    margin-top: 14px;
}

.plan-billed {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 4px;
    text-align: left;
    color: #242c5c;
}

.plan-buy {
    width: 168px;
    height: 43px;
    font-weight: 500;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    background: #3e6fd9;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    min-width: 100%;
    margin-top: 8px;
}

.get-billing-details:hover,
.plan-buy:hover {
    background-color: #3061ae;
    color: white;
}

.plan-descriptions {
    margin-left: -25px;
    margin-top: 20px;
}

.plan-credits-description {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    display: flex;
    width: 270px;
    color: #242c5c;
    font-weight: 500;
}

.select-amount {
    padding-left: 27px;
    margin-top: 10px;
    font-weight: 500;
}

.select-amount:hover {
    border-color: #f1666a;
    color: #f1666a;
    background-color: #fde8e9;
}

.plan-credits-buy {
    border-top: 2px #c6cbed solid;
    padding-top: 15px;
}

.select-credits {
    align-items: center;
    height: 39px;
    padding-left: 17px;
    padding-right: 20px;
    background: #fafafa;
    border-color: #f1f1f1;
    border-width: 1px;
    border-style: solid;
    font-size: 17px;
    font-weight: 500;
    line-height: 40px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
}

.select-credits:hover {
    background-color: #ffffff;
    cursor: pointer;
}

button:active,
button:focus {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}

.select-credits-block {
    position: absolute;
    bottom: 69px;
    width: 261px;
    filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
}

.select-amount-active {
    border-color: #f1666a;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #fde8e9;
    align-items: center;
    display: flex;
    width: 250px;
    height: 43px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    border: 0;
    background-color: #fafafa;
}

.select-amount-active:hover {
    background-color: #fafafa;
    color: #242c5c;
}

.arrow-icon {
    width: 30px;
    height: 42px;
    position: relative;
}

.select-credits-label {
    display: flex;
    justify-content: space-between;
    width: 195px;
}
