.plan-dialog {
    width: 348px;
    height: 308px;
}

.header-dialog {
    font-size: 24px;
    font-weight: 500;
}

.dialog-close {
    width: 22px;
}

.top-dialog {
    padding: 30px;
    height: 90px;
    background-color: #eceef5;
}

.modal-adding-part {
    height: 218px;
    padding: 30px;
    padding-top: 16px;
}

.number-field {
    margin-top: 20px;
    margin-left: 20px;
}

.type-plan {
    width: 371px;
}

.plan-subscription {
    min-height: 500px;
    margin-top: 20px;
    padding: 20px 30px;
    width: 300px;
    background-color: white;
}

.plan-tokens-dialog {
    width: 346px;
    height: 308px;
}

.modal-adding-tokens-part {
    height: 220px;
    padding: 30px;
    padding-top: 15px;
}

.plan-monthly {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 100;
}

.small-description-input {
    height: 26px !important;
    border: 0 !important;
    background-color: #f6f7f9 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    text-align: left;
    color: #242c5c !important;
    padding-left: 15px !important;
    margin-left: -15px;
    width: 256px !important;
}

.save-description {
    background-color: #3e6fd9;
    height: 26px;
    position: absolute;
    outline: 0 !important;
    border: 0;
    left: 286px;
    width: 22px;
}

.save-description:hover {
    background-color: #3061ae;
}

.active-plan-item:hover {
    cursor: pointer;
    color: #3e6fd9;
}

.plan {
    margin-bottom: 50px;
}

.plus-icon {
    width: 18px;
    position: absolute;
}

.close-description {
    background-color: #fde8e9;
    height: 26px;
    position: absolute;
    outline: 0 !important;
    border: 0;
    left: 264px;
    width: 22px;
    z-index: 2;
}

.close-description:hover {
    fill: white !important;
    background-color: #cc4b4f;
}

.close-description:hover .icon-close {
    fill: white;
}

.icon-close {
    fill: #f1666a;
}

.remove-description-field {
    height: 43px !important;
    min-height: 43px !important;
    max-height: 43px !important;
    margin-right: 0 !important;
    width: 130px !important;
    font-weight: 500 !important;
    border-radius: 0;
}

.add-description-field {
    max-width: 130px !important;
    min-width: 130px !important;
    margin-top: 0 !important;
}
