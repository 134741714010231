.header-body {
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    text-align: center;
    color: #242c5c;
    margin-top: 39px;
}

.table {
    width: 539px;
    height: 258px;
    border-color: #cdd1e5;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    background: #f7f8fa;
    margin-top: 23px;
    padding-top: 38px;
}

.table-item {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
    margin-left: 49px;
    max-width: 420px;
    display: flex;
}

.circle {
    min-width: 16px;
    margin-right: 16px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #cdd1e5;
}

.cancel-button {
    width: 539px;
    height: 58px;
    font-size: 20px;
    margin-top: 40px;
}

.dialog {
    height: 551px;
    width: 662px;
}
