.header {
    height: 85px;
    padding: 0px 42px;
    background-color: #eceef5;
}

.dialog {
    width: 622px;
    height: 566px;
}

.next-dialog {
    width: 622px;
    height: 515px;
}

.title-header {
    color: #242c5c;
    font-weight: 500;
    margin-left: 10px;
    font-size: 30px;
}

.description-span {
    width: 400px;
    font-weight: 500;
    margin-top: 12px;
}

.default-color {
    color: #242c5c;
}

.confirm-span {
    margin-top: 40px;
}

.main-span {
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
}

.tariff-block {
    width: 163px;
    height: 193px;
    border: 2px #cdd1e5 solid;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 28px;
}

.tariff-left-margin {
    margin-left: 17px;
}

.check_mark {
    width: 16px;
    height: 16px;
    display: flex;
    border-color: #cdd1e5;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    margin-bottom: 14px;
}

.default-button {
    width: 524px !important;
    height: 58px !important;
    font-size: 21px;
}

.icon-check_mark {
    margin-bottom: 12px;
}

.close-icon {
    padding: 10px;
    margin-right: -10px;
}

.tariff-block:hover,
.close-icon:hover {
    cursor: pointer;
}

.tariff-info {
    color: #242c5c;
    font-size: 18px;
    font-weight: 500;
}

.total-table {
    width: 540px;
    height: 223px;
    background: #f7f8fa;
    border: 5px #cdd1e5 solid;
    border-width: 2px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 42px;
}

.credits-block {
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 30px;
}

.credits-block-bottom {
    margin-top: 20px;
    margin-bottom: 10px;
}

.total-block {
    margin-left: 30px;
    margin-bottom: 26px;
    margin-right:  30px;
    border-top: 2px #CDD1E5 solid ;
    border-radius: 1px;
    padding-top: 15px;
}

.credits-block span {
    color: #242c5c;
    font-weight: 500;
    font-size: 18px;
}

.total-block span {
    color: #242c5c;
    font-weight: 600;
    font-size: 18px;
}

.purchase-button {
    margin-top: 28px;
    min-width: 544px;
    border: 0;
}

.credits-block-subscription {
    margin-top: 20px;
}

.total-table-subscription {
    margin-top: 28px;
}