.header {
  width: 100%;
  background-color: rgb(36, 44, 92);
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: 600;
}

.header-logo {
  img {
    height: 25px;
    width: 25px;
    margin-top: 3px;
    margin-right: 5px;
  }
}

.header-title {
  font-weight: 500;
}