@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f7f8fa !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}
.divider {
  margin: 0;
}
.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed !important;
}

.flex {
    display: flex;
}

.display-hide-hard {
    display: none !important;
}

.inline-flex {
    display: inline-flex;
}

.ml-60px {
    margin-left: 60px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 19px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.align-center {
    align-items: center;
}

.align-flex-end {
    align-items: flex-end;
}

.align-flex-start {
    align-items: flex-start;
}

.align-baseline {
    align-items: baseline;
}

.dialog-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    border-radius: 4px;
    background-color: white;
}

.dialog-content input,
textarea {
    outline: none !important;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
}

.dialog-overlay.draggable {
    overflow: hidden;
}

.dialog-content.draggable {
    margin: 0;
}

.ml-0 {
    margin-left: 0;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-flex-end {
    justify-content: flex-end;
}

.justify-flex-start {
    justify-content: flex-start;
}

.width-100 {
    width: 100%;
}

.force-width-100 {
    width: 100% !important;
}

.height-100 {
    height: 100%;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.disable-pointer-events {
    pointer-events: none;
}

.underlined {
    text-decoration: underline;
}

.user-select-none {
    -webkit-user-select: none;
            user-select: none;
}

.white-spice-nowrap {
    white-space: nowrap;
}

.dark-blue-btn {
    color: white;
    background-color: #3e6fd9;
    outline: none !important;
}

.dark-blue-btn:hover {
    background-color: #3061ae;
}

.transparent-btn {
    background-color: transparent;
    color: rgb(136, 141, 168);
    outline: none !important;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

.ellipsed-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.pd-20 {
    padding: 20px;
}

.logo {
    height: 80px;
    -webkit-user-select: none;
            user-select: none;
    align-self: flex-start;
    margin-left: 15px;
}

.logo img {
    pointer-events: none;
    height: 100%;
}

.bg-white {
    background-color: #fff;
}

.overflow-visible {
    overflow: visible !important;
}

.height-auto-important {
    height: auto !important;
}

.text-align-center {
    text-align: center;
}

.text-align-start {
    text-align: start;
}

.widht-50 {
    width: 50%;
}

.mb-10px {
    margin-bottom: 10px;
}

.mb-0 {
    margin-bottom: 0;
}

.border-0 {
    border: 0;
}

.justify-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.margin-left-20px {
    margin-left: 20px;
}

.ml-80px {
    margin-left: 80px;
}

.ml-250px {
    margin-left: 220px;
}

.max-width-250px {
    max-width: 250px;
}

.delete-popup.delete-conformation-modal {
    height: 250px;
}

.delete-popup-text.span-delete-modal {
    height: auto;
    width: 400px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-0 {
    padding-top: 0;
}

.hide:hover,
.hide:active,
.hide {
    height: 0;
    width: 0;
    border: 0;
    padding: 0;
    background-color: none;
}
.background-grey {
    background-color: #eceef5;
}

.font-size-20 {
    font-size: 20px;
}

.mt-120px {
    margin-top: 120px !important;
}

.mt-25px {
    margin-top: 25px;
}

.white-no-wrap {
    white-space: nowrap;
}

.icon-17px {
    margin-right: 8px;
    width: 17px;
}

.icon-32px {
    margin-right: 8px;
    width: 32px;
}

.tooltip-message.white {
    width: auto;
    max-width: 300px;
    background-color: white;
    color: #3e6fd9;
}

.tooltip-message.white.top::after {
    border-color: white transparent transparent transparent;
}

.tooltip-message.white.left::after {
    border-color: transparent transparent transparent white;
}

.tooltip-message.white.right::after {
    border-color: transparent white transparent transparent;
}

.tooltip-message.white.bottom::after {
    border-color: transparent transparent white transparent;
}

.ai-generate-modal-window-message {
    font-size: 16px;
    color: #9880ff;
}

.video {
    max-width: 60vw;
}

.login-form {
    width: 357px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 90px;
    margin-top: 80px;
}

.login-title {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
}

.login-text span,
.login-text a {
    height: 23px;
    color: #242c5c;
    font-size: 17px;
}

.login-text .custom-checkbox {
    margin-right: 20px;
}

.login-links {
    padding: 0 5px;
}

.login-links .login-link {
    height: 23px;
    color: #888da8;
    font-size: 17px;
    white-space: nowrap;
}

.login-button {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 48px;
    background-color: #3e6fd9;
    outline: none !important;
}

.login-button span {
    height: 24px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 18px;
}

.login-form-container {
    width: 100%;
}

.div-notification {
    position: fixed;
    top: 20px;
    right: 20px;
}

.error-message {
    text-align: left;
}

.visibility-button {
    position: absolute;
    height: 41px;
    width: 41px;
    right: 0;
    top: 0;
    border: none;
    background-color: transparent;
    padding-right: 12px;
    outline: none !important;
}

.visibility-button img {
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
    height: 19px;
    width: 23px;
}

.language-login {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 17px;
}

.flag-icon-login {
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-left: 5px;
}

.language-title{
    margin-right: 5px;
    margin-left: 7px;
    cursor: pointer;
    text-decoration: underline;
}

.right-flag {
    margin-left: 5px;
}

.other-application-login {
    padding-bottom: 20px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    width: 100%;
}

.other-application-login__title-text {
    background-color: white;
    position: relative;
    bottom: 14px;
    height: 0;
}
.custom-login-input {
    width: 100%;
    height: 41px;
    border: 2px solid #d9dcf5;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 4px 17px 0 17px;
    color: #242c5c;
    font-size: 17px;
    outline: none;
}

.custom-login-input.has-errors {
    border: 2px solid #f1666a;
}

.custom-login-input::-webkit-input-placeholder {
    color: #8a93c3;
}

.custom-login-input::placeholder {
    color: #8a93c3;
}

.custom-login-input-errors {
    width: 100%;
    height: 41px;
    min-height: 41px;
    border-radius: 0 0 4px 4px;
    background-color: #f6e2e5;
}

.custom-login-input-errors span {
    height: 23px;
    color: #f1666a;
    font-size: 17px;
}

.custom-password-input {
    width: 100%;
    height: 41px;
    border: 2px solid #d9dcf5;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 4px 17px 0 17px;
    color: #242c5c;
    font-size: 17px;
    outline: none;
}

.custom-password-input.has-errors {
    border: 2px solid #f1666a;
}

.custom-password-input::-webkit-input-placeholder {
    color: #8a93c3;
}

.custom-password-input::placeholder {
    color: #8a93c3;
}

.custom-password-input-errors {
    width: 100%;
    height: 41px;
    min-height: 41px;
    border-radius: 0 0 4px 4px;
    background-color: #f6e2e5;
}

.custom-password-input-errors span {
    height: 23px;
    color: #f1666a;
    font-size: 17px;
}

.login-wrapper {
    font-family: 'Inter';
    width: 1680px;
    height: 1150px;
    width: 100vw;
    height: 100vh;
    background-color: #f7f8fa;
    position: absolute;
    left: 0;
}

.login-logo {
    position: absolute;
    left: 35px;
    top: 35px;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.login-illustration-container {
    min-width: 50vw;
    max-width: 50vw;
    justify-content: center;
    align-items: flex-end;
    background-color: #242c5c;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.login-illustration-container img {
    max-width: 100%;
    max-height: 100%;
}

.contact-us-popup {
    width: 472px;
    height: 227px;
    border-radius: 4px;
    overflow: hidden;
    font-family: 'Inter';
    padding: 20px;
}

.contact-us-popup button {
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 357px;
    height: 56px;
    background-color: #3e6fd9;
}

.contact-us-popup button span {
    height: 30px;
    font-size: 22px;
    text-transform: uppercase;
}

.contact-us-popup-close {
    justify-content: flex-end;
    min-height: 20px;
}

.contact-us-popup-text {
    width: 380px;
    color: #465664;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
}

.contact-us-popup-button {
    outline: none !important;
}

.delete-popup {
    width: 472px;
    height: 207px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    font-family: 'Inter';
    padding: 20px;
}

.delete-popup button {
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 357px;
    height: 56px;
    background-color: #3e6fd9;
}

.delete-popup button span {
    height: 30px;
    font-size: 22px;
    text-transform: uppercase;
}

.delete-popup-close {
    justify-content: flex-end;
    min-height: 20px;
}

.delete-popup-text {
    margin-bottom: 24px;
    width: 271px;
    height: 55px;
    color: #465664;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 10px;
}

.delete-popup-button {
    outline: none !important;
}

.login-text .terms-and-conditions {
    height: auto;
    line-height: 21px;
    text-align: left;
}

.terms-and-conditions.red,
.terms-and-conditions.red a {
    color: #f1666a;
}

.language-sign-up {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 17px;
}

.flag-icon-sign-up {
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-left: 5px;
}

.underlined {
    margin: 0 5px;
}

.captcha-error {
    border: 2px solid #f1666a;
    border-radius: 3px;
}

.custom-captcha {
    width: 100%;
    margin-bottom: 20px;
}

.width-49 {
    width: 49%;
}

.flex-grow-1 {
    flex-grow: 1;
}

.custom-checkbox {
    height: 21px;
    min-height: 21px;
    width: 21px;
    min-width: 21px;
    background-color: white;
    border: 2px solid #cdd1e5;
    border-radius: 4px;
    -webkit-user-select: none;
            user-select: none;
}

.custom-checkbox img {
    height: 16px;
    width: 16px;
    pointer-events: none;
}

.custom-checkbox.checked {
    border: 2px solid #3e6fd9;
    background-color: #3e6fd9;
}

.custom-checkbox.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.custom-checkbox.red {
    border: 2px solid #f1666a;
}

.projects-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 41px;
}

.add-project-button-round {
    margin-left: 20px;
    color: #3e6fd9;
}

.add-project-button-round:hover {
    color: #3061ae;
}

.dashboard-project-count {
    color: #838fdb;
    font-family: 'Inter';
    font-size: 30px;
    margin-left: 10px;
    height: 41px;
}

.dashboard-wrapper {
    margin: 0 40px;
    font-family: 'Inter';
    min-width: 560px;
}

.input-label {
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 10px;
    height: 22px;
}

.project-input-container {
    margin-left: 20px;
    white-space: nowrap;
}

.search-input-container.project-search {
    margin-left: 20px;
}

.dashboard-input {
    height: 41px;
    line-height: 41px;
    border: 2px solid #cdd1e5;
    border-radius: 4px;
    color: #242c5c;
    padding: 0 14px;
    font-size: 15px;
    background-color: transparent;
    min-width: 190px;
    font-family: 'Inter';
}

.dashboard-input::-webkit-input-placeholder {
    color: #242c5c;
}

.dashboard-input::placeholder {
    color: #242c5c;
}

.header-image-container {
    height: 170px;
    margin: -12px 0 30px;
    position: relative;
}

.blue-rectangle {
    height: 143px;
    width: 100%;
    background-color: #3e6fd9;
    border-radius: 4px;
    align-self: flex-end;
}

.welcome-container {
    align-items: flex-start;
    text-align: left;
    align-self: center;
    margin-left: 36px;
    z-index: 10;
}

.welcome-title {
    color: #ffffff;
    font-family: 'Inter';
    font-size: 30px;
}

.welcome-text {
    color: #b3c6f0;
    font-family: 'Inter';
    font-size: 26px;
    line-height: 28px;
}

.info-container {
    align-items: flex-start;
    text-align: left;
    align-self: center;
    height: auto;
    width: 100%;
    border: 2px solid #3e6fd9;
    background-color: #d4def2;
    border-radius: 4px;
    padding: 20px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    line-height: 24px;
}

.image-container {
    width: 397px;
    height: 144px;
    position: absolute;
    right: 0;
    overflow: hidden;
    margin-right: -24px;
    margin-top: 26px;
}

.image-container img {
    width: 100%;
    height: 100%;
}

.project-container {
    margin-bottom: 15px;
    margin-right: 13px;
}

.search-input {
    padding: 0px 40px 0 14px;
    font-size: 17px;
}

.white {
    color: white;
}

.project-input-container.first {
    margin-left: auto;
}

.create-folder-icon {
    background-color: #3e6fd9;
    width: 32px;
    height: 32px;
    margin-left: 10px;
    border-radius: 50%;
}

.reverse-sort-button {
    color: #242c5c;
    height: 41px;
    border: 1px solid #3e6fd9;
    border-radius: 4px;
    padding: 0 10px 0 15px;
    margin-right: 10px;
    border: 2px solid #cdd1e5;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    background-color: #f7f8fa;
}
.reverse-sort-button:hover {
    background-color: white;
}
.reverse-sort-button svg {
    position: relative;
    left: -3px;
}

.my-projects-tooltip {
    width: auto !important;
}

.folder-icon-class {
    position: relative;
    top: 2px;
}

.custom-scrollbar-scrollable-container::-webkit-scrollbar {
    display: none;
}

.custom-scrollbar-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.custom-scrollbar-scrollable-container {
    overflow: auto;
    height: 100%;
    position: relative;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.custom-scrollbar {
    width: 7px;
    height: 100%;
    right: 0;
    top: 0px;
    position: absolute;
    border-radius: 7px;
    bottom: 0px;
}

.custom-scrollbar-handle {
    width: 7px;
    height: 20px;
    position: absolute;
    border-radius: 7px;
    top: 0;
}

.tooltip-container {
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
    border-radius: 4px;
    position: relative;
}

.tooltip-container:hover .tooltip-message-container {
    display: block;
}

.tooltip-indicator {
    font-family: 'Inter';
    font-size: 20px;
    line-height: 26px;
    height: 27px;
    -webkit-user-select: none;
            user-select: none;
}

.tooltip-text {
    white-space: pre-line;
}

.tooltip-message-container {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    position: fixed;
    width: 450px;
    background-color: #3e6fd9;
    color: white;
    z-index: 1000;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.tooltip-message-container::after {
    content: '';
    width: 0;
    height: 0;
    border: 5px solid;
    position: absolute;
}

.tooltip-message-container.top::after {
    border-color: #3e6fd9 transparent transparent transparent;
    left: calc(50% - 5px);
    bottom: -10px;
}

.tooltip-message-container.bottom::after {
    border-color: transparent transparent #3e6fd9 transparent;
    left: calc(50% - 5px);
    top: -10px;
}

.tooltip-message-container.right::after {
    border-color: transparent #3e6fd9 transparent transparent;
    left: -10px;
    top: calc(50% - 5px);
}

.tooltip-message-container.left::after {
    border-color: transparent transparent transparent #3e6fd9;
    right: -10px;
    top: calc(50% - 5px);
}

.tooltip-inner-text {
    border-bottom: 1px dotted #242c5c;
}

.tooltip-inner-text-white {
    border-bottom: 1px dotted white;
}

.tooltip-inner-text-blue {
    border-bottom: 1px dotted #3e6fd9;
}

.tooltip-inner-text-red {
    border-bottom: 1px dotted #f1666a;
}

.width-fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.custom-dropdown-container {
    width: 191px;
    height: 41px;
    border-radius: 4px;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.custom-dropdown-options-container {
    font-family: 'Inter';
}

.custom-dropdown-options {
    width: 191px;
    height: auto;
    border-radius: 0 0 4px 4px;
    border: 2px solid #cdd1e5;
    border-top: none;
    overflow: hidden;
    position: absolute;
    background-color: #f7f8fa;
    z-index: 300;
}

.custom-dropdown-container span {
    color: #242c5c;
    font-size: 15px;
    height: 22px;
    -webkit-user-select: none;
            user-select: none;
}

.custom-dropdown-triangle-icon {
    border: 5px solid;
    border-color: #858aa6 transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 19px;
}

.dependency-indicator {
    width: 19px;
    height: 19px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #cdd1e5;
}

.dependency-indicator.unaddressed {
    border: 2px solid #cdd1e5;
}

.custom-dropdown-container-option {
    height: 39px;
}

.custom-dropdown-container-option.first {
    border: 2px solid #cdd1e5;
    border-radius: 4px 4px 4px 4px;
    height: 41px;
}

.custom-dropdown-container-option.first.open {
    border: 2px solid #cdd1e5;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #eceef5;
}

.custom-dropdown-container-option:not(.first) {
    border-bottom: 2px solid #eceef5;
}

.custom-dropdown-container-option:last-of-type {
    border-bottom: none;
}

.custom-dropdown-container-option:hover {
    background-color: #ffffff;
}

.custom-dropdown-container-option.active {
    background-color: white;
}

.custom-dropdown-option {
    padding: 0 12px;
}

.benefit-option img {
    min-width: 20px;
    width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 6px;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.custom-search-input {
    position: relative;
}

.custom-search-input img {
    position: absolute;
    right: 17px;
    top: 10px;
}

.custom-search-input-input {
    height: 41px;
    line-height: 40px;
    border: 2px solid #cdd1e5;
    border-radius: 4px;
    color: #242c5c;
    background-color: white;
    font-family: 'Inter';
    padding: 3px 40px 3px 14px;
    font-size: 14.5px;
    font-weight: 500;
    outline: none;
}

.custom-search-input-input::-webkit-input-placeholder {
    color: #242c5c;
}

.custom-search-input-input::placeholder {
    color: #242c5c;
}

.custom-search-input-input:hover {
    border: 2px solid #9eb6ec;
}

.custom-search-input-input:focus {
    border: 2px solid #9eb6ec;
}

.custom-search-input-input.empty {
    background-color: #f6f7f9;
}

.custom-search-input-input.empty:focus {
    background-color: white;
}

.custom-search-input-promo-codes {
    max-width: 280px;
}

.view-tutorial-button {
    color: #3e6fd9;
    width: 200px;
    height: 44px;
    border: none;
    background-color: #fce0a9;
    border-radius: 4px;
    padding: 0 10px 0 15px;
    margin-right: 15px;
    outline: none !important;
}

.view-tutorial-button span {
    height: 22px;
    line-height: 25px;
    font-family: 'Inter';
    font-size: 16px;
}

.view-tutorial-button svg {
    width: 28px;
    height: 25px;
}

.view-tutorial-button:hover {
    color: #ecf1fc;
    background-color: #3e6fd9;
}

.custom-input {
    height: 43px;
    width: 100%;
    border-radius: 4px;
    border: 2px solid #eceef5;
    padding: 0 16px;
    color: #465664;
    font-size: 16px;
    outline: none !important;
}

.custom-input-label-default {
    color: #465664;
    font-size: 16px;
    align-self: flex-start;
    text-align: start;
}

.custom-input:hover {
    border: 2px solid #9eb6ec;
}

.custom-input:focus {
    border: 2px solid #9eb6ec;
}

.custom-input.empty {
    background-color: #f6f7f9;
}

.custom-input.disabled {
    background-color: #d9dce2;
}

.custom-input.has-errors {
    border: 2px solid #f1666a;
}

.custom-input-errors {
    width: 100%;
    height: 41px;
    min-height: 41px;
    border-radius: 0 0 4px 4px;
    background-color: #f6e2e5;
}

.custom-input-errors span {
    height: 23px;
    color: #f1666a;
    font-size: 17px;
}

.folder-rename-dialog-wrapper {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 400px;
    min-height: 300px;
}
.folder-input-container {
    margin-bottom: 20px;
    margin-top: 10px;
    width: 100%;
}
.folder-input-label {
    text-align: left;
}

.progress-circle {
    position: relative;
}
  
.progress-circle svg {
    width: 100px;
    height: 100px;
}

.progress-circle circle {
    fill: transparent;
}

.progress-circle__circles {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.progress-circle__progress-dashes {
    stroke-linecap: round;
    stroke:  #9880ff;
}

.progress-circle__background-dashes {
    stroke-linecap: round;
    stroke: #c8c8c8;
}

.progress-circle__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
}
.generate-image-modal-header {
    height: 100px;
    align-items: center;
    justify-content: space-between;
    background-color: #fce8e9;
    border-radius: 4px 4px 0 0;
    padding: 10px 28px;
}

.generate-image-modal-header h2 {
    font-size: 1.6rem;
    margin: 0 10px;
    color: #cc4b4f;
}

.generate-image-modal__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.generate-image-modal__body {
    top: 39px;
    left: 42px;
}

.generate-image-modal__text {
    margin: 0;
    color: #888da8;
}

.generate-image-modal__img {
    min-width: 300px;
	min-height: 300px;
	max-width: 80vw;
	max-height: 80vh;
}

.generate-image-modal__button {
    border-radius: 16px;
    width: 80px;
    margin-top: 8px;
    color: #ffffff;
    border-width: 0px;
}
.generate-image-modal__button_accept {
    background-color: #6EC988;
}
.generate-image-modal__button_decline {
    background-color: #D73245;
}
.generate-image-modal__buttons-container {
    display: flex;
    flex-direction: column;
}
.image-modal {
	background-color: #eceef5;
	border-radius: 4px;
}

.image-modal-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 75px;
	margin: 0 10px;
}

.image-modal-header__title {
	color: #242c5c;
	font-size: 26px;
	margin-left: 20px;
}

.image-modal-header__button-exit {
	width: 22px;
	height: 30px;
	margin-right: 20px;
}

.image-modal__img {
	min-width: 300px;
	min-height: 200px;
	max-width: 80vw;
	max-height: 80vh;
}
.custom-radio-input {
    flex: 1 1;
    margin-right: 10px;
    height: 39px;
    min-width: 80px;
    border-radius: 4px;
    padding-left: 10px;
    border: 2px solid transparent;
    background-color: #eceef5;
}

.custom-radio-input:last-of-type {
    margin-right: 0;
}

.custom-radio-input span {
    height: 23px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 15px;
    margin-left: 5px;
}

.radio-button-icon {
    height: 16px;
    width: 16px;
    min-width: 16px;
    border: 2px solid #cdd1e5;
    border-radius: 10px;
    background-color: #ffffff;
    margin-left: 10px;
}

.custom-radio-input img {
    margin-left: 10px;
    height: 16px;
    width: 16px;
}

.show-radio-border {
    border: 2px solid #cdd1e5;
}

.custom-radio-input.active {
    border: 2px solid #3e6fd9;
}

.custom-radio-input.active span {
    color: #242c5c;
}

.transparent {
    background-color: transparent;
}

.custom-radio-input.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.custom-textarea {
    height: 96px;
    width: 100%;
    border-radius: 4px;
    border: 2px solid #eceef5;
    padding: 5px 16px 0;
    color: #465664;
    font-size: 16px;
    resize: none;
}

.custom-textarea-label,
.custom-textarea-label-default {
    color: #465664;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
}

custom-textarea-label-default {
    margin-bottom: 5px;
}

.custom-textarea-label {
    margin-bottom: 15px;
}

.custom-textarea:hover {
    border: 2px solid #9eb6ec;
}

.custom-textarea:focus {
    border: 2px solid #9eb6ec;
}

.custom-textarea.empty {
    background-color: #f6f7f9;
}

.custom-textarea::-webkit-input-placeholder {
    color:  #75798d;
}

.custom-textarea::placeholder {
    color:  #75798d;
}
.dnd-label {
    color: #465664;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 13px;
    height: 24px;
}

.dnd {
    height: 107px;
    width: 100%;
    background-color: #f7f8fa;
    border-radius: 4px;
    border: 2px dashed rgba(62, 111, 217, 0.44);
    font-family: 'Inter';
}

.dnd.preview {
    border: none;
}

.dnd-text {
    color: #465664;
    font-size: 16px;
    height: 20px;
    line-height: 24px;
}

.drop-zone {
    width: 100%;
    height: 100%;
}

.light-blue {
    color: #3e6fd9;
}

.dnd-bottom-text {
    color: #888da8;
    font-size: 14px;
}

.dnd.dnd-progress {
    height: 47px;
    margin-top: 10px;
    border: none;
}

.dnd-cancel-icon {
    width: 12px;
    height: 12px;
    margin: 0 15px;
}

.dnd-progress-bar {
    width: 59px;
    height: 7px;
    background-color: #cdd1e5;
    border-radius: 8px;
}

.dnd-progress-bar.filled {
    background-color: #3e6fd9;
}

.dnd-filename span {
    height: 22px;
    color: #242c5c;
    font-size: 14px;
}

.dnd-filename {
    margin-left: 15px;
}

.dnd-filename svg {
    margin-right: 10px;
}

.dnd-file-info span {
    color: #888da8;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
}

.display-none {
    display: none;
}

.dnd-image-preveiw img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.dnd-preview-label {
    margin-bottom: 0;
}

.custom-switch-container {
    height: 20px;
    width: 40px;
    margin: 0 5px;
    position: relative;
}

.custom-switch-rectangle {
    width: 34px;
    height: 14px;
    background-color: #9db6ed;
    border-radius: 10px;
}

.custom-switch-circle {
    height: 20px;
    width: 20px;
    background-color: #3e6fd9;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-switch-circle.active {
    left: unset;
    right: 0px;
}

.custom-switch-label-default {
    height: 24px;
    color: #465664;
    font-family: 'Inter';
    font-size: 16px;
}

.custom-switch-label-default.active {
    color: #3e6fd9;
}

.custom-switch-tooltip {
    margin-left: 5px;
}

.custom-switch.disabled {
    opacity: 0.5;
}
.custom-toggle-rectangle-logic {
    width: 34px;
    height: 14px;
    background-color: #c3c5d3;
    border-radius: 10px;
}

.custom-number-input {
    padding: 0 32px 0 30px;
    color: #465664;
    font-size: 16px;
    width: 110px;
    height: 43px;
    border: 2px solid #eceef5;
    border-radius: 4px;

    -webkit-appearance: textfield;
    appearance: textfield;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.custom-input-container {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.custom-input-container.disabled {
    opacity: 0.5;
}

.custom-number-input-arrows {
    height: 43px;
    width: 35px;
    border-radius: 0 4px 4px 0;
    border: 2px solid #eceef5;
    position: absolute;
    top: 0;
    right: 0;
}

.triangle-icon-up {
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: transparent transparent #888da8 transparent;
}

.triangle-icon-down {
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: #888da8 transparent transparent transparent;
}

.arrow-icon.up {
    border-bottom: 2px solid #eceef5;
}

.arrow-icon {
    width: 100%;
    height: 21px;
}

.custom-number-input-label {
    position: absolute;
    left: 2px;
    top: 2px;
    background-color: white;
    color: #465664;
    font-size: 16px;
    height: 39px;
    border-radius: 4px 0 0 4px;
    width: 74px;
    padding: 0 2px;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
}

.WarningNotTokensModal_dialog__3e13d {
    height: 360px;
    width: 500px;
}

.WarningNotTokensModal_header__ycFSL {
    height: 80px;
    padding: 0px 42px;
}

.WarningNotTokensModal_close-icon__10qFU {
    padding: 20px;
    margin-right: -20px;
}

.WarningNotTokensModal_close-icon__10qFU:hover {
    cursor: pointer;
}

.WarningNotTokensModal_title-header__1bUAS {
    color: #242c5c;
    font-weight: 600;
    margin-left: 10px;
    font-size: 20px;
}

.WarningNotTokensModal_close-button__2Rb5y {
    border: none;
    background: none;
}

.WarningNotTokensModal_buy-button__3iIUR {
    margin: 0 !important;
}

.WarningNotTokensModal_body-label__2j85p {
    font-size: 20px;
    font-weight: 500;
    padding: 30px 20px 42px 20px;
}

.WarningNotTokensModal_purchase-button__2aV8Z {
    margin-top: 18px;
    border: 2px #3e6fd9 solid !important;
    background-color: white !important;
    color: #3e6fd9 !important;
}

.WarningNotTokensModal_purchase-button__2aV8Z:hover {
    background-color: #f5f5f5 !important;
}

.WarningNotTokensModal_default-button__F3UCq {
    height: 42px !important;
    width: 80% !important;
    text-align: center;
    font-weight: 600;
}

.WarningNotTokensModal_icon-generate__2kOmq {
    color: #cc4b4f;
    height: 24px;
    width: 24px;
}

.BuyMoreAICreditsModal_header__28Yrz {
    height: 85px;
    padding: 0px 42px;
    background-color: #eceef5;
}

.BuyMoreAICreditsModal_dialog__1pkep {
    width: 622px;
    height: 566px;
}

.BuyMoreAICreditsModal_next-dialog__1FjG9 {
    width: 622px;
    height: 515px;
}

.BuyMoreAICreditsModal_title-header__Oqcs0 {
    color: #242c5c;
    font-weight: 500;
    margin-left: 10px;
    font-size: 30px;
}

.BuyMoreAICreditsModal_description-span__ChT4N {
    width: 400px;
    font-weight: 500;
    margin-top: 12px;
}

.BuyMoreAICreditsModal_default-color__3tUtg {
    color: #242c5c;
}

.BuyMoreAICreditsModal_confirm-span__2RfFu {
    margin-top: 40px;
}

.BuyMoreAICreditsModal_main-span__FyS05 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
}

.BuyMoreAICreditsModal_tariff-block__3KUDF {
    width: 163px;
    height: 193px;
    border: 2px #cdd1e5 solid;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 28px;
}

.BuyMoreAICreditsModal_tariff-left-margin__1oTO8 {
    margin-left: 17px;
}

.BuyMoreAICreditsModal_check_mark__1xI-l {
    width: 16px;
    height: 16px;
    display: flex;
    border-color: #cdd1e5;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    margin-bottom: 14px;
}

.BuyMoreAICreditsModal_default-button__2K9Cn {
    width: 524px !important;
    height: 58px !important;
    font-size: 21px;
}

.BuyMoreAICreditsModal_icon-check_mark__1Krrv {
    margin-bottom: 12px;
}

.BuyMoreAICreditsModal_close-icon__5PGjo {
    padding: 10px;
    margin-right: -10px;
}

.BuyMoreAICreditsModal_tariff-block__3KUDF:hover,
.BuyMoreAICreditsModal_close-icon__5PGjo:hover {
    cursor: pointer;
}

.BuyMoreAICreditsModal_tariff-info__2CWYy {
    color: #242c5c;
    font-size: 18px;
    font-weight: 500;
}

.BuyMoreAICreditsModal_total-table__3DS3E {
    width: 540px;
    height: 223px;
    background: #f7f8fa;
    border: 5px #cdd1e5 solid;
    border-width: 2px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 42px;
}

.BuyMoreAICreditsModal_credits-block__2GI67 {
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 30px;
}

.BuyMoreAICreditsModal_credits-block-bottom__2viO4 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.BuyMoreAICreditsModal_total-block__lxzVz {
    margin-left: 30px;
    margin-bottom: 26px;
    margin-right:  30px;
    border-top: 2px #CDD1E5 solid ;
    border-radius: 1px;
    padding-top: 15px;
}

.BuyMoreAICreditsModal_credits-block__2GI67 span {
    color: #242c5c;
    font-weight: 500;
    font-size: 18px;
}

.BuyMoreAICreditsModal_total-block__lxzVz span {
    color: #242c5c;
    font-weight: 600;
    font-size: 18px;
}

.BuyMoreAICreditsModal_purchase-button__goLXb {
    margin-top: 28px;
    min-width: 544px;
    border: 0;
}

.BuyMoreAICreditsModal_credits-block-subscription__1L-2C {
    margin-top: 20px;
}

.BuyMoreAICreditsModal_total-table-subscription__3xdpX {
    margin-top: 28px;
}
.checkout-dialog-wrapper {
    width: 622px;
    height: 600px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
}

.checkout-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
}

.checkout-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    height: 33px;
}

.checkout-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 85px);
}

.checkout-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.checkout-dialog-button {
    border: none;
    min-width: 105px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.checkout-dialog-label {
    color: #465664;
    font-size: 16px;
    height: 24px;
}

.stripe-error-message {
    color: #f1666a;
    font-size: 16px;
    height: 24px;
    margin-top: 10px;
    margin-right: 181px;
    text-align: end;
}

.checkout-dialog-button.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.billing-dialog-label {
    color: #465664;
    font-size: 16px;
    height: 20px;
}

.billing-dialog-label.total {
    font-size: 20px;
    height: 10px;
}

.error-message-modal {
    width: 50vw;
    height: 35vh;
    border-radius: 4px;
    position: relative;
    font-family: "Inter";
    justify-content: space-between;
}

.error-message-modal-header {
    align-items: center;
    justify-content: space-between;
    background-color: #eceef5;
    padding: 25px 25px;
}

.error-message-modal-header h2 {
    font-size: 30px;
    color: #242c5c;
    margin: 0;
    font-weight: 400;
}

.error-message-modal-body {
    margin: 0 25px;
}

.error-message-modal-text {
    font-size: 20px;
}

.error-message-modal-footer {
    margin: 0 0 25px 0;
}

.error-message-modal-footer__button {
    border: none;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    font-size: 16px;
    color: white;
    background-color: #3E6FD9;
}
.carousel-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.arrows {
    color: red;
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    font-size: 30px;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    align-items: center;
}

.arrow {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-bottom: 30px;
}

.arrow__image{
    width: 30px;
    height: 30px;
}

.arrow__image_left{
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.carousel-container__images-container{
    width: 300px;
    display: flex;
    overflow: hidden;
}

.carousel-container__slider-image{
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.carousel-container__slider-image_selected {
    border: 2px solid #CB4252;
}

.carousel-container__image-deletion-button {
    font-size: 8px;
    height: 15px;
    width: 15px;
    position: relative;
    bottom: 80px;
    left: 65px;
    z-index: 1;
    border: 0px;
    padding: 0px;
}

.carousel-container__image-deletion-button > img {
    height: 8px;
    width: 8px;
  }

.carousel-container__image-container{
    display: flex;
    flex-direction: column;
}
.project-dialog-wrapper {
    width: 622px;
    min-height: 731px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
}

.project-dialog-header {
    width: 100%;
    height: 155px;
    background-color: #eceef5;
    padding: 0 28px;
}

.project-dialog-header-top {
    margin-top: 30px;
}

.project-dialog-header-icon {
    margin-right: 10px;
}

.project-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 600;
    height: 33px;
}

.project-dialog-body {
    padding: 26px 32px 110px 32px;
    width: 100%;
}

.project-dialog-label {
    color: #465664;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    height: 24px;
}

.project-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.project-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    outline: none !important;
}

.project-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.project-dialog-tab {
    width: 50%;
    height: 46px;
    align-self: flex-end;
    position: relative;
}

.project-dialog-tab span {
    width: 67px;
    height: 27px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 20px;
}

.project-dialog-tab.active span {
    color: #3e6fd9;
}

.project-tab-line {
    width: 310px;
    height: 3px;
    background-color: #3e6fd9;
    position: absolute;
    bottom: 0;
}

.project-tab-line.right {
    left: 0;
}

.project-tab-line.left {
    right: 0;
}

.align-self-start {
    align-self: flex-start;
}

.number-input {
    width: 110px;
    height: 43px;
    border: 2px solid #eceef5;
    border-radius: 4px;
    padding-left: 30px;
}

.project-type-radio {
    font-weight: 600;
}

.project-name span {
    font-weight: 500;
}

.project-name-placeholder {
    font-size: 15px;
}

.project-name-placeholder::-webkit-input-placeholder {
    color: #3e6fd9;
    opacity: 0.7;
}

.project-name-placeholder::placeholder {
    color: #3e6fd9;
    opacity: 0.7;
}

.delete-popup.modal-type-error {
    height: 250px;
}

.border-error {
    border: 1px solid rgb(203, 26, 26);
}

.project-edit-modal__button-ai_image {
    width: 40%;
    position: relative;
    top: 31px;
    left: 80px;
    font-size: 14px;
}

.project-edit-modal__button-ai_image p {
    margin: 0;
}

.project-edit-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 7px 7px 7px 7px;
    font-weight: 500;
}

.project-edit-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.project-edit-modal__button-ai_disabled,
.project-edit-modal__button-ai_disabled:hover {
    background-color: #888da8;
    color: #fff;
}

.project-edit-modal__upload-image-button {
    position: relative;
    top: 44px;
    right: 55px;
    border: 0px;
    border-radius: 4px;
    height: 59px;
    font-size: 14px;
    width: 40%;
}
.project-edit-modal__upload-image-button:hover{
    background-color: #cc4b4f
}
.project-edit-modal__upload-image-button_disabled,
.project-edit-modal__upload-image-button_disabled:hover,
.project-edit-modal__button-ai_image-disabled,
.project-edit-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}
.dnd-label {
    color: #465664;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 13px;
    height: 24px;
}

.dnd {
    height: 107px;
    width: 100%;
    background-color: #f7f8fa;
    border-radius: 4px;
    border: 2px dashed rgba(62, 111, 217, 0.44);
    font-family: 'Inter';
}

.dnd.preview {
    border: none;
}

.dnd-text {
    color: #465664;
    font-size: 16px;
    height: 20px;
    line-height: 24px;
}

.drop-zone {
    width: 100%;
    height: 100%;
}

.light-blue {
    color: #3e6fd9;
}

.dnd-bottom-text {
    color: #888da8;
    font-size: 14px;
}

.dnd.dnd-progress {
    height: 47px;
    margin-top: 10px;
    border: none;
}

.dnd-cancel-icon {
    width: 12px;
    height: 12px;
    margin: 0 15px;
}

.dnd-progress-bar {
    width: 59px;
    height: 7px;
    background-color: #cdd1e5;
    border-radius: 8px;
}

.dnd-progress-bar.filled {
    background-color: #3e6fd9;
}

.dnd-filename span {
    height: 22px;
    color: #242c5c;
    font-size: 14px;
}

.dnd-filename {
    margin-left: 15px;
}

.dnd-filename svg {
    margin-right: 10px;
}

.dnd-file-info span {
    color: #888da8;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
}

.display-none {
    display: none;
}

.dnd-image-preveiw img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.dnd-preview-label {
    margin-bottom: 0;
}

.import-dialog-wrapper {
    width: 622px;
    min-height: 400px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
}

.project-dialog-header {
    width: 100%;
    height: 155px;
    background-color: #eceef5;
    padding: 0 28px;
}

.project-dialog-header-top {
    margin-top: 30px;
}

.project-dialog-header-icon {
    margin-right: 10px;
}

.project-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 600;
    height: 33px;
}

.project-dialog-body {
    padding: 26px 32px 110px 32px;
    width: 100%;
}

.project-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.project-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    outline: none !important;
}

.project-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.project-dialog-tab {
    width: 50%;
    height: 46px;
    align-self: flex-end;
    position: relative;
}

.import-csv-header {
    font-size: 20px;
    text-align: left;
    margin-bottom: 5px;
}

.import-csv-component-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.import-csv-label {
    font-size: 18px;
    text-align: left;
    margin-bottom: 5px;
}

.import-csv-name-container {
    display: flex;
}

.project-dashboard-tabs {
    height: 55px;
    background-color: #eceef5;
}

.project-dashboard-tab {
    width: 200px;
    height: 100%;
    border-bottom: 3px solid transparent;
    overflow: hidden;
}

.project-dashboard-tab-counter {
    margin-left: 10px;
    height: 28px;
    color: #838fdb;
    height: 28px;
    font-size: 20px;
}

.project-dashboard-tab-title {
    height: 28px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-dashboard-tab.active .project-dashboard-tab-title {
    color: #3e6fd9;
}

.project-dashboard-tab.active {
    border-bottom: 3px solid #3e6fd9;
}

.project-progress-bar {
    width: 100%;
    height: 7px;
    background-color: #cdd1e5;
    border-radius: 4px;
}

.project-progress-colors {
    height: 100%;
    -webkit-animation: width-animated 1s ease forwards;
            animation: width-animated 1s ease forwards;
}

@-webkit-keyframes width-animated {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@keyframes width-animated {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.project-progress {
    height: 100%;
    border-radius: 4px;
}

.project-progress.green {
    background-color: #5ac57a;
}

.project-progress.yellow {
    background-color: #ffcd67;
}

.project-progress.red {
    background-color: #f9ae9f;
}

.card-modal-container {
    min-width: 155px;
    min-height: 33px;
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid #c7ccec;
    background-color: white;
    overflow: hidden;
    z-index: 1000;
    -webkit-user-select: none;
            user-select: none;
    max-height: 265px;
    top: 52px;
}

.search-bar {
    left: 270px;
    width: 20%;
}

.card-modal-container span {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15px;
}

.card-modal-option {
    background-color: #f7f8fa;
    display: flex;
    align-items: center;
    height: 33px;
    min-height: 33px;
    padding: 0 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #242c5c;
    border-bottom: 1px solid #eceef5;
}

.card-modal-option span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-modal-option:hover {
    background-color: white;
}

.card-modal-option:hover span {
    color: #3c6ad7;
}

.menu-items-container {
    overflow-y: auto;
}

.card-modal-search-input {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.card-modal-search-input .custom-search-input {
    max-width: unset;
}

.initials.cn {
    font-size: 12px;
}

.component-card-initials.flex-center.cn {
    font-size: 12px;
}

.reports-row-initials.flex-center span.cn {
    font-size: 12px;
}

.users-row-initials-block.flex-center span.cn {
    font-size: 12px;
    margin-top: 5px;
}

.add-project {
    width: 333px;
    height: 217px;
    border: 1px solid #3e6fd9;
    border-radius: 4px;
    color: #3e6fd9;
}

.isDragged {
    -webkit-animation: isDragged 1s infinite;
            animation: isDragged 1s infinite;
}

@-webkit-keyframes isDragged {
    25% {
        -webkit-transform: rotate(0.5deg);
                transform: rotate(0.5deg);
    }
    50% {
        -webkit-transform: rotate(-0.5deg);
                transform: rotate(-0.5deg);
    }
    75% {
        -webkit-transform: rotate(0.5deg);
                transform: rotate(0.5deg);
    }
    100% {
        -webkit-transform: rotate(-0.5deg);
                transform: rotate(-0.5deg);
    }
}

@keyframes isDragged {
    25% {
        -webkit-transform: rotate(0.5deg);
                transform: rotate(0.5deg);
    }
    50% {
        -webkit-transform: rotate(-0.5deg);
                transform: rotate(-0.5deg);
    }
    75% {
        -webkit-transform: rotate(0.5deg);
                transform: rotate(0.5deg);
    }
    100% {
        -webkit-transform: rotate(-0.5deg);
                transform: rotate(-0.5deg);
    }
}

.add-project span {
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 24px;
}

.add-project-icon {
    margin-bottom: 15px;
}

.add-project:hover {
    background-color: #3e6fd9;
    color: white;
}

.add-project:hover span {
    color: white;
}

.project {
    align-items: center;
    width: 333px;
    height: 217px;
    border: 1px solid #c7ccec;
    border-radius: 4px;
    overflow: hidden;
    justify-content: space-between;
    margin: 0 13px 15px 0;
    transition: all 0.2s;
}

.project img {
    pointer-events: none;
}

.project:hover {
    border-color: #3e6fd9;
}

.project-title-container {
    padding: 0 17px;
    text-align: left;
    margin: 16px 0 0;
    justify-content: space-between;
    width: 100%;
    position: relative;
    align-items: center;
}

.menu-button {
    background-color: #f7f8fa;
    border: none;
    height: 30px;
    width: 30px;
    outline: none !important;
}

.project-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
    line-height: 22px;
    max-height: 44px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.project-title-name {
    margin-bottom: 0;
    color: #3e6fd9;
    width: 50%;
}

.project-info {
    width: 100%;
    min-height: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    width: 98%;
    padding: 0 10px 0 10px;
}

.icons-left {
    min-width: 80px;
    max-width: 110px;
}

.flex.icons-left {
    justify-content: space-evenly;
}

.components {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.project-info-tooltip {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}

.project-info-tooltip-message {
    width: auto;
    max-width: 300px;
    background-color: white;
    color: #3e6fd9;
}

.project-info-tooltip-message.top::after {
    border-color: white transparent transparent transparent;
}

.initials {
    color: #f1666a;
    font-family: 'Inter';
    font-size: 16px;
    background-color: #fdeeee;
    width: 32px;
    height: 26px;
    border-radius: 4px;
    line-height: 26px;
}

.green-button {
    background-color: #e9f7ed;
    color: #47b869;
    font-size: 15px;
}

.blue-button {
    background-color: #ecf1fb;
    color: #3e6fd9;
    font-size: 14px;
}

.blue-button.project-button {
    align-items: center;
    justify-content: space-between;
}

.blue-button.project-button span {
    height: 21px;
    line-height: 21px;
    font-size: 16px;
}

.green-button.project-button span {
    height: 21px;
    line-height: 21px;
    font-size: 17px;
}

.project-button {
    height: 36px;
    border-radius: 4px;
    border: none;
    font-family: 'Inter';
    outline: none !important;
    width: 125px;
    padding: 0 15px 0 15px;
}

.project-info-icons {
    justify-content: space-around;
    width: 98%;
    margin: 0 10px 0 10px;
}

.project-button img {
    margin-right: 8px;
}

.ideas-count-projects {
    display: flex;
    align-items: center;
    width: 187px;
}

.ideas-count-projects svg {
    margin-left: 8px;
}

.max-width-50 {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.folder-delete-confirmation-modal-text {
    height: auto !important;
}

.folder-delete-popup {
    height: auto !important;
}

.report-print-button-container {
    width: 100%;
    display: flex;
    align-items: start;
    padding-left: 10px;
}

.report-print-button {
    cursor: pointer;
    display: flex;
    border: 1px solid #cbcaca;
    color: #3e6fd9;
    padding: 5px;
    align-items: center;
}


.delete-popup {
    width: 472px;
    height: 207px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    font-family: 'Inter';
    padding: 20px;
}

.delete-popup-buttons-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.delete-popup button {
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 175px;
    height: 56px;
    background-color: #3e6fd9;
}

.delete-popup button span {
    height: 30px;
    font-size: 22px;
    text-transform: uppercase;
}

.delete-popup-close {
    justify-content: flex-end;
    min-height: 20px;
}

.delete-popup-text {
    width: 271px;
    height: 55px;
    color: #465664;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 24px;
}

.yes-popup-button {
    outline: none !important;
}

.no-popup-button {
    outline: none !important;
}

.folder {
    background-color: #ffe9a2;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: flex-start;
    justify-content: space-between;
    transition: all 0.2s ease;
}

.folder-header {
    width: 100%;
    padding-inline: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.folder-rectangle {
    position: absolute;
    left: -145px;
    top: -74px;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid #e9d48f;
    transition: all 0.2s ease;
}

.folder-rectangle-rotated {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    left: -145px;
    top: -74px;
    transition: all 0.2s ease;
}

.folder-square {
    position: absolute;
    left: -283px;
    top: -24px;
    width: 138px;
    height: 50px;
    background-color: #e9d48f;
    transition: all 0.2s ease;
}
.folder-title {
    margin-bottom: 10px;
    width: 100%;
}

.folder-title > span {
    display: block;
    -webkit-user-select: none;
            user-select: none;
    margin-inline: 20px;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    text-align: left;
}

.project-count-container {
    position: relative;
}
.project-count-text {
    position: absolute;
    left: 12px;
    font-size: 24px;
    top: -176px;
    opacity: 0.5;
}
.folder.isDragged {
    background-color: #3e6fd9;
}

.folder.isDragged .folder-rectangle-rotated {
    border-bottom: 100px solid #3e6fd9;
}

.folder.isDragged .folder-square {
    background-color: #3e6fd9;
}

.folder.isDragged > * > span {
    color: white;
}

.folder.isDragged .project-count-text {
    color: white;
    opacity: 1;
}

.folder-context-button {
    background-color: #00000000;
}

.invitation-button {
    width: 142px;
    height: 36px;
    background-color: #3e6fd9;
    border-radius: 4px;
    border: none;
    outline: none !important;
}

.invitation-button-container {
    padding: 0 18px;
}

.invitation-button.gray {
    background-color: #888da8;
}

.invitation-button.gray:hover {
    background-color: #666a86;
}

.invitation-button span {
    height: 19px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.invitation-dialog-wrapper {
    width: 622px;
    height: 728px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.invitation-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-radius: 4px 4px 0 0;
}

.invitation-dialog-header img {
    -webkit-user-select: none;
            user-select: none;
}

.invitation-dialog-body {
    padding: 0 40px 0 40px;
    width: 100%;
}

.invitation-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
}

.invitation-dialog-text {
    margin: 50px 0 20px 0;
    height: 32px;
    color: #465664;
    font-size: 24px;
}

.invitation-dialog-button {
    align-self: flex-end;
    width: 243px;
    height: 44px;
    border: none;
    border-radius: 4px;
    background-color: #3e6fd9;
    position: absolute;
    bottom: 40px;
    outline: none !important;
}

.invitation-dialog-button span {
    height: 24px;
    color: #ffffff;
    font-size: 18px;
    text-transform: uppercase;
}

.invitation-input {
    width: 168px;
    height: 152px;
    border: 2px solid #cdd1e5;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.invitation-input:not(:last-of-type) {
    margin-right: 18px;
}

.invitation-input.active {
    border-color: #3e6fd9;
}

.invitation-radio-button-icon {
    height: 16px;
    min-height: 16px;
    width: 16px;
    min-width: 16px;
    border: 2px solid #cdd1e5;
    border-radius: 10px;
    background-color: transparent;
    margin: 24px 0 30px;
}

.invitation-input-text {
    height: 32px;
    color: #242c5c;
    font-size: 24px;
    font-family: 'Inter';
    line-height: 22px;
    width: 122px;
}

.invitation-title-container img {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin: 24px 0 30px;
}

.team-selector {
    height: 100%;
    border-radius: 4px;
}

.invitation-dropdown-container {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.invitation-dropdown {
    border-radius: 4px;

    width: 538px;
    height: 90px;
    border: 2px solid #cdd1e5;
    background-color: #f7f8fa;
}

.invitation-dropdown-triangle {
    border: 5px solid;
    border-color: #888da8 transparent transparent transparent;
    position: absolute;
    right: 30px;
    top: 50px;
}

.invitation-dropdown.active {
    border-radius: 4px 4px 0 0;
    border: 2px solid #cdd1e5;
    border-bottom: 2px solid transparent;
}

.invitation-dropdown-options {
    width: 538px;
    max-height: 272px;
    background-color: #f7f8fa;
    border-radius: 0 0 4px 4px;
    position: absolute;
    overflow-y: auto;
    border: 2px solid #cdd1e5;
    overflow-x: hidden;
    border-top: none;
    z-index: 200;
}

.invitation-dropdown-search-input {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
}

.invitation-dropdown-search-input .custom-search-input {
    max-width: unset;
}

.invitation-option-label {
    border-radius: 4px;
    white-space: nowrap;
    height: 22px;
    background-color: #cdd1e5;
    padding: 0 5px;
}

.invitation-option-label span {
    height: 18px;
    color: #242c5c;
    font-size: 12px;
}

.invitation-option-label:not(:last-of-type) {
    margin: 0 10px 10px 0;
}

.invitation-option-label-text {
    height: 27px;
    color: #465664;
    font-size: 20px;
    margin: 20px 0 5px 20px;
    align-self: flex-start;
    text-align: left;
}

.invitation-dropdown-option {
    min-height: 90px;
    border: 2px solid transparent;
}

.invitation-dropdown-option.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.invitation-dropdown-option.first {
    width: 100%;
}

.invitation-dropdown-option:not(.first):hover {
    border: 2px solid #3e6fd9;
    background-color: #dbdfed;
}

.labels-container {
    margin-left: 14px;
}

.labels-container.first {
    max-height: 28px;
    max-width: 100%;
    overflow: hidden;
}

.option-divider {
    margin: 0;
    margin-top: auto;
}

.invitation-dropdown-placeholder {
    margin-left: 30px;
    height: 32px;
    color: #465664;
    font-size: 24px;
}

.loader {
    color:#3e6fd9;
    font-size: 50px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 15% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
 
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.projects-dashboard-wrapper {
    margin: 100px 40px 0;
    font-family: 'Inter';
}

.project-dashboard-header {
    min-height: 250px;
    margin-bottom: 40px;
}

.idea-container {
    width: 415px;
    min-width: 415px;
    height: 270px;
    max-height: 270px;
    border-radius: 6px;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.idea-container-with-default-image {
    background-color: #e2eafc;
}

.idea-placeholder-icon {
    margin-left: 30px;
}

.idea-image {
    max-width: 100%;
    max-height: 100%;
}

.project-header-container {
    min-height: 270px;
    margin-right: 33px;
    align-items: flex-start;
}

.project-header-container hr {
    width: 100%;
    margin: 0 0 20px 0;
    color: #eceef5;
}

.projects-type-label {
    height: 14px;
    color: #8a93c3;
    font-size: 15px;
}

.projects-header-title {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
}

.project-description {
    align-items: flex-start;
}

.project-description-text {
    text-align: left;
    height: 110px;
    overflow-y: auto;
    min-width: 234px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 22px;
    word-break: break-word;
}

.projects-dashboard-label {
    color: #8a93c3;
    font-size: 15px;
    height: 21px;
    margin-bottom: 8px;
}

.project-details-info-container {
    align-items: flex-start;
}

.project-details-info-container hr {
    margin: 15px 0 0 -13px;
    width: 147px;
    color: #eceef5;
}

.project-details-info {
    height: 21px;
    margin-left: 8px;
    color: #242c5c;
    font-size: 15px;
}

.no-users {
    margin-left: 0px;
    text-align: left;
}

.project-details-icon {
    margin-left: 3px;
}

.project-details {
    display: grid;
    grid-template-rows: 80px 80px;
    grid-template-columns: 180px 143px;
    margin: 0px 50px 0 5px;
}

.project-dashboard-card {
    min-width: 460px;
    width: 100%;
    height: 277px;
    box-shadow: 0 1px 1px #c6cbed;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-right: 10px;
    justify-content: space-between;
}

.card-image-container {
    margin-right: 20px;
}

.project-dashboard-card img {
    margin: 0 20px;
    max-width: 100%;
}

.project-dashboard-card-title-container {
    margin: 25px 0 0 25px;
}

.project-dashboard-card-title {
    margin-left: 10px;
    height: 36px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-right: 10px;
}

.project-card-content {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.project-card-content img {
    width: calc(100% - 440px);
}

.dashboard-card-button span {
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 21px;
}

.dashboard-card-button {
    color: #3e6fd9;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 20px;
    height: 52px;
    width: 363px;
    border: none;
    border-radius: 4px;
    background-color: rgba(62, 111, 217, 0.1);
    outline: none !important;
}

.dashboard-card-button svg {
    color: #3e6fd9;
}

.dashboard-card-button:hover {
    background-color: #3e6fd9;
}

.dashboard-card-button:hover span {
    color: white;
}

.dashboard-card-button.medium:hover span {
    color: white;
}

.dashboard-card-button.medium:hover svg {
    color: white;
}

.dashboard-card-button:hover svg {
    color: white;
}

.dashboard-card-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.dashboard-card-button-container {
    margin: 0 0 45px 45px;
    max-width: 363px;
}

.dashboard-card-description {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    line-height: 22px;
}

.mt-20 {
    margin-top: 20px;
}

.project-diagram-block {
    width: 67px;
    height: 50px;
    background-color: #3e6fd9;
    border-radius: 4px;
}

.project-diagram-block span {
    height: 34px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
}

.project-diagram-block-label {
    height: 24px;
    color: #242c5c;
    font-size: 18px;
    margin-left: 10px;
}

.diagram-tooltip {
    margin-left: 10px;
}

.project-diagram-block-big {
    border-radius: 4px;
    width: 139px;
    height: 115px;
    background-color: #5ac57a;
    margin-right: 20px;
}

.project-diagram-block-big span:first-child {
    height: 45px;
    color: #ffffff;
    font-size: 38px;
}

.project-diagram-block-big span:last-child {
    height: 24px;
    color: #ffffff;
    font-size: 18px;
}

.diagram-block-top {
    margin-bottom: 15px;
}

.dashboard-card-button.medium {
    margin: 25px 25px 0 0;
    width: auto;
    height: 36px;
    width: 244px;
    white-space: nowrap;
}

.dashboard-card-button.medium span {
    height: 22px;
    color: #3e6fd9;
    font-size: 14px;
    text-transform: uppercase;
}

.dashboard-card-button.medium svg {
    width: 13px;
    height: 13px;
}

.progress-diagram-container {
    height: 155px;
    width: 158px;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.card-right-diagram-container {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.card-content-container {
    padding-top: 10px;
}

.dependency-card-content-container {
    padding-top: 20px;
}

.progress-diagram-label {
    height: 75px;
    color: #242c5c;
    font-size: 55px;
    font-family: 'Inter';
    margin-right: 20px;
}

.progress-diagram-block {
    margin-bottom: 5px;
    border-radius: 4px;
    width: 27px;
    height: 27px;
    background-color: #cdd1e5;
}

.progress-diagram-block.active {
    background-color: #3e6fd9;
}

.donut-diagram-container {
    display: flex;
    flex-direction: column;
    height: 185px;
    width: 185px;
    position: relative;
}

.donut-diagram-container circle {
    fill: transparent;
}

.donut-diagram-container svg {
    position: absolute;
    left: 0;
    top: 0;
}

.donut-svg-chart {
    height: 185px;
    width: 185px;
    border-radius: 50%;
}

.donut-svg-chart-highlighted {
    width: 195px;
    height: 195px;
    border-radius: 50%;
    margin: -5px 0 0 -5px;
}

.donut-diagram-circle {
    height: 185px;
    width: 185px;
    border-radius: 50%;
    background-color: white;
    border: 15px solid #3e6fd9;
}

.donut-diagram-label {
    height: 75px;
    color: #242c5c;
    font-size: 55px;
    font-family: 'Inter';
}

.ideas-right-diagram {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-bottom: -60px;
}

.dashboard-card-button.ideas-button {
    width: 130px;
}

.dashboard-card-button.dependency-button {
    width: 180px;
}

.project-dashboard-card.ideas {
    height: auto;
    min-width: 560px;
    margin-right: 0;
}

.ideas-container {
    padding: 0 30px;
}

.ideas-tab-label {
    height: 24px;
    color: #242c5c;
    font-size: 18px;
}

.ideas-tab-header {
    width: 50%;
    height: 36px;
}

.selection-line {
    width: 100%;
    height: 2px;
    background-color: #5ac57a;
}

.selection-line.yellow {
    background-color: #ffcd66;
}

.ideas-content {
    padding: 10px 30px;
    max-height: 500px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ideas-content::-webkit-scrollbar {
    display: none;
}

.tab-idea-container {
    width: 100%;
    height: 51px;
    min-height: 51px;
    border-radius: 4px;
    background-color: rgba(90, 197, 122, 0.17);
    margin-bottom: 2px;
}

.tab-idea-name {
    height: 22px;
    max-width: 100%;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tab-idea-container-row {
    width: 90%;
}

.idea-score {
    width: 35px;
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #5ac57a;

    margin-right: 15px;
}

.idea-score span {
    height: 34px;
    color: #ffffff;
    font-size: 21px;
}

.tab-idea-container.in-progress {
    background-color: rgba(255, 205, 103, 0.35);
}

.button-triangle-icon {
    border: 5px solid;
    border-color: transparent transparent transparent #3e6fd9;
}

.dashboard-project-title-container {
    min-width: 150px;
    height: 38px;
    text-align: left;
}

.dashboard-project-title {
    height: 40px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    margin-right: 5px;
}

.dashboard-project-button {
    width: 200px;
    height: 44px;
    border: 1px solid #3e6fd9;
    background-color: #ecf1fc;
    border-radius: 4px;
    padding: 0 10px 0 15px;
    margin-right: 15px;
    outline: none !important;
}

.dashboard-project-button span {
    height: 22px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.dashboard-project-button:not(:last-of-type) {
    margin-right: 15px;
}

.dashboard-project-buttons {
    position: absolute;
    top: 240px;
}

.dashboard-project-button:hover {
    background-color: #3e6fd9;
}

.dashboard-project-button:hover span {
    color: white;
}

.dashboard-project-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.project-description-container {
    margin-top: 10px;
}

.project-dashboard-cards-container {
    width: 100%;
    margin-right: 10px;
}

.project-dashboard-card .attributes-icon {
    margin-right: 49px;
}

.idea-placeholder-icon-scaled.placeholder {
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
}

.idea-container.small {
    min-width: 370px;
    width: 370px;
    min-height: 240px;
    height: 240px;
}

@media only screen and (max-width: 1340px) {
    .project-dashboard-header {
        flex-wrap: wrap;
    }

    .project-description-text {
        height: 370px;
        min-width: 140px;
        width: 100%;
    }

    .project-description-text.no-edit {
        height: 390px;
    }

    .project-dashboard-body {
        flex-wrap: wrap;
    }

    .project-header-container,
    .project-dashboard-cards-container {
        margin-right: 0;
    }

    .project-header-container {
        min-height: 420px;
    }

    .project-header-container.no-edit {
        min-height: 500px;
    }

    .project-dashboard-card {
        margin-right: 0;
    }
}

.project-dashboard-card:last-of-type {
    margin-right: 0;
}

.circulate-button {
    margin-right: 15px;
}

.people-in-project {
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
}

.people-in-project-tooltip {
    white-space: nowrap;
}

.project-edit-buttons {
    margin-top: 10px;
}

.canvas svg {
    width: 185px;
    height: 185px;
}

.ideas-donut-diagram {
    fill: none;
}

.green-sector-shadow-0 {
    -webkit-filter: drop-shadow(-1px 1px 0.7px rgba(0, 0, 0, 0.116));
            filter: drop-shadow(-1px 1px 0.7px rgba(0, 0, 0, 0.116));
}

.green-sector-shadow-50 {
    -webkit-filter: drop-shadow(0px 1px 0.7px rgba(0, 0, 0, 0.116));
            filter: drop-shadow(0px 1px 0.7px rgba(0, 0, 0, 0.116));
}

.green-sector-shadow-100 {
    -webkit-filter: drop-shadow(0px 0px 0.3px rgba(0, 0, 0, 0.274));
            filter: drop-shadow(0px 0px 0.3px rgba(0, 0, 0, 0.274));
}

.dashboard-card-donut-diagram {
    margin-bottom: 55px;
}

.ideas-count {
    width: 100%;
    text-align: left;
    flex-direction: column;
    margin-top: 10px;
}

.ideas-count span {
    height: 20px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.donut-ideas-diagram-label {
    height: 75px;
    color: #242c5c;
    font-size: 55px;
    font-family: 'Inter';
    margin-bottom: 8px;
}

.tab-idea-text-container {
    margin-left: 10px;
}

.tab-idea-text {
    height: 25px;
    line-height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.users-in-project-info {
    width: 520px;
    height: 321px;
    border-radius: 4px;
    overflow: hidden;
    font-family: 'Inter';
    padding: 20px;
    text-align: left;
    margin-bottom: 20px;
}

.users-in-project-info-close {
    justify-content: flex-end;
    min-height: 20px;
}

.users-in-project-info-main-content {
    display: flex;
    align-items: baseline;
    width: 100%;
    padding-bottom: 5px;
}

.users-in-project-info-icon {
    margin-left: 3px;
    margin-right: 5px;
}

.users-in-project-info-div-content {
    padding-bottom: 10px;
}

.users-in-project-info-text {
    width: 100%;
    color: #465664;
    font-size: 22px;
    line-height: 26px;
    white-space: wrap;
    word-break: break-word;
}

.users-in-project-info-zero-users {
    width: 100%;
    color: #465664;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    margin-top: 100px;
}

.users-in-project-info-error {
    width: 100%;
    color: #465664;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.custom-scrollbar-scrollable-container::-webkit-scrollbar {
    display: none;
}

.custom-scrollbar-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
}

.custom-scrollbar-scrollable-container {
    overflow: auto;
    height: 100%;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.custom-scrollbar {
    width: 7px;
    height: 100%;
    right: 0;
    top: 0px;
    position: absolute;
    border-radius: 7px;
    bottom: 0px;
}

.custom-scrollbar-handle {
    width: 7px;
    height: 20px;
    position: absolute;
    border-radius: 7px;
    top: 0;
}

.select-triangle-icon.scrollbar-arrow {
    position: absolute;
    left: -28px;
    top: -23px;
}

.copy-link-button {
    width: 200px;
    height: 44px;
    border: 1px solid #3e6fd9;
    background-color: #ecf1fc;
    border-radius: 4px;
    padding: 0 10px 0 15px;
    outline: none !important;
}

.copy-link-button span {
    height: 22px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
}

.copy-link-button:hover {
    background-color: #3e6fd9;
}

.copy-link-button:hover span {
    color: white;
}

.copy-link-button:hover svg {
    fill: white;
}

.copy-link-tooltip {
    margin-left: 5px;
}

.copy-link {
    line-break: anywhere;
}

.copied-link {
    background-color: #498bf7;
    color: white;
}

.message-not-found-component {
    padding-top: 30px;
}

.message-not-found-component span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.project-bar-selector {
    width: calc(100% - 205px);
    min-height: 70px;
    align-items: flex-start;
    background: #f8f9fa;
    position: fixed;
    top: 0;
    z-index: 100;
}

.project-bar-selector.compact {
    width: calc(100% - 74px);
}

.project-bar-selector.public-matrix {
    width: 100%;
}

.projects-bar-selector-title {
    width: 100%;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    text-align: left;
    word-break: break-word;
    margin-top: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.project-bar-selector hr {
    width: 95%;
    margin: 0 auto;
    margin-top: 5px;
    color: #eceef5;
}

.select-triangle-icon {
    border: 5px solid;
    border-color: #242c5c transparent transparent transparent;
    opacity: 0.54;
    margin: 5px 0 0 25px;
}

.addressed-cells-count {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15.5px;
    margin-right: 8px;
}

.progress-bar-container {
    width: 210px;
}

.addressed-cells-counter {
    align-items: center;
    margin-right: 28px;
}

.project-bar-selector-option span {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15px;
}

.project-bar-selector-option {
    background-color: #f7f8fa;
    display: flex;
    align-items: center;
    height: 33px;
    min-height: 33px;
    padding: 0 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #242c5c;
    border-bottom: 1px solid #eceef5;
}

.project-bar-selector-option:hover {
    background-color: white;
}

.project-bar-selector-option:hover span {
    color: #3c6ad7;
}

.project-bar-header {
    height: 70px;
    display: flex;
    align-items: baseline;
}

.projects-bar-select-container {
    border: 2px solid #8a93c3;
    padding: 14px;
    font-size: 10px;
    width: 23%;
    height: 80%;
    border-radius: 6px;
    margin-left: 40px;
}

.toolbar-border {
    border-bottom: 2px solid #eeee;
    border-radius: 1px;
    padding-top: 0;
    padding-bottom: 80px;
}

.free-height {
    height: auto;
}

.dependency-matrix-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
    min-height: 500px;
}

.component-cell-container:hover span {
    color: #3e6fd9;
}

.dependency-matrix-header-tutorial {
    margin: 10px 0 20px 0;
}

.dependency-matrix-title {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    white-space: nowrap;
    margin-right: auto;
}

.dependency-matrix-table-wrapper {
    margin-top: 50px;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 190px);
}

.dependency-matrix-container {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    overflow-x: auto;
    overflow-y: auto;
}

.table-cell-container {
    width: 63px;
    height: 34px;
    border: 1px solid #d7dbe8;
}

.table-cell {
    border-radius: 4px;
    width: 63px;
    height: 34px;
    background-color: #f7f8fa;
}

.table-cell.pointer.hover {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
}

.attribute-cell {
    width: 120px;
    height: 34px;
    justify-content: flex-start;
    border: 1px solid transparent;
    z-index: 1;
}

.attribute-cell:hover {
    border: 1px solid #3e6fd9;
    border-radius: 4px;
}

.matrix-attribute-title {
    height: auto;
    min-height: 13px;
    color: #5b6185;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    max-height: 34px;
    max-width: 100%;
    word-break: break-word;
    overflow: hidden;
}

.matrix-attribute-title.column {
    text-align: center;
}

.component-cell {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    width: 123px;
    height: 100%;
}

.component-cell:hover {
    box-sizing: border-box;
    text-decoration: underline;
    color: #3e6fd9;
    cursor: pointer;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #3e6fd9;
}

.matrix-component-title {
    display: block;
    min-height: 16px;
    height: auto;
    max-height: 34px;
    line-height: 18px;
    color: #5b6185;
    font-size: 13px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 130px;
}

.matrix-component-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.matrix-component-title-container:hover {
    background-color: white;
    border-radius: 4px;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
    border: 1px solid #3e6fd9;
}

.matrix-component-title-container:hover .matrix-component-title {
    color: #3e6fd9;
}

.matrix-attribute-title-container {
    width: 63px;
    height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.matrix-attribute-title-container:hover {
    background-color: white;
    border-radius: 4px;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
    border: 1px solid #3e6fd9;
}

.matrix-attribute-title-container:hover span {
    color: #3e6fd9;
}

.dependency-matrix-table td {
    border: 1px solid #d7dbe8;
}

.dependency-matrix-table tr td:last-of-type {
    border-right: none;
}

.dependency-matrix-table tr td:first-of-type {
    border-right: none;
    border-left: none;
}

.dependency-matrix-table tr:first-of-type td {
    border-top: none;
    border-bottom: none;
    z-index: 3;
}

.dependency-matrix-table tr:nth-of-type(2) td {
    border-top: none;
}

.dependency-matrix-table tr:last-of-type td {
    border-bottom: none;
}

.component-type-indicator-container {
    background: #f7f8fa;
    margin: 0;
    width: 87px;
    min-width: 87px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    padding-top: 72px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;
}

.dependency-matrix-table tr:nth-of-type(1) td:not(:first-of-type) {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 87px;
}

.dependency-matrix-table tr:nth-of-type(2) td {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    top: 36px;
    left: 87px;
    box-shadow: 0px 2px 0px -1px #d7dbe8;
}

td.component-cell-container {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 87px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

tr td:first-of-type.component-cell-container {
    border-bottom: none;
}

td.attribute-cell-container {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 209px;
    box-shadow: 2px 0px 0px -1px #d7dbe8;
    z-index: 1;
}

.attribute-cell-container:hover {
    background-color: white;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
}

.attribute-cell-container:hover span {
    color: #3e6fd9;
}

.internal-type-indicator {
    height: 120px;
    width: 27px;
    border-radius: 4px;
    background-color: #eceef5;
}

.external-type-indicator {
    height: 120px;
    width: 27px;
    border-radius: 4px;
    background-color: #dfe4f4;
}

.component-type-text span {
    height: 49px;
    color: rgba(36, 44, 92, 0.75);
    font-size: 14px;
}

.transform-90-deg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.component-type-text:hover {
    position: absolute;
}

td.highlighted {
    background-color: #d9e2f6 !important;
}

.matrix-corner-cell {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 87px;
    top: 0;
    box-shadow: 2px 2px 0px -1px #d7dbe8;
    z-index: 1;
}

.table-cell-container {
    position: relative;
}

.cell-mark span {
    font-size: 18px;
    font-family: 'Inter';
    color: black;
}

.matrix-input-container {
    margin: 0 0 10px 20px;
}

.matrix-input-container.first {
    margin-left: auto;
}

@media only screen and (max-width: 980px) {
    .matrix-input-container .custom-search-input .custom-search-input-input {
        max-width: 220px;
    }

    .matrix-input-container {
        margin-left: 10px;
    }
}

.cell-mark span.cell-binary-text {
    color: #242c5c;
    white-space: pre-wrap;
}

.matrix-placeholder {
    padding-top: 30px;
}

.matrix-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.cell-dropdown {
    position: absolute;
    width: 415px;
    left: calc(50% - 415px / 2);
    top: 50px;
    background-color: white;
    border: 2px solid lightblue;
    border-radius: 5px;
    z-index: 3;
}

.cell-dropdown::before {
    content: '';
    height: 0;
    width: 0;
    top: -21px;
    left: calc(50% - 17px);
    position: absolute;
    border: 17px solid transparent;
    border-bottom: 20px solid lightblue;
    border-top: 0;
}

.cell-dropdown::after {
    content: '';
    height: 0;
    width: 0;
    top: -18px;
    left: calc(50% - 15px);
    position: absolute;
    border: 15px solid transparent;
    border-bottom: 18px solid white;
    border-top: 0;
}

.cell-dropdown-row {
    height: 40px;
    width: 400px;
    margin-top: 5px;
    background-color: #e8f6ec;
    border-radius: 4px;
    position: relative;
    border: 2px solid transparent;
}

.cell-dropdown-row-text {
    height: 21px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-dropdown-row.green {
    background-color: #e9f6ed;
}

.cell-dropdown-row.yellow {
    background-color: #fff4de;
}

.cell-dropdown-row.red {
    background-color: #fde6e1;
}

.cell-dropdown-row.white {
    background-color: #ffffff;
    border: 1px solid #cdd1e5;
}

.cell-dropdown-row:hover {
    border: 2px solid #3e6fd9;
}

.cell-dropdown-row:hover.green {
    background-color: #d0e9d8;
}

.cell-dropdown-row:hover.yellow {
    background-color: #f3e3c4;
}

.cell-dropdown-row:hover.red {
    background-color: #f0cec7;
}

.cell-dropdown-row-rating {
    flex: 1 1 50px;
    min-width: 5%;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.cell-dropdown-row-name {
    flex: 1 1 345px;
    min-width: 25%;
}

.cell-dropdown-idea-score {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #5ac57a;
}

.cell-dropdown-idea-score span {
    height: 26px;
    color: #ffffff;
    font-size: 20px;
    margin-left: 1.5px;
}

.cell-dropdown-row-color-rating {
    height: 100%;
    width: 7px;
    min-width: 7px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.cell-dropdown-row:hover .cell-dropdown-row-color-rating {
    height: 100%;
    border-radius: 2px 0 0 2px;
}

.cell-dropdown-row-buttons-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .cell-dropdown-row-buttons-container {
    opacity: 1;
}

.open-details-button {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 14px;
}

.open-details-button svg {
    color: #3e6fd9;
}

.open-details-button:hover {
    background-color: #3e6fd9;
}

.open-details-button:hover span {
    color: white;
}

.open-details-button:hover svg {
    color: white;
}
.open-details-button .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.dependency-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.dependency-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.dependency-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.dependency-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.dependency-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
}

.dependency-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 178px;
    min-width: 300px;
}

.dependency-tabs-line {
    min-height: 3px;
    height: 3px;
    width: 178px;
    min-width: 235px;
    background-color: transparent;
}

.dependency-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.dependency-tabs-line {
    min-width: 315px;
    background-color: transparent;
    transition: background-color 0.5s;
}

.dependency-tabs-line.active {
    background-color: #3e6fd9;
    transition: background-color 0.5s;
}

.dependency-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.dependency-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.dependency-tab {
    max-width: 20%;
    min-width: 235px;
}

.matrix-dialog-header-imagine {
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    width: 98%;
    margin: 0 10px 0 10px;
    color: #242C5C;
}

.matrix-dialog-header-imagine u {
    margin:  0 10px 0 10px;
    padding-bottom: 20px;
}

.dependency_tooltip {
    color: #242C5C;
    font-size: 18px;
    white-space: nowrap;
    margin-bottom: 0;
}
.custom-toggle-container {
    height: 20px;
    width: 40px;
    position: relative;
}

.custom-toggle-container.disabled {
    opacity: 0.5;
}

.custom-toggle-rectangle {
    width: 34px;
    height: 14px;
    background-color: #c3c5d3;
    border-radius: 10px;
}

.custom-toggle-circle {
    height: 20px;
    width: 20px;
    background-color: #888da8;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-toggle-circle.active {
    left: unset;
    right: 0px;
    background-color: #3e6fd9;
}

.custom-toggle-rectangle.active {
    background-color: #9db6ed;
}

.custom-toggle-label-default {
    height: 24px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500px;
    margin-left: 30px;
    color: #465664;
    -webkit-user-select: none;
            user-select: none;
}

.custom-toggle-label-default.active {
    color: #3e6fd9;
}

.custom-toggle-label-default.disabled {
    opacity: 0.5;
}

.dependency-modal-label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.dependency-modal-label span {
    height: 33px;
}

.dependency-modal-label span u {
    max-width: 300px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.dependency-modal__close-issue-icon,
.dependency-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 44px;
}

.dependency-modal__add-issue-icon,
.dependency-modal__add-benefit-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 20px;
}

.dependency-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.dependency-modal-benefit-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.dependency-modal-challenges__initials {
    cursor: pointer;
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.dependency-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.dependency-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.dependency-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.dependency-modal-description {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #242c5c;
}

.dependency-modal-description u {
    margin: 0 10px 0 10px;
}

.dependency-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 7px 7px 7px 7px;
    font-weight: 500;
}

.dependency-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.dependency-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.dependency-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.dependency-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.dependency-modal__button-ai_name-idea,
.dependency-modal__button-ai_image {
    width: 50%;
    position: relative;
    top: 31px;
    font-size: 14px;
}

.dependency-modal__button-ai_name-idea {
    left: 190px;
}

.dependency-modal__button-ai_image {
    left: 10px;
}

.dependency-modal__button-ai_image p {
    margin: 0;
}

.dependency-modal-container__name,
.dependency-modal-container__describe {
    width: 380px;
}

.dependency-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 13px;
}

.dependency-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.dependency-modal__challenges-header {
    height: 33px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 15px;
}

.dependency-modal-challenges-input,
.dependency-modal-benefit-input {
    margin-top: -8px;
}

.dependency-modal-benefit-input__empty,
.dependency-modal-challenges-input__empty {
    background-color: white;
}

.dependency-modal-benefit__title.textarea,
.dependency-modal-challenges-input__empty_title {
    font-size: 18px;
}

.dependency-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.dependency-modal-benefit {
    margin-bottom: 3px;
}

.dependency-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.dependency-modal-benefit-line {
    border-top: 2px solid #c3cde2;
    margin-top: 20px;
}

.dependency-modal__close-solve-icon,
.dependency-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.dependency-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.dependency-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.dependency-modal__upload-image-button {
    position: relative;
    top: 44px;
    height: 59px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
    right: 8px;
}

.dependency-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.dependency-modal__upload-image-button_disabled,
.dependency-modal__upload-image-button_disabled:hover,
.dependency-modal__button-ai_image-disabled,
.dependency-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}


.carousel-container__image-container{
    margin-right: 10px;
}
.text-loader {
  font-size: 16px;
  color: #9880ff;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
          animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.dot-flashing::before, 
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: dot-flashing 1s infinite alternate;
          animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: dot-flashing 1s infinite alternate;
          animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
.generate-names {
    width: 622px;
    height: 662px;
}

.generate-names-header {
    height: 13%;
    align-items: center;
    justify-content: space-between;
    background-color: #fce8e9;
    border-radius: 4px 4px 0 0;
    padding: 10px 28px;
}

.generate-names-header h2 {
    font-size: 1.6rem;
    margin: 0;
    color: #cc4b4f;
}

.generate-names-scroll {
    overflow: scroll;
}

.generate-names-list {
    margin: 20px;
}

.generate-names-list__name {
    border: 2px solid #eceef5;
    border-radius: 4px;
    margin: 0 0 15px 0;
    padding: 5px 25px;
    width: 100%;
}

.generate-names-list__name p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    font-weight: 500;
    height: 9vh;
    font-size: 20px;
}

.generate-names-list__name.selected {
    border: 2px solid #f1666a;
}

.generate-names-list__name.selected p {
    color: #f1666a;
}

.generate-names__button {
    color: #fff;
    background-color:  #3e6fd9;
    margin: 0 20px 10px;
    border: none;
    border-radius: 4px;
    height: 10%;
    font-size: 20px;
}

.generate-names__button:disabled {
    opacity: 0.5;
}
.generate-modal-window {
	width: 622px;
	height: 662px;
}

.generate-modal-window-header {
	height: 13%;
	align-items: center;
	justify-content: space-between;
	background-color: #fce8e9;
	border-radius: 4px 4px 0 0;
	padding: 10px 28px;
}

.generate-modal-window-header h2 {
	font-size: 1.6rem;
	margin: 0;
	color: #cc4b4f;
}

.generate-modal-window-scroll {
	overflow: scroll;
}

.generate-modal-window-list {
	margin: 20px;
}

.generate-modal-window-list__with-options {
	margin-top: 0;
	height: 100%;
}

.generate-modal-window-list__option {
	border: 2px solid #eceef5;
	border-radius: 4px;
	margin: 0 0 15px 0;
	padding: 5px 25px;
	width: 100%;
	text-align: left;
}

.generate-modal-window-list__option.selected {
	border: 2px solid #f1666a;
}

.generate-modal-window-list__option.selected p {
	color: #f1666a;
}

.generate-modal-window__button {
	color: #fff;
	background-color:  #3e6fd9;
	margin: 0 20px 10px;
	border: none;
	border-radius: 4px;
	height: 10%;
	font-size: 20px;
}

.generate-modal-window__button:disabled {
	opacity: 0.5;
}

.generate-modal-window-list__option-title {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 0;
	margin-top: 20px;
}

.generate-modal-window-list__option-description {
	margin-bottom: 26px;
	font-weight: normal;
}
.custom-input-category-container .custom-input {
    color: #242c5c;
    font-size: 15px;
}

.custom-input-category-container .custom-input::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.custom-input-category-container .custom-input::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.custom-input-category-container {
    margin-right: 8px;
}

.custom-input-category-container .category-button {
    border: none;
    background: transparent;
    width: 35px;
    top: 10px;
    right: 5px;
    outline: none !important;
}

.bullet-point-container {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.bullet-point-item {
    height: 16px;
    width: 16px;
    background-color: #848ca6;
    border-radius: 50%;
}

.custom-input-input {
    height: 45px;
    width: 100%;
    border-radius: 4px 0 0 4px;
    border: 2px solid #eceef5;
    padding: 0 16px;
    color: #242c5c;
    font-size: 14px;
    border-right: none;
}

.custom-input-input::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.custom-input-input::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.custom-input-select {
    border-radius: 0 4px 4px 0;
    padding-left: 14px;
    font-family: 'Inter';
    width: 139px;
    min-width: 139px;
    height: 45px;
    background-color: #eceef5;
    border: 2px solid #eceef5;
    color: #242c5c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.custom-input-select-container {
    width: 270px;
    min-width: 270px;
    height: auto;
    min-height: 45px;
    font-family: 'Inter';
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.custom-input-select-container span {
    -webkit-user-select: none;
            user-select: none;
}

.custom-input-triangle-icon {
    border: 5px solid;
    border-color: #858aa6 transparent transparent transparent;
    position: absolute;
    right: 10px;
}

.custom-input-select-options {
    position: fixed;
    height: 120px;
    width: 270px;
    min-width: 270px;
    min-height: 45px;
    background-color: #eceef5;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    z-index: 100;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.custom-input-container-option {
    height: 39px;
    min-height: 39px;
    padding: 0 25px 0 15px;
}

.custom-input-container-option > img {
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    margin-right: 8px;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
}

.custom-input-container-option:not(.first):not(:last-of-type) {
    border-bottom: 2px solid #e8ebf2;
}

.custom-input-container-option.first {
    border-bottom: 2px solid #e8ebf2;
    height: calc(100% - 8px);
    margin-bottom: auto;
    min-height: 45px;
}

.custom-input-container-option:hover {
    background-color: #dee1eb;
}

.custom-input-first-option .custom-input-container-option {
    border-radius: 0 4px 4px 0;
    background-color: #eceef5;
}

.custom-input-first-option .custom-input-container-option.open {
    border-radius: 0 4px 0 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.custom-input-first-option {
    position: relative;
    height: 100%;
}

.custom-input-option-text {
    height: 20px;
    max-width: 80px;
    color: #242c5c;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-input-option-text.placeholder {
    color: #888da8;
    text-overflow: ellipsis;
}

.custom-input-input:hover {
    border: 2px solid #9eb6ec;
}

.custom-input-input:focus {
    border: 2px solid #9eb6ec;
}

.custom-input-input.empty {
    background-color: #f6f7f9;
}

.benefits-textarea-container {
    width: 100%;
    min-height: 45px;
}

.benefits-textarea-container textarea {
    width: 100%;
    border-radius: 4px 0 0 4px;
    color: #242c5c;
    font-size: 15px;
    line-height: 15px;
    padding: 13px 16px 13px 16px;
    max-height: 95px;
}

.benefits-textarea-container textarea::-webkit-input-placeholder {
    color:  #75798d;
}

.benefits-textarea-container textarea::placeholder {
    color:  #75798d;
}

.custom-category-checkbox {
    margin-right: 10px;
}

.custom-button-panel {
    display: flex;
    grid-gap: 13px;
    gap: 13px;
    margin-right: 20px;
}

.custom-button-panel > button {
    padding: 0;
    border: none;
}

.custom-button-panel > button svg {
    width: 16px;
    height: 16px;
}

.custom-input-container-option .custom-input-category-container input {
    text-overflow: ellipsis;
    padding: 0 5px;
    margin: 0;
}
.custom-score-input-container {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 0;
  }
  
  .custom-score-input-circle {
    height: 50px;
    width: 50px;
    border: 2px solid #3e6fd9;
    background-color: #ffffff;
    border-radius: 100%;
    color: #3e6fd9;
    margin-right: 30px;
    font-size: 21px;
    line-height: 38px;
    z-index: 100;
    -webkit-user-select: none;
            user-select: none;
    position: relative;
}

.custom-score-input-circle:last-of-type {
    margin-right: 0;
}

.custom-score-input-circle.active span {
    color: white !important;
}

.custom-score-input-circle span {
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 29px;
    height: 30px;
    line-height: 32px;
  }
  
  .custom-score-selection {
    width: 65px;
    height: 60px;
    min-width: 65px;
    min-height: 60px;
    border-radius: 4px;
    border: 2px solid transparent;
}

.custom-sub-label {
    font-family: 'Inter';
    font-size: 12px;
    color: black;
}

.user-initials {
    width: 26px;
    min-width: 26px;
    height: 22px;
    min-height: 22px;
    background-color: #fde8e9;
    border-radius: 2px;
}

.user-initials span {
    height: 17px;
    line-height: 17px;
    color: #272727;
    font-size: 13px;
    text-transform: uppercase;
}

.user-initials span.cn {
    height: 17px;
    line-height: 17px;
    color: #272727;
    font-size: 12px;
    text-transform: uppercase;
}

.user-initials.disabled {
    background-color: rgba(241, 102, 106, 0.11);
}

.roundedInitials {
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
}

.issues-input-container textarea {
    background-color: #fff;
}

.matrix-dialog-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.matrix-dependency img {
    pointer-events: none;
}

.matrix-dialog-header {
    width: 100%;
    height: 300px;
    background-color: #eceef5;
    position: relative;
}

.matrix-dialog-header-icon {
    margin-right: 10px;
    width: 26px;
}

.matrix-dialog-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
}

.matrix-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    height: 38px;
    line-height: 38px;
}

.matrix-dialog-body {
    padding: 20px 140px 0;
    width: 100%;
}

.matrix-dialog-body.benefits-container {
    padding: 40px 0 0;
}

.matrix-dialog-label {
    align-self: flex-start;
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.matrix-dialog-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.matrix-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.matrix-dialog-button.disabled {
    background-color: #888da8;
    color: white;
}

.matrix-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.matrix-dialog-button span {
    height: 20px;
    font-family: 'Inter';
    font-size: 18px;
}

.dialog-buttons-arrow {
    font-family: 'Inter';
    line-height: 20px;
}

.dialog-buttons-arrow-right {
    margin-left: 10px;
    font-size: 20px;
    margin-bottom: 4px;
}

.dialog-buttons-arrow-left {
    margin-right: 10px;
    font-size: 20px;
    margin-bottom: 4px;
}

.dialog-buttons-arrow-right {
    margin-left: 10px;
    font-size: 20px;
    margin-bottom: 4px;
}

.dialog-buttons-arrow-left {
    margin-right: 10px;
    font-size: 20px;
    margin-bottom: 4px;
}

.matrix-dialog-button.control {
    width: 131px;
}

.matrix-dialog-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.matrix-dialog-button.outlined span {
    color: #3e6fd9;
}

.dependency-text {
    width: 90%;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 20px;
    align-self: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 30px;
    height: 60px;
}

.dependency-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.dependency-component {
    height: 50px;
    width: 240px;
}

.dependency-component img {
    margin-right: 10px;
    margin-left: 10px;
}

.dependency-component span,
.dependency-attribute span {
    height: 26px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    letter-spacing: -0.3px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.dependency-attribute {
    width: 240px;
    height: 50px;
    border-right: 2px solid #c0c4de;
}

.dependency-attribute img {
    margin-right: 10px;
    margin-left: 10px;
}

.matrix-dependency {
    padding: 0 28px;
}

.matrix-dialog-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.dependency-influence {
    min-height: 3px;
    min-width: 10px;
    max-width: 35px;
    width: 100%;
    background-color: #cdd1e5;
}

.dependency-influence span {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.subtraction-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.subtraction-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.subtraction-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.subtraction-tab-button div {
    height: 100%;
}

.subtraction-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.subtraction-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
    cursor: pointer;
}

.completed-idea-button div {
    height: 25%;
}

.subtraction-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 178px;
    min-width: 300px;
}

.subtraction-tabs-line {
    min-height: 3px;
    height: 3px;
    min-width: 300px;
    width: 178px;
    background-color: transparent;
}

.subtraction-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.subtraction-tabs-line {
    background-color: transparent;
    transition: background-color 0.5s;
}

.subtraction-tabs-line.active {
    min-width: 300px;
    background-color: #3e6fd9;
    transition: background-color 0.5s;
}

.subtraction-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.subtraction-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.subtraction-tab {
    max-width: 20%;
    min-width: 235px;
}

.tab-label-optional {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.subtraction-description-describe {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #242C5C;
}

.subtraction-description-describe u {
    margin: 0 10px 0 10px;
}

.subtraction-imagine-the {
    height: 32px;
}

.subtraction-without-the {
    height: 32px;
}

.subtraction-modal-header-imagine {
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    width: 98%;
    margin: 0 10px 0 10px;
    color: #242C5C;
}

.subtraction-modal-header-imagine u {
    margin: 0 10px 0 10px;
}
.subtraction-modal-benefits-container__label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.subtraction-modal-benefits-container__label span {
    height: 33px;
}

.subtraction-modal-benefits-container__label span u {
    max-width: 300px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.carousel-container__image-container{
    margin-right: 10px;
}

.describe-new-product-page-one {
    width: 99%;
    margin-bottom: 20px;
    font-size: 26px;
}

.subtraction-modal-input {
    margin-bottom: 15px;
}

.subtraction-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 37px;
}

.subtraction-modal__close-issue-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 32px;
}

.subtraction-modal__add-benefit-icon,
.subtraction-modal__add-issue-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 20px;
}

.subtraction-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.subtraction-modal-benefit {
    margin-bottom: 3px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.subtraction-modal-benefits-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.subtraction-modal-benefits-container button {
    cursor: pointer;
}

.mt--8 {
    margin-top: -8px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.issues-input-container textarea {
    width: 100%;
    border: 2px solid #eceef5;
    color: #3e6fd9;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 16px 14px 16px;
    max-height: 95px;
}

.issues-input-container textarea::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.subtraction-modal__benefits-header {
    height: 33px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 15px;
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.subtraction-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.subtraction-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 7px 7px 7px 7px;
    font-weight: 500;
}

.subtraction-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.subtraction-modal__button-ai_name-idea,
.subtraction-modal__button-ai_image {
    position: relative;
    font-size: 14px;
    width: 50%;
}

.subtraction-modal__button-ai_name-idea {
    top: 30px;
    left: 190px;
}

.subtraction-modal__button-ai_image {
    top: 30px;
    left: 94px;
}

.subtraction-modal__button-ai_image p {
    margin: 0;
}

.subtraction-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 13px;
}

.subtraction-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.subtraction-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.subtraction-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.subtraction-modal-container__name,
.subtraction-modal-container__describe {
    width: 380px;
}

.subtraction-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.subtraction-modal-benefit-input,
.subtraction-modal-challenges-input {
    margin-top: -8px;
}

.subtraction-modal-benefit-input__empty,
.subtraction-modal-challenges-input__empty {
    background-color: white;
}

.subtraction-modal-benefit-line {
    border-top: 2px solid #c3cde2;;
    margin-top: 20px
}

.subtraction-modal-benefit__title.textarea,
.subtraction-modal-challenges-input__empty_title {
    font-size: 18px;
}

.subtraction-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.subtraction-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.subtraction-modal-challenges__initials {
    cursor: pointer; 
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.subtraction-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.subtraction-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.subtraction-modal__close-solve-icon,
.subtraction-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.subtraction-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.subtraction-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}

.subtraction-modal__upload-image-button {
    position: relative;
    top: 42px;
    right: 8px;
    height: 59px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
}

.subtraction-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.subtraction-modal__upload-image-button_disabled,
.subtraction-modal__upload-image-button_disabled:hover,
.subtraction-modal__button-ai_image-disabled,
.subtraction-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}
.subtraction-modal-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.subtraction-modal-header {
    width: 100%;
    height: 257px;
    background-color: #eceef5;
    position: relative;
}

.subtraction-modal-header-icon {
    margin-right: 10px;
    height: 26px;
}

.subtraction-modal-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
}

.subtraction-modal-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
}

.subtraction-modal-body {
    padding: 40px 140px 0;
    width: 100%;
}

.subtraction-modal-body.benefits-container {
    padding: 40px 0 0;
}

.subtraction-modal-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.subtraction-modal-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.subtraction-modal-button.disabled {
    background-color: #888da8;
    color: white;
}

.subtraction-modal-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.subtraction-modal-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 18px;
}

.subtraction-modal-button.control {
    width: 131px;
}

.subtraction-modal-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.subtraction-modal-button.outlined span {
    color: #3e6fd9;
}

.subtraction-text {
    margin-top: 20px;
    margin-bottom: auto;
    width: 100%;
    height: 24px;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 28px;
    line-height: 22px;
    align-self: center;
    max-width: 900px;
}

.subtraction-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.subtraction-modal-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.bgc-white {
    background-color: white !important;
}

.component-dialog-wrapper {
    width: 622px;
    height: 600px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.component-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 4px 4px 0 0;
}

.component-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.component-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 165px);
}

.component-dialog-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
    align-self: flex-start;
}

.component-dialog-importance-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
}

.component-dialog-label.active {
    color: #3e6fd9;
}

.component-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.component-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.component-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.component-dialog-input-container {
    justify-content: space-between;
}

.location-toggle {
    margin: 0 20px 0 10px;
}

.component-importance {
    height: 36px;
}

.custom-score-component-input-container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-score-component-input-circle {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  color: #3e6fd9;
  margin-right: 30px;
  font-size: 21px;
  line-height: 38px;
  z-index: 100;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  border: white 4px solid;
}

.custom-score-component-input-circle:last-of-type {
  margin-right: 0;
}

.custom-score-component-input-circle.active span {
  color: white !important;
}

.custom-score-component-input-circle span {
  color: #3e6fd9;
  font-family: 'Inter';
  font-size: 13px;
  height: 30px;
  line-height: 32px;
}

.custom-score-component-selection {
  width: 55px;
  height: 55px;
  margin: 0 4px;
  border-radius: 4px;
  border: 2px solid transparent;
}

.custom-component-sub-label {
  font-family: 'Inter';
  font-size: 12px;
  color: black;
}

.grey-line {
  background-color: #eceef5;
  width: 260px;
  height: 17px;
  display: flex;
  position: absolute;
}

.components-dashboard-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
}

.search-input-container-subtraction-components {
    max-width: 326px;
}

.components-dashboard-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
    margin-left: 10px;
}

.add-component-button-round {
    margin: 0 20px;
    color: #3e6fd9;
}

.add-component-button-round:hover {
    color: #3061ae;
}

.components-counter-container {
    height: 45px;
    padding-bottom: 10px;
}

.components-tooltip {
    margin-right: auto;
}

.components-cards-wrapper {
    max-height: 100%;
}

.add-component-button {
    width: 346px;
    height: 49px;
    background-color: #d4def2;
    padding: 0 17px 0 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    margin-bottom: 10px;
}

.add-component-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.add-component-button svg {
    width: 13px;
    height: 13px;
}

.add-component-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.subtraction-component-card {
    width: 398px;
    min-width: 264px;
    height: 754px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    position: relative;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
}

.subtraction-component-card:hover {
    border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
    width: 100%;
    background-color: #eceef5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    border-radius: 4px;
    height: 30px;
}

.subtraction-component-card-header .link-icon {
    margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
    margin-right: 8px;
}

.cant-create-idea {
    text-align: center;
    color: #8a93c3;
    font-family: 'Inter';
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subtraction-component-card-title {
    line-height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    letter-spacing: -0.36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-height: 23px;
    margin-right: 3px;
}

.subtract-component-card-menu-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 20px;
    outline: none !important;
}

.component-type {
    font-family: 'Inter';
    width: 59px;
    height: 22px;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.component-type span {
    height: 18px;
    color: #3e6fd9;
    font-size: 14px;
    font-weight: 500;
}

.component-type.external {
    background-color: #fdeeee;
}

.component-type.external span {
    color: #f1666a;
}

.component-card-body {
    padding: 0 15px;
}

.subtract-component-card {
    border-radius: 4px;
    width: 346px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
}

.subtract-component-card.disabled {
    background-color: #d6dbed;
}

.subtract-component-card.disabled {
    background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card.disabled {
    background-color: #d6dbed;
}

.dropdown-last-idea {
    margin-bottom: 5px;
}

.subtract-component-card:hover {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.component-card-container {
    border-radius: 1px;
    padding-top: 10px;
    width: 100%;
    height: 600px;
    margin-bottom: 10px;
}

.subtract-component-card-container {
    width: 100%;
    height: 570px;
    margin-bottom: 10px;
}

.subtract-component-card-container.compact {
    height: 196px;
}

.subtraction-component-card-tooltip {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 5px;
    width: 100%;
}

.subtraction-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.subtraction-header-container-tutorial,
.subtraction-header-container-settings {
    margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
    height: 49px;
    min-height: 49px;
    margin-bottom: 7px;
    position: relative;
}

.subtract-component-card-header-compact {
    padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
    display: flex;
    flex-direction: row;
}

.subtract-component-card-menu-button.compact {
    width: 22px;
}

.component-card.compact {
    height: 370px;
}

.subtraction-component-type-container {
    margin-top: 16px;
}

.subtraction-component-card-header.compact {
    margin: 0;
}

.subtract-component-card-title-container {
    max-width: 100%;
}

.subtract-component-card-title-container span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    word-break: break-word;
    max-height: 40px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    max-width: 269px;
}

.subtract-component-card-title-container.compact span {
    line-height: 20px;
    max-height: 36px;
    padding-top: 2px;
}

.component-card img {
    pointer-events: none;
}

.subtraction-component-card-header-text {
    width: 100%;
    height: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.component-droppable-container {
    min-height: calc(100% - 10px);
}

.component-placeholder {
    padding-top: 30px;
}

.component-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.subtraction-component-card-header-top {
    width: 363px;
    min-width: 363px;
    justify-content: space-between;
    height: 32px;
}

.row-dropdown-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .row-dropdown-container {
    opacity: 1;
}

.open-details-button-subtraction {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button-subtraction span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 16px;
}

.open-details-button-subtraction svg {
    color: #3e6fd9;
}

.open-details-button-subtraction:hover {
    background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
    color: white;
}

.open-details-button-subtraction:hover svg {
    color: white;
}
.open-details-button-subtraction .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button-subtraction.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.active-component {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
}

.top-2 {
    position: relative !important;
    top: 2px;
}

.subtraction-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.subtraction-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.eureka-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.eureka-tab-button div {
    height: 100%;
}

.subtraction-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.eureka-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
    cursor: pointer;
}

.completed-idea-button div {
    height: 25%;
}

.eureka-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 210px;
    min-width: 300px;
}

.eureka-tabs-line {
    min-height: 3px;
    height: 3px;
    width: 300px;
    background-color: transparent;
}

.eureka-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.eureka-tabs-line {
    background-color: transparent;
    transition: background-color 0.5s;
}

.eureka-tabs-line.active {
    background-color: #3e6fd9;
    min-width: 300px;
    transition: background-color 0.5s;
}

.subtraction-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.subtraction-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.eureka-tab {
    max-width: 25%;
}

.tab-label-optional {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.subtraction-description {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.eureka-modal-title {
    margin-bottom: auto;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 24px;
    align-self: center;
    max-width: 900px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.eureka-modal-title u {
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px;
    margin: 0 5px;
}

.eureka-modal-label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.eureka-modal-label span {
    height: 33px;
}

.eureka-modal-label span u {
    max-width: 300px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.carousel-container__image-container{
    margin-right: 10px;
}

.describe-new-product-page-one {
    width: 99%;
    margin-bottom: 20px;
    font-size: 26px;
}

.subtraction-modal-input {
    margin-bottom: 15px;
}

.eureka-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 37px;
}

.eureka-modal__add-issue-icon,
.eureka-modal__add-benefit-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 12px;
}

.eureka-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.benefit-input {
    margin-bottom: 3px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.eureka-modal__benefit-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

.eureka-modal__benefit-input-container button {
    cursor: pointer;
}

.mt--8 {
    margin-top: -8px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.issues-input-container textarea {
    width: 100%;
    border: 2px solid #eceef5;
    color: #3e6fd9;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 16px 14px 16px;
    max-height: 95px;
}

.issues-input-container textarea::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.eureka-modal-benefit {
    margin-bottom: 3px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.eureka-modal__issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

.eureka-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.eureka-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.eureka-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.eureka-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-weight: 500;
}

.eureka-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.eureka-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.eureka-modal__button-ai_name-idea,
.eureka-modal__button-ai_image {
    position: relative;
    font-size: 14px;
    width: 50%;
}

.eureka-modal__button-ai_name-idea {
    left: 190px;
    top: 31px;
}

.eureka-modal__button-ai_image {
    left: 94px;
    top: 28px;
}

.eureka-modal__button-ai_image p {
    margin: 0;
}

.eureka-modal__button-ai_disabled,
.eureka-modal__button-ai_disabled:hover {
    color: #fff;
    background-color: #888da8;
}

.eureka-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 15px;
}

.eureka-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.eureka-modal-container__name,
.eureka-modal-container__describe {
    width: 380px;
}

.eureka-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.eureka-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.eureka-modal-challenges__title.textarea,
.eureka-modal-benefit__title.textarea,
.eureka-modal-challenges-input__empty_title {
    font-size: 18px;
}

.eureka-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.eureka-modal-challenges-input,
.eureka-modal-benefit-input {
    margin-top: -8px;
}

.eureka-modal-challenges-input__empty,
.eureka-modal-benefit-input__empty {
    background-color: white;
}

.eureka-modal-benefit-line {
    border-top: 2px solid #c3cde2;;
    margin-top: 20px
}

.eureka-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.eureka-modal-challenges__initials {
    cursor: pointer; 
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.eureka-modal__close-issue-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 32px;
}

.eureka-modal__close-solve-icon,
.eureka-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.eureka-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.eureka-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}

.eureka-modal__upload-image-button {
    position: relative;
    top: 17px;
    right: 8px;
    height: 55px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
}

.eureka-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.eureka-modal__upload-image-button_disabled,
.eureka-modal__upload-image-button_disabled:hover,
.eureka-modal__button-ai_image-disabled,
.eureka-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}
.subtraction-dialog-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.subtraction-dialog-header {
    width: 100%;
    height: 257px;
    background-color: #eceef5;
    position: relative;
}

.subtraction-dialog-header-icon {
    margin-right: 10px;
    height: 26px;
}

.subtraction-dialog-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
}

.subtraction-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
}

.subtraction-dialog-body {
    padding: 40px 140px 0;
    width: 100%;
}

.subtraction-dialog-body.benefits-container {
    padding: 40px 0 0;
}

.eureka-dialog-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.eureka-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.eureka-dialog-button.disabled {
    background-color: #888da8;
    color: white;
}

.eureka-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.eureka-dialog-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 18px;
}

.eureka-dialog-button.control {
    width: 131px;
}

.eureka-dialog-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.eureka-dialog-button.outlined span {
    color: #3e6fd9;
}

.subtraction-text {
    margin-top: 20px;
    margin-bottom: auto;
    width: 100%;
    height: 24px;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 28px;
    line-height: 22px;
    align-self: center;
    max-width: 900px;
}

.subtraction-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.subtraction-dialog-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.bgc-white {
    background-color: white !important;
}

.contradictions-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
    min-height: 500px;
}

.contradictions-title {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    white-space: nowrap;
    margin-right: auto;
}

.contradiction-matrix-table {
    margin-top: 20px;
    max-width: 1200px;
    width: calc(100% - 100px);
    min-height: 600px;

    th {
        border: 1px black solid;
    }

    td {
        border: 1px #949494 solid;
    }
}

.no-border-th {
    border: none !important;
}

.contradiction_name_table_cell {
    font-weight: 700;
    font-size: 24px;
    text-align: start;
}

.contradiction_requirements_table_cell {
    font-weight: 400;
    font-size: 16px;
    text-align: start;
}

.contradiction_idea_btn {
    outline: none;
    border:none;
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
}

.contradiction_table_idea_cell {
    position: relative;
}

.contradiction_table_dropdown__additional_style_container {
    position: relative;
    top: -20px;
}

.contradiction_table___tooltip_container {
    z-index: 2!important;
}

.contradiction_table_dropdown__additional_style_container_upper_cell {
    top: -170px;
    .cell-dropdown::after {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        top: 100%;
    };
    .cell-dropdown::before {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        top: 100%;
    }
}

.contradiction_table_dropdown {
    background-color: red;
}

.contradiction_idea_btn_not_finished {
    background-color: rgb(255, 244, 222)
}

.button-ai {
    height: 60px;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 7px 7px 7px 7px;
    font-weight: 500;
}

.ai_button_container {
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    grid-gap: 24px;
    gap: 24px;
}


.add_contradiction_button {
    background-color: #d4def2;
    outline: none;
    border: none;
    color: #3e6fd9;
    border-radius: 16px;
    margin-top: 20px;
    padding: 16px;

    &:hover {
        color: white;
        background-color: #3e6fd9;
    }
}

.editable_content {
    min-width: 100px;
    display: inline-block;
}
.subtraction-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.subtraction-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.multiplication-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.multiplication-tab-button div {
    height: 100%;
}

.subtraction-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.multiplication-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
    cursor: pointer;
}

.completed-idea-button div {
    height: 25%;
}

.multiplication-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 178px;
    min-width: 300px;
}

.multiplication-tabs-line {
    min-height: 3px;
    height: 3px;
    min-width: 300px;
    width: 178px;
    background-color: transparent;
}

.multiplication-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.multiplication-tabs-line {
    background-color: transparent;
    transition: background-color 0.5s;
}

.multiplication-tabs-line.active {
    background-color: #3e6fd9;
    min-width: 300px;
    transition: background-color 0.5s;
}

.subtraction-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.subtraction-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.subtraction-tab {
    min-width: 235px;
    max-width: 20%;
}

.tab-label-optional {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.multiplication-description {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #242C5C;
}

.multiplication-description u {
    margin: 0 10px 0 10px;
}

.multiplication-dialog-header-image {
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    width: 98%;
    margin: 0 10px 0 10px;
    color: #242C5C;
}

.multiplication-dialog-header-image u {
    margin: 0 10px 0 10px;
}
.multiplication-modal-label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.multiplication-modal-label span {
    height: 33px;
}

.multiplication-modal__button-ai {
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-weight: 500;
}

.multiplication-modal-label span u {
    max-width: 300px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.multiplication-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.multiplication-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.multiplication-modal__upload-image-button_disabled,
.multiplication-modal__upload-image-button_disabled:hover,
.multiplication-modal__button-ai_image-disabled,
.multiplication-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}

.multiplication-modal__upload-image-button {
    position: relative;
    top: 17px;
    height: 55px;
    right: 8px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
}

.carousel-container__image-container{
    margin-right: 10px;
}

.describe-new-product-page-one {
    width: 99%;
    margin-bottom: 20px;
    font-size: 26px;
}

.subtraction-modal-input {
    margin-bottom: 15px;
}

.multiplication-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 37px;
}

.multiplication-modal__add-issue-icon,
.multiplication-modal__add-benefit-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 12px;
}

.multiplication-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.benefit-input {
    margin-bottom: 3px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.benefit-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.benefit-input-container button {
    cursor: pointer;
}

.mt--8 {
    margin-top: -8px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.issues-input-container textarea {
    width: 100%;
    border: 2px solid #eceef5;
    color: #3e6fd9;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 16px 14px 16px;
    max-height: 95px;
}

.issues-input-container textarea::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.multiplication-modal-benefit {
    margin-bottom: 3px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.multiplication-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.multiplication-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.multiplication-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.multiplication-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.multiplication-modal__button-ai_name-idea,
.multiplication-modal__button-ai_image {
    position: relative;
    font-size: 14px;
    width: 50%;
}

.multiplication-modal__button-ai_name-idea {
    left: 190px;
    top: 31px;
}

.multiplication-modal__button-ai_image{
    left: 94px;
    top: 28px;
}

.multiplication-modal__button-ai_image p {
    margin: 0;
}

.multiplication-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 15px;
}

.multiplication-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.multiplication-modal-container__name,
.multiplication-modal-container__describe {
    width: 380px;
}

.multiplication-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.multiplication-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.multiplication-modal-challenges__title.textarea,
.multiplication-modal-benefit__title.textarea,
.multiplication-modal-challenges-input__empty_title {
    font-size: 18px;
}

.multiplication-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.multiplication-modal-challenges-input,
.multiplication-modal-benefit-input {
    margin-top: -8px;
}

.multiplication-modal-challenges-input__empty,
.multiplication-modal-benefit-input__empty {
    background-color: white;
}

.multiplication-modal-benefit-line {
    border-top: 2px solid #c3cde2;;
    margin-top: 20px
}

.multiplication-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.multiplication-modal-challenges__initials {
    cursor: pointer; 
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.multiplication-modal__close-issue-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 32px;
}

.multiplication-modal__close-solve-icon,
.multiplication-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.multiplication-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.multiplication-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}
.subtraction-dialog-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.multiplication-dialog-header {
    display: flex;
    width: 100%;
    height: 257px;
    background-color: #eceef5;
    position: relative;
}

.subtraction-dialog-header-icon {
    margin-right: 10px;
    height: 26px;
}

.multiplication-dialog-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
    margin-top: 20px;
}

.subtraction-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
}

.subtraction-dialog-body {
    padding: 40px 140px 0;
    width: 100%;
}

.subtraction-dialog-body-eureka {
    padding: 30px 140px 0;
    width: 100%;
}

.subtraction-dialog-body.benefits-container {
    padding: 40px 0 0;
}

.multiplication-dialog-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.multiplication-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.multiplication-dialog-button.disabled {
    background-color: #888da8;
    color: white;
}

.multiplication-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.multiplication-dialog-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 18px;
}

.multiplication-dialog-button.control {
    width: 131px;
}

.multiplication-dialog-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.multiplication-dialog-button.outlined span {
    color: #3e6fd9;
}

.subtraction-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.subtraction-dialog-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.bgc-white {
    background-color: white !important;
}

.components-dashboard-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
}

.search-input-container-subtraction-components {
    max-width: 326px;
}

.components-dashboard-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
    margin-left: 10px;
}

.add-component-button-round {
    margin: 0 20px;
    color: #3e6fd9;
}

.add-component-button-round:hover {
    color: #3061ae;
}

.components-counter-container {
    height: 45px;
    padding-bottom: 10px;
}

.components-tooltip {
    margin-right: auto;
}

.components-cards-wrapper {
    max-height: 100%;
}

.add-component-button {
    width: 346px;
    height: 49px;
    background-color: #d4def2;
    padding: 0 17px 0 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    margin-bottom: 10px;
}

.add-component-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.add-component-button svg {
    width: 13px;
    height: 13px;
}

.add-component-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.subtraction-component-card {
    width: 398px;
    min-width: 264px;
    height: 754px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    position: relative;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
}

.subtraction-component-card:hover {
    border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
    width: 100%;
    background-color: #eceef5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    border-radius: 4px;
    height: 30px;
}

.subtraction-component-card-header .link-icon {
    margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
    margin-right: 8px;
}

.cant-create-idea {
    text-align: center;
    color: #8a93c3;
    font-family: 'Inter';
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subtraction-component-card-title {
    line-height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    letter-spacing: -0.36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-height: 23px;
    margin-right: 3px;
}

.subtract-component-card-menu-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 20px;
    outline: none !important;
}

.component-type {
    font-family: 'Inter';
    width: 59px;
    height: 22px;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.component-type span {
    height: 18px;
    color: #3e6fd9;
    font-size: 14px;
    font-weight: 500;
}

.component-type.external {
    background-color: #fdeeee;
}

.component-type.external span {
    color: #f1666a;
}

.component-card-body {
    padding: 0 15px;
}

.subtract-component-card {
    border-radius: 4px;
    width: 346px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
}

.subtract-component-card.disabled {
    background-color: #d6dbed;
}

.subtract-component-card.disabled {
    background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card.disabled {
    background-color: #d6dbed;
}

.dropdown-last-idea {
    margin-bottom: 5px;
}

.subtract-component-card:hover {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.component-card-container {
    width: 100%;
    height: 600px;
    margin-bottom: 10px;
}

.subtract-component-card-container {
    width: 100%;
    height: 570px;
    margin-bottom: 10px;
}

.subtract-component-card-container.compact {
    height: 196px;
    
}

.multiplication-component-card-title {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 5px;
    width: 100%;
}

.multiplication-header-container-tutorial, 
.multiplication-header-container-settings {
    margin: 10px 0 10px 0;
}

.multiplication-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.subtract-component-card.compact {
    height: 49px;
    min-height: 49px;
    margin-bottom: 7px;
    position: relative;
}

.subtract-component-card-header-compact {
    padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
    display: flex;
    flex-direction: row;
}

.subtract-component-card-menu-button.compact {
    width: 22px;
}

.component-card.compact {
    height: 370px;
}

.subtraction-component-type-container {
    margin-top: 16px;
}

.subtraction-component-card-header.compact {
    margin: 0;
}

.subtract-component-card-title-container {
    max-width: 100%;
}

.subtract-component-card-title-container span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    word-break: break-word;
    max-height: 40px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    max-width: 269px;
}

.subtract-component-card-title-container.compact span {
    line-height: 20px;
    max-height: 36px;
    padding-top: 2px;
}

.component-card img {
    pointer-events: none;
}

.subtraction-component-card-header-text {
    width: 100%;
    height: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.component-droppable-container {
    min-height: calc(100% - 10px);
}

.component-placeholder {
    padding-top: 30px;
}

.component-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.subtraction-component-card-header-top {
    width: 363px;
    min-width: 363px;
    justify-content: space-between;
    height: 32px;
}

.row-dropdown-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .row-dropdown-container {
    opacity: 1;
}

.open-details-button-subtraction {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button-subtraction span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 16px;
}

.open-details-button-subtraction svg {
    color: #3e6fd9;
}

.open-details-button-subtraction:hover {
    background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
    color: white;
}

.open-details-button-subtraction:hover svg {
    color: white;
}
.open-details-button-subtraction .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button-subtraction.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.active-component {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
}

.top-2 {
    position: relative !important;
    top: 2px;
}

.component-multiplication-dialog-wrapper {
    width: 600px;
    height: 540px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.component-multiplication-dialog-header {
    width: 100%;
    height: 130px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 4px 4px 0 0;
}

.component-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.component-multiply-dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 165px);
}

.component-multiplication-dialog-scrollbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 190px;
}

.component-dialog-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
    align-self: flex-start;
}

.component-dialog-importance-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
}

.component-dialog-label.active {
    color: #3e6fd9;
}

.component-replace-dialog-buttons {
    display: flex;
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.component-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.component-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.component-dialog-input-container {
    justify-content: space-between;
}

.location-toggle {
    margin: 0 20px 0 10px;
}

.component-importance {
    height: 36px;
}

.component-to-multiply-in-title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 475px;
    margin-left: 15px;
    font-size: 18px;
    cursor: default;
}

.component-to-multiply-in-title u {
    margin: 0 5px;
    line-height: 20px;
    margin-top: 4px;
    height: 20px;
    max-width: 216px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-disabled {
    pointer-events: none;
    background-color: rgb(141, 141, 141);
}

.button-not-allowed {
    cursor: not-allowed;
}

.component-to-replace-in-button {
    max-width: 420px;
    font-size: 15.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attributes-multiply-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 280px;
    background-color: rgb(243, 243, 243);
    border-radius: 5px;
    background-color: #eceef5;
    box-shadow: 0 1px 1px #c6cbed;
    padding-top: 4px;
}

.attributes-list-element {
    text-align: left;
    background-color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    min-height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.attributes-list-element.active-attribute {
    padding: 4px 14px;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    box-shadow: 0 3px 6px rgb(36 44 92 / 40%);
    border: 1px solid #3e6fd9;
}

.attributes-list-element:hover {
    padding: 3px 13px;
    border: 2px solid #3e6fd9;
}

.create-new-multiplication-attribute {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 17px;
    width: 90% !important;
    height: 80px !important;
}

.keepAttributes {
    background-color: rgb(209, 206, 206);
}

.component-to-multiply-in-title_tooltip {
    color: #212529;
    font-size: 18px;
    white-space: nowrap;
    margin-bottom: 0;
}
.attribute-dialog-wrapper {
    width: 622px;
    height: 497px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.attribute-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 4px 4px 0 0;
}

.attribute-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.attribute-dialog-title-text {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
    height: 38px;
    margin-left: 10px;
}

.attribute-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 165px);
}

.attribute-dialog-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
    align-self: flex-start;
}

.attribute-dialog-importance-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
}

.attribute-importance {
    height: 36px;
}

.attribute-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.attribute-dialog-button {
    border: none;
    width: 105px;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.attribute-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.attribute-dialog-input-container {
    justify-content: space-between;
}

.attribute-location-toggle {
    margin: 0 20px 0 10px;
}

.draggable-handle {
    cursor: grab;
}

.attribute-dialog-tooltip {
    margin-left: 10px;
    margin-top: 5px;
}

.add-attribute-component-name {
    max-width: 300px;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.subtraction-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.subtraction-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.replacement-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.replacement-tab-button div {
    height: 100%;
}

.subtraction-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.replacement-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
    cursor: pointer;
}

.completed-idea-button div {
    height: 25%;
}

.replacement-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 178px;
    min-width: 300px;
}

.replacement-tabs-line {
    min-height: 3px;
    height: 3px;
    min-width: 300px;
    width: 178px;
    background-color: transparent;
}

.replacement-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.replacement-tabs-line {
    background-color: transparent;
    transition: background-color 0.5s;
}

.replacement-tabs-line.active {
    min-width: 300px;
    background-color: #3e6fd9;
    transition: background-color 0.5s;
}

.subtraction-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.subtraction-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.subtraction-tab {
    min-width: 235px;
    max-width: 20%;
}

.tab-label-optional {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.replacement-description {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #242C5C;
}

.replacement-description u {
    margin: 0 10px 0 10px;
}

.replacement-dialog-header-imagine {
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    width: 98%;
    margin: 0 10px 0 10px;
    color: #242C5C;
}

.replacement-dialog-header-imagine u {
    margin: 0 10px 0 10px;
}
.replacement-modal-label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.replacement-modal-label span {
    height: 33px;
}

.replacement-modal-label span u {
    word-wrap: break-word;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.carousel-container__image-container{
    margin-right: 10px;
}

.describe-new-product-page-one {
    width: 99%;
    margin-bottom: 20px;
    font-size: 26px;
}

.subtraction-modal-input {
    margin-bottom: 15px;
}

.replacement-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 37px;
}

.replacement-modal__add-issue-icon,
.replacement-modal__add-benefit-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 20px;
}

.replacement-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.replacement-benefit-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.benefit-input-container button {
    cursor: pointer;
}

.mt--8 {
    margin-top: -8px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.issues-input-container textarea {
    width: 100%;
    border: 2px solid #eceef5;
    color: #3e6fd9;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 16px 14px 16px;
    max-height: 95px;
}

.issues-input-container textarea::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 140px;
}

.replacement-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.replacement-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.replacement-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.replacement-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-weight: 500;
}

.replacement-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.replacement-modal__button-ai_name-idea,
.replacement-modal__button-ai_image {
    position: relative;
    font-size: 14px;
    width: 50%;
}

.replacement-modal__button-ai_name-idea {
    left: 190px;
    top: 31px;
}

.replacement-modal__button-ai_image {
    left: 94px;
    top: 29px;
}

.replacement-modal__button-ai_image p {
    margin: 0;
}

.replacement-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.replacement-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 15px;
}

.replacement-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.replacement-modal-container__name,
.replacement-modal-container__describe {
    width: 380px;
}

.replacement-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.replacement-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.replacement-modal-challenges__title.textarea,
.replacement-modal-benefit__title.textarea,
.replacement-modal-challenges-input__empty_title {
    font-size: 18px;
}

.replacement-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.replacement-modal-challenges-input,
.replacement-modal-benefit-input {
    margin-top: -8px;
}

.replacement-modal-challenges-input__empty,
.replacement-modal-benefit-input__empty {
    background-color: white;
}

.replacement-modal-benefit-line {
    border-top: 2px solid #c3cde2;;
    margin-top: 20px
}

.replacement-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.replacement-modal-benefit {
    margin-bottom: 3px;
}

.replacement-modal-challenges__initials {
    cursor: pointer; 
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.replacement-modal__close-issue-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 32px;
}

.replacement-modal__close-solve-icon,
.replacement-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.replacement-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.replacement-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}

.replacement-modal__upload-image-button {
    position: relative;
    top: 42px;
    height: 55px;
    right: 8px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
}

.replacement-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.replacement-modal__upload-image-button_disabled,
.replacement-modal__upload-image-button_disabled:hover,
.replacement-modal__button-ai_image-disabled,
.replacement-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}

.subtraction-dialog-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.replacement-dialog-header {
    width: 100%;
    height: 257px;
    background-color: #eceef5;
    position: relative;
}

.subtraction-dialog-header-icon {
    margin-right: 10px;
    height: 26px;
}

.subtraction-dialog-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
}

.replacement-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.subtraction-dialog-body {
    padding: 40px 140px 0;
    width: 100%;
}

.subtraction-dialog-body.benefits-container {
    padding: 40px 0 0;
}

.replacement-dialog-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.replacement-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.replacement-dialog-button.disabled {
    background-color: #888da8;
    color: white;
}

.replacement-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.replacement-dialog-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 18px;
}

.replacement-dialog-button.control {
    width: 131px;
}

.replacement-dialog-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.replacement-dialog-button.outlined span {
    color: #3e6fd9;
}

.subtraction-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.subtraction-dialog-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.bgc-white {
    background-color: white !important;
}

.components-dashboard-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
}

.search-input-container-subtraction-components {
    max-width: 326px;
}

.components-dashboard-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
    margin-left: 10px;
}

.add-component-button-round {
    margin: 0 20px;
    color: #3e6fd9;
}

.add-component-button-round:hover {
    color: #3061ae;
}

.components-counter-container {
    height: 45px;
    padding-bottom: 10px;
}

.components-tooltip {
    margin-right: auto;
}

.components-cards-wrapper {
    max-height: 100%;
}

.add-component-button {
    width: 346px;
    height: 49px;
    background-color: #d4def2;
    padding: 0 17px 0 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    margin-bottom: 10px;
}

.add-component-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.add-component-button svg {
    width: 13px;
    height: 13px;
}

.add-component-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.subtraction-component-card {
    width: 398px;
    min-width: 264px;
    height: 754px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    position: relative;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
}

.subtraction-component-card:hover {
    border: 2px solid #3e6fd9;
}

.subtraction-component-card-header {
    width: 100%;
    background-color: #eceef5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    border-radius: 4px;
    height: 30px;
}

.subtraction-component-card-header .link-icon {
    margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
    margin-right: 8px;
}

.cant-create-idea {
    text-align: center;
    color: #8a93c3;
    font-family: 'Inter';
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subtraction-component-card-title {
    line-height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    letter-spacing: -0.36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-height: 23px;
    margin-right: 3px;
}

.subtract-component-card-menu-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 20px;
    outline: none !important;
}

.component-type {
    font-family: 'Inter';
    width: 59px;
    height: 22px;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.component-type span {
    height: 18px;
    color: #3e6fd9;
    font-size: 14px;
    font-weight: 500;
}

.component-type.external {
    background-color: #fdeeee;
}

.component-type.external span {
    color: #f1666a;
}

.component-card-body {
    padding: 0 15px;
}

.subtract-component-card {
    border-radius: 4px;
    width: 346px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
}

.subtract-component-card.disabled {
    background-color: #d6dbed;
}

.subtract-component-card.disabled {
    background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card-header.disabled {
    background-color: #d6dbed;
}

.subtraction-component-card.disabled {
    background-color: #d6dbed;
}

.dropdown-last-idea {
    margin-bottom: 5px;
}

.subtract-component-card:hover {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.component-card-container {
    width: 100%;
    height: 600px;
    margin-bottom: 10px;
}

.subtract-component-card-container {
    width: 100%;
    height: 570px;
    margin-bottom: 10px;
}

.subtract-component-card-container.compact {
    height: 196px;
}

.replacement-component-card-title {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 5px;
    width: 100%;
}

.replacement-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.replacement-header-container-tutorial,
.replacement-header-container-settings {
    margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
    height: 49px;
    min-height: 49px;
    margin-bottom: 7px;
    position: relative;
}

.subtract-component-card-header-compact {
    padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
    display: flex;
    flex-direction: row;
}

.subtract-component-card-menu-button.compact {
    width: 22px;
}

.component-card.compact {
    height: 370px;
}

.subtraction-component-type-container {
    margin-top: 16px;
}

.subtraction-component-card-header.compact {
    margin: 0;
}

.subtract-component-card-title-container {
    max-width: 100%;
}

.subtract-component-card-title-container span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    word-break: break-word;
    max-height: 40px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    max-width: 217px;
}

.subtract-component-card-title-container.compact span {
    line-height: 20px;
    max-height: 36px;
    padding-top: 2px;
}

.component-card img {
    pointer-events: none;
}

.subtraction-component-card-header-text {
    width: 100%;
    height: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.component-droppable-container {
    min-height: calc(100% - 10px);
}

.component-placeholder {
    padding-top: 30px;
}

.component-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.subtraction-component-card-header-top {
    width: 363px;
    min-width: 363px;
    justify-content: space-between;
    height: 32px;
}

.row-dropdown-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .row-dropdown-container {
    opacity: 1;
}

.open-details-button-subtraction {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button-subtraction span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 16px;
}

.open-details-button-subtraction svg {
    color: #3e6fd9;
}

.open-details-button-subtraction:hover {
    background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
    color: white;
}

.open-details-button-subtraction:hover svg {
    color: white;
}
.open-details-button-subtraction .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button-subtraction.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.active-component {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
}

.top-2 {
    position: relative !important;
    top: 2px;
}

.green-ideas-count {
    height: 100%;
    font-size: 21px;
    font-family: 'Inter';
    color: #242c5c;
    line-height: 35px;
    margin-right: 10px;
}

.component-dialog-wrapper {
    width: 1000px;
    height: 537px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.component-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 4px 4px 0 0;
}

.component-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.component-replace-dialog-body {
    display: flex;
    padding: 26px 70px 80px;
    width: 100%;
    height: calc(100% - 165px);
}

.component-header-replacement-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.component-header-replacement-label {
    display: flex;
    justify-content: space-between;
    height: 70px;
}

.component-replace-dialog-scrollbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 235px;
}

.component-dialog-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
    align-self: flex-start;
}

.component-dialog-importance-label {
    color: #465664;
    font-size: 16px;
    line-height: 24px;
}

.component-dialog-label.active {
    color: #3e6fd9;
}

.component-replace-dialog-buttons {
    display: flex;
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.component-dialog-button {
    border: none;
    width: 105px;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.component-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.component-dialog-input-container {
    justify-content: space-between;
}

.location-toggle {
    margin: 0 20px 0 10px;
}

.component-importance {
    height: 36px;
}

.component-to-replace-in-title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 15px;
    font-size: 26px;
    cursor: default;
    margin-bottom: 30px;
    color: #242c5c;
}

.component-to-replace-in-title u {
    margin: 0 5px;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-disabled {
    pointer-events: none;
    background-color: rgb(141, 141, 141);
}

.button-not-allowed {
    cursor: not-allowed;
}

.component-to-replace-in-button {
    max-width: 420px;
    font-size: 15.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.internal-components-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 235px;
    height: 270px;
    background-color: rgb(243, 243, 243);
    border-radius: 5px;
    background-color: #eceef5;
    box-shadow: 0 1px 1px #c6cbed;
}

.external-components-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 235px;
    height: 270px;
    background-color: rgb(243, 243, 243);
    margin-left: 20px;
    border-radius: 5px;
    background-color: #eceef5;
    box-shadow: 0 1px 1px #c6cbed;
}

.components-list-element {
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 1;
    margin: 0 5px;
    width: 200px;
    height: 50px;
    max-height: 50px;
    max-width: 197px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    padding: 1px;
    padding-right: 5px;
    box-shadow: 0 1px 1px #c6cbed;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 15px;
    align-items: center;
}

.components-list-element.active-component {
    -webkit-transform: none;
            transform: none;
    padding-left: 14px;
    padding-top: 9px;
    border: 2px solid #3e6fd9;
}

.components-list-element:hover {
    padding-left: 14px;
    padding-top: 9px;
    border: 2px solid #3e6fd9;
}

.create-new-replacement-component {
    align-items: center;
    background-color: #3e6fd9;
    margin-left: 10px;
    color: white;
    width: 260px;
    min-width: 260px;
    height: 44px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    outline: none !important;
    justify-content: space-between;
}

.select-replacement-component {
    margin-top: 30px;
    align-items: center;
    font-size: 18px;
    background-color: #3e6fd9;
    color: white;
    width: 420px;
    height: 44px;
    padding: 5px 10px 5px 20px;
    border: none;
    border-radius: 4px;
    outline: none !important;
    justify-content: space-between;
}

.replace-component-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 49%;
    align-content: flex-start;
    height: 100%;
}

.replace-component-items_component {
    width: 100%;
    margin-left: 5px;
    margin-bottom: 10px;
}
.replace-component-items:first-child {
    border-right: 1px solid #c5c5de;
}

.custom-replace-label {
    height: 33px;
    color: #242c5c;
    font-size: 26px;
    margin-bottom: 5px;
    text-align: start;
}

.replace-components-container {
    height: 79%;
    margin-top: 30px;
    text-align: start;
}

.replace-scrollbar-host {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    width: 100%;
    height: 90%;
}

.component-to-replace-in-title_tooltip {
    color: #212529;
    font-size: 26px;
    white-space: nowrap;
    margin-bottom: 0;
}

.replace-component-items_tooltip {
    color: #212529;
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 0;
}
.subtraction-tabs-circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 3px solid transparent;
    background-color: #eceef5;
    border-radius: 20px;
    position: relative;
    transition: border-color 0.5s;
}

.subtraction-tabs-circle.active {
    border-color: #3e6fd9;
    transition: border-color 0.5s;
}

.eureka-tab-button span {
    height: 22px;
    color: #888da8;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.5s;
}

.eureka-tab-button div {
    height: 100%;
}

.subtraction-tab-label.active {
    color: #3e6fd9;
    transition: color 0.5s;
}

.eureka-tab-button {
    border: none;
    background-color: transparent;
    height: 36px;
    line-height: 1.1;
    padding: 0 10px;
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    outline: none !important;
    cursor: pointer;
}

.completed-idea-button div {
    height: 25%;
}

.eureka-tabs-line-container {
    min-height: 3px;
    height: 3px;
    width: 210px;
    min-width: 300px;
}

.eureka-tabs-line {
    min-height: 3px;
    height: 3px;
    width: 300px;
    background-color: transparent;
}

.eureka-tabs-container {
    position: absolute;
    bottom: -12px;
    left: -170px;
    width: 96%;
    justify-content: space-evenly;
}

.eureka-tabs-line {
    background-color: transparent;
    transition: background-color 0.5s;
}

.eureka-tabs-line.active {
    background-color: #3e6fd9;
    min-width: 300px;
    transition: background-color 0.5s;
}

.subtraction-tabs-circle svg {
    width: 29px;
    min-width: 29px;
    height: 29px;
    min-height: 29px;
    position: absolute;
    left: -5px;
    top: -5px;
    opacity: 0;
    transition: opacity 0.5s;
}

.subtraction-tabs-circle svg.completed {
    opacity: 1;
    transition: opacity 0.5s;
}

.eureka-tab {
    max-width: 25%;
}

.tab-label-optional {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.subtraction-description {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.eureka-modal-title {
    margin-bottom: auto;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 24px;
    align-self: center;
    max-width: 900px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.eureka-modal-title u {
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px;
    margin: 0 5px;
}

.eureka-modal-label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 10px;
}

.eureka-modal-label span {
    height: 33px;
}

.eureka-modal-label span u {
    max-width: 300px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: -2px;
}

.carousel-container__image-container{
    margin-right: 10px;
}

.describe-new-product-page-one {
    width: 99%;
    margin-bottom: 20px;
    font-size: 26px;
}

.subtraction-modal-input {
    margin-bottom: 15px;
}

.eureka-modal__close-benefit-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 37px;
}

.eureka-modal__add-issue-icon,
.eureka-modal__add-benefit-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
    position: relative;
    bottom: 12px;
}

.eureka-modal__benefit-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.benefit-input {
    margin-bottom: 3px;
}

.benefits-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    align-self: flex-start;
}

.eureka-modal__benefit-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

.eureka-modal__benefit-input-container button {
    cursor: pointer;
}

.mt--8 {
    margin-top: -8px;
}

.not-feasible {
    width: 1000px;
    height: 53px;
    background-color: rgba(241, 102, 106, 0.15);
    position: absolute;
    bottom: 110px;
    left: 30px;
    border-radius: 4px;
}

.not-feasible span {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.issues-input-container {
    width: 100%;
}

.issues-input-container textarea {
    width: 100%;
    border: 2px solid #eceef5;
    color: #3e6fd9;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 16px 14px 16px;
    max-height: 95px;
}

.issues-input-container textarea::-webkit-input-placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.issues-input-container textarea::placeholder {
    color: rgba(36, 44, 92, 0.5);
}

.dependency-modal-input {
    margin-bottom: 8px;
}

.eureka-modal-benefit {
    margin-bottom: 3px;
}

.initials-container {
    position: relative;
    top: -4.5px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    cursor: pointer;
}

.eureka-modal__issue-input-container {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

.eureka-modal__issue-button {
    border: none;
    background: transparent;
    height: 26px;
    width: 27px;
    margin-left: 10px;
}

.eureka-modal__comment-solve-container {
    position: relative;
    top: -25px;
    display: inline;
    margin-left: 58px;
}

.eureka-modal__comment-solve-container span {
    color: #75798d;
    cursor: pointer;
    font-size: 14px;
}

.thread-container {
    width: calc(100% - 42px);
    margin-left: 42px;
    margin-bottom: 7px;
}

.width-90 {
    width: 90%;
}

.semitransparent {
    opacity: 0.5;
}

.eureka-modal__button-ai {
    height: 10%;
    background-color: #f9e9ec;
    color: #cc4b4f;
    border: none;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-weight: 500;
}

.eureka-modal__button-ai:hover {
    background-color: #cc4b4f;
    color: #fff;
}

.eureka-modal__button-ai_challenges {
    width: 230px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.eureka-modal__button-ai_name-idea,
.eureka-modal__button-ai_image {
    position: relative;
    font-size: 14px;
    width: 50%;
}

.eureka-modal__button-ai_name-idea {
    left: 190px;
    top: 31px;
}

.eureka-modal__button-ai_image {
    left: 94px;
    top: 28px;
}

.eureka-modal__button-ai_image p {
    margin: 0;
}

.eureka-modal__button-ai_disabled,
.eureka-modal__button-ai_disabled:hover {
    color: #fff;
    background-color: #888da8;
}

.eureka-modal-container__label {
    width: 50%;
    text-align: left;
    margin-bottom: 15px;
}

.eureka-modal-container {
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5px;
}

.eureka-modal-container__name,
.eureka-modal-container__describe {
    width: 380px;
}

.eureka-modal-container__describe {
    margin-top: 15px;
    margin-right: 5px;
}

.eureka-modal-benefit__initials {
    position: relative;
    bottom: 53px;
    right: 5px;
    margin-left: 5px;
}

.eureka-modal-challenges__title.textarea,
.eureka-modal-benefit__title.textarea,
.eureka-modal-challenges-input__empty_title {
    font-size: 18px;
}

.eureka-modal-challenges-input__empty_title {
    padding: 7px 16px;
}

.eureka-modal-challenges-input,
.eureka-modal-benefit-input {
    margin-top: -8px;
}

.eureka-modal-challenges-input__empty,
.eureka-modal-benefit-input__empty {
    background-color: white;
}

.eureka-modal-benefit-line {
    border-top: 2px solid #c3cde2;;
    margin-top: 20px
}

.eureka-modal__button-ai_benefits {
    width: 208px;
    margin: 0 0 15px 25px;
    font-size: 14px;
}

.eureka-modal-challenges__initials {
    cursor: pointer; 
    position: relative;
    bottom: 42px;
    right: 5px;
    margin-left: 5px;
}

.eureka-modal__close-issue-icon {
    width: 12px;
    height: 12px;
    position: relative;
    bottom: 32px;
}

.eureka-modal__close-solve-icon,
.eureka-modal__add-solve-icon {
    position: relative;
    bottom: 5px;
}

.eureka-modal__close-solve-icon {
    width: 12px;
    height: 12px;
}

.eureka-modal__add-solve-icon {
    color: #888da8;
    height: 14px;
    width: 14px;
}

.eureka-modal__upload-image-button {
    position: relative;
    top: 17px;
    right: 8px;
    height: 55px;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
}

.eureka-modal__upload-image-button:hover{
    background-color: #cc4b4f
}

.eureka-modal__upload-image-button_disabled,
.eureka-modal__upload-image-button_disabled:hover,
.eureka-modal__button-ai_image-disabled,
.eureka-modal__button-ai_image-disabled:hover
{
    background-color: #888da8;
    color:white
}
.subtraction-dialog-wrapper {
    width: 1061px;
    height: 792px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
    background: white;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 768px;
    min-height: 768px;
}

.subtraction-dialog-header {
    width: 100%;
    height: 257px;
    background-color: #eceef5;
    position: relative;
}

.subtraction-dialog-header-icon {
    margin-right: 10px;
    height: 26px;
}

.subtraction-dialog-title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-self: flex-start;
    padding: 0 28px;
}

.subtraction-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
}

.subtraction-dialog-body {
    padding: 40px 140px 0;
    width: 100%;
}

.subtraction-dialog-body.benefits-container {
    padding: 40px 0 0;
}

.eureka-dialog-footer {
    border-top: 2px solid #eceef5;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
}

.eureka-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.eureka-dialog-button.disabled {
    background-color: #888da8;
    color: white;
}

.eureka-dialog-button.secondary {
    background-color: transparent;
    color: #888da8;
    margin-right: 10px;
}

.eureka-dialog-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 18px;
}

.eureka-dialog-button.control {
    width: 131px;
}

.eureka-dialog-button.outlined {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.eureka-dialog-button.outlined span {
    color: #3e6fd9;
}

.subtraction-text {
    margin-top: 20px;
    margin-bottom: auto;
    width: 100%;
    height: 24px;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 28px;
    line-height: 22px;
    align-self: center;
    max-width: 900px;
}

.subtraction-container {
    height: 52px;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    background-color: white;
    border: 2px solid #c0c4de;
    border-radius: 4px;
    z-index: 100;
}

.subtraction-dialog-controls img {
    margin-left: 30px;
}

.feasible-idea {
    height: 110px;
    background-color: #eceef5;
    border-radius: 4px;
}

.feasible-idea.negative {
    background-color: rgba(241, 102, 106, 0.29);
}

.feasible-idea.positive {
    background-color: rgba(90, 197, 122, 0.2);
}

.feasible-idea-text {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
    margin-bottom: 10px;
}

.flex-1 {
    flex: 1 1;
}

.idea-rating-label {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 20px;
}

.idea-rating-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin-bottom: 40px;
}

.bgc-white {
    background-color: white !important;
}

.components-dashboard-wrapper {
    margin: 20px 40px 0;
    font-family: 'Inter';
}

.search-input-container-subtraction-components {
    max-width: 326px;
}

.components-dashboard-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
    margin-left: 10px;
}

.add-component-button-round {
    margin: 0 20px;
    color: #3e6fd9;
}

.add-component-button-round:hover {
    color: #3061ae;
}

.components-counter-container {
    height: 45px;
    padding-bottom: 10px;
}

.components-tooltip {
    margin-right: auto;
}

.components-cards-wrapper {
    max-height: 100%;
}

.add-component-button {
    width: 346px;
    height: 49px;
    background-color: #d4def2;
    padding: 0 17px 0 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    margin-bottom: 10px;
}

.add-component-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.add-component-button svg {
    width: 13px;
    height: 13px;
}

.add-component-button span {
    height: 24px;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
}

.eureka-component-card {
    width: 600px;
    min-width: 264px;
    height: 754px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    position: relative;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
}

.eureka-component-card:hover {
    border: 2px solid #3e6fd9;
}

.eureka-component-card-header {
    width: 100%;
    background-color: #eceef5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    border-radius: 4px;
    height: 30px;
}

.eureka-component-card-header .link-icon {
    margin-right: 8px;
}

.subtract-component-card-rating-menu-wrapper .link-icon {
    margin-right: 8px;
}

.cant-create-idea {
    text-align: center;
    color: #8a93c3;
    font-family: 'Inter';
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.eureka-component-card-title {
    line-height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
    letter-spacing: -0.36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-height: 23px;
    margin-right: 3px;
}

.subtract-component-card-menu-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 20px;
    outline: none !important;
}

.component-type {
    font-family: 'Inter';
    width: 59px;
    height: 22px;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.component-type span {
    height: 18px;
    color: #3e6fd9;
    font-size: 14px;
    font-weight: 500;
}

.component-type.external {
    background-color: #fdeeee;
}

.component-type.external span {
    color: #f1666a;
}

.component-card-body {
    padding: 0 15px;
}

.subtract-component-card {
    border-radius: 4px;
    width: 346px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
}

.subtract-component-card.disabled {
    background-color: #d6dbed;
}

.subtract-component-card.disabled {
    background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
    background-color: #d6dbed;
}

.eureka-component-card-header.disabled {
    background-color: #d6dbed;
}

.eureka-component-card.disabled {
    background-color: #d6dbed;
}

.dropdown-last-idea {
    margin-bottom: 5px;
}

.subtract-component-card:hover {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.component-card-container {
    width: 100%;
    height: 600px;
    margin-bottom: 10px;
}

.subtract-component-card-container {
    width: 100%;
    height: 570px;
    margin-bottom: 10px;
}

.subtract-component-card-container.compact {
    height: 196px;
}

.subtract-component-card-title {
    height: 25px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 5px;
    width: 100%;
}

.eureka-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.eureka-header-container-tutorial, 
.eureka-header-container-settings {
    margin: 10px 0 10px 0;
}

.subtract-component-card.compact {
    height: 49px;
    min-height: 49px;
    margin-bottom: 7px;
    position: relative;
}

.subtract-component-card-header-compact {
    padding: 0 10px;
}

.subtract-component-card-rating-menu-wrapper {
    display: flex;
    flex-direction: row;
}

.subtract-component-card-menu-button.compact {
    width: 22px;
}

.component-card.compact {
    height: 370px;
}

.subtraction-component-type-container {
    margin-top: 16px;
}

.eureka-component-card-header.compact {
    margin: 0;
}

.eureka-idea-card-title-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 20px;
}

.eureka-idea-card-title-container span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    word-break: break-word;
    max-height: 40px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    max-width: 269px;
}

.eureka-idea-card-title-container.compact span {
    line-height: 20px;
    max-height: 36px;
    padding-top: 2px;
}

.component-card img {
    pointer-events: none;
}

.eureka-component-card-header-text {
    width: 100%;
    height: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.eureka-ideas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100% - 20px);
    margin-top: 20px;
}

.component-placeholder {
    padding-top: 30px;
}

.component-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.eureka-component-card-header-top {
    width: 363px;
    min-width: 363px;
    justify-content: space-between;
    height: 32px;
}

.row-dropdown-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .row-dropdown-container {
    opacity: 1;
}

.open-details-button-subtraction {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button-subtraction span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 16px;
}

.open-details-button-subtraction svg {
    color: #3e6fd9;
}

.open-details-button-subtraction:hover {
    background-color: #3e6fd9;
}

.open-details-button-subtraction:hover span {
    color: white;
}

.open-details-button-subtraction:hover svg {
    color: white;
}
.open-details-button-subtraction .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button-subtraction:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button-subtraction.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button-subtraction.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.active-eureka-idea {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
}

.top-2 {
    position: relative !important;
    top: 2px;
}

.eureka-idea-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 550px;
    height: 51px;
    min-height: 51px;
    border-radius: 4px;
    background-color: rgba(90, 197, 122, 0.17);
    margin-bottom: 6px;
}

.eureka-idea-name {
    height: 50px;
    line-height: 48px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400px;
    margin-right: 10px;
    text-align: left;
}

.eureka-idea-score-container {
    display: flex;
    width: 35px;
    align-items: center;
    margin-right: 10px;
}

.eureka-idea-score {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #5ac57a;
}

.eureka-idea-score span {
    color: #ffffff;
    font-size: 21px;
}

.components-dashboard-wrapper {
    margin: 95px 40px 0;
    font-family: 'Inter';
}

.components-dashboard-title {
    min-height: 45px;
    max-height: 90px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 32px;
    margin-right: 10px;
    word-wrap: normal;
    text-align: left;
}

.search-input-container-components {
    max-width: 250px;
}

.components-dashboard-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
    margin-left: 10px;
}

.add-component-button-round {
    margin: 0 20px;
    color: #3e6fd9;
}

.add-component-button-round:hover {
    color: #3061ae;
}

.components-counter-container {
    height: 38px;
    margin-bottom: 10px;
}

.components-tooltip {
    margin-right: auto;
}

.components-cards-wrapper {
    max-height: 100%;
}

.component-card {
    width: 264px;
    min-width: 264px;
    height: 754px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 0 10px 10px 0;
    position: relative;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
}

.component-card:hover {
    border: 2px solid #3e6fd9;
}

.component-card-header {
    width: 100%;
    background-color: #eceef5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 13px 15px 0 15px;
    border-radius: 4px;
    height: 75px;
}

.component-card-header .component-icon {
    width: 18px;
    min-width: 18px;
    height: 32px;
    min-height: 32px;
    margin-right: 10px;
}

.component-icon-step {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.component-card-header .link-icon {
    margin-right: 8px;
}

.component-card-header span {
    padding-top: 4px;
}

.component-card-menu-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 20px;
    outline: none !important;
}

.importance-block {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: #f1666a;
    margin-right: 2px;
}

.component-card-initials {
    font-family: 'Inter';
    width: 30px;
    height: 22px;
    color: #f1666a;
    font-size: 14px;
    font-weight: 500;
    background-color: #fdeeee;
    border-radius: 3px;
    line-height: 26px;
}

.component-type {
    font-family: 'Inter';
    width: 59px;
    height: 22px;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.component-type span {
    height: 18px;
    color: #3e6fd9;
    font-size: 12px;
}

.component-type.external {
    background-color: #fdeeee;
}

.component-type.external span {
    color: #f1666a;
}

.component-card-body-dashboard {
    padding: 0 15px;
    height: 90%;
}

@media (min-height: 320px) {
    .component-card-body-dashboard {
        min-height: 50%;
        max-height: 50%;
        height: 50% !important;
    }
}

@media (min-height: 400px) {
    .component-card-body-dashboard {
        min-height: 60%;
        max-height: 60%;
        height: 60% !important;
    }
}

@media (min-height: 570px) {
    .component-card-body-dashboard {
        min-height: 70%;
        max-height: 70%;
        height: 70% !important;
    }
}

@media (min-height: 700px) {
    .component-card-body-dashboard {
        min-height: 81%;
        max-height: 81%;
        height: 81% !important;
    }
}

@media (min-height: 900px) {
    .component-card-body-dashboard {
        min-height: 90%;
        max-height: 90%;
        height: 90% !important;
    }
}
.attribute-card {
    border-radius: 4px;
    width: 229px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
}

.attribute-card.disabled {
    background-color: #d6dbed;
}

.attribute-card.disabled .component-card-initials {
    background-color: rgba(241, 102, 106, 0.11);
}

.component-card.disabled {
    background-color: #d6dbed;
}

.component-card-header.disabled {
    background-color: #d6dbed;
}

.attribute-card:hover {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.attribute-card-container {
    width: 100%;
    height: 570px;
    margin-bottom: 10px;
}

.attribute-card-title {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
}

.attribute-card-header {
    max-height: 42px;
    padding: 0 10px;
    margin-top: 6px;
    margin-bottom: 4px;
}

.attribute-card-header svg {
    width: 13px;
    height: 15px;
    min-width: 13px;
    min-height: 15px;
    margin-right: 10px;
    margin-bottom: 3px;
}

.attribute-card-description-container {
    padding: 0 15px;
    max-height: 28px;
    overflow: hidden;
}

.attribute-card-description {
    font-family: 'Inter';
    max-height: 38px;
    color: #888da8;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    word-break: break-word;
}

.attribute-card-top {
    max-height: 78px;
}

.attribute-card-bottom {
    padding: 0 15px 10px 15px;
    align-items: flex-end;
}

.menu-icon {
    width: 12px;
    min-width: 12px;
    height: 25px;
    min-height: 25px;
}

.add-attribute-button {
    width: 100px;
    height: 39px;
    background-color: #d4def2;
    padding: 0 10px 0 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    margin-bottom: 10px;
}

.add-attribute-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.add-attribute-button svg {
    width: 13px;
    height: 13px;
}

.add-attribute-button span {
    height: 20px;
    line-height: 20px;
    font-family: 'Inter';
    font-size: 14px;
    text-transform: uppercase;
}

.importance-block-compact {
    font-family: 'Inter';
    border-radius: 3px;
    width: 22px;
    height: 22px;
    background-color: #fde8e9;
}

.importance-block-compact span {
    height: 18px;
    color: #f1666a;
    font-size: 12px;
}

.active-card-element {
    padding: 0;
    border: 2px solid #3e6fd9;
}

.attribute-card.compact {
    height: 49px;
    min-height: 49px;
    margin-bottom: 7px;
}

.attribute-card-header-compact {
    padding: 0 10px;
}

.attribute-card-header-compact svg {
    width: 17px;
    height: 15px;

    min-width: 17px;
    min-height: 15px;
    margin-right: 5px;
}

.component-card-menu-button.compact {
    width: 22px;
}

.component-card.compact {
    height: 370px;
}

.attribute-card-container.compact {
    height: 196px;
}

.component-type-container {
    margin-bottom: 20px;
}

.component-type-container.compact {
    margin-bottom: 10px;
}

.component-card-header.compact {
    margin: 0;
}

.attribute-card-title-container {
    max-width: 100%;
}

.attribute-card-title-container span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    word-break: break-word;
    max-height: 40px;
    height: auto;
    text-align: left;
    padding-top: 6px;
}

.attribute-card-title-container.compact span {
    line-height: 18px;
    max-height: 36px;
    padding-top: 2px;
}

.attribute-card-title-container:not(.compact) svg {
    margin-top: 6px;
}

.component-card img {
    pointer-events: none;
}

.component-card-header-text {
    width: calc(100% - 20px);
}

.attribute-card-scroll-container {
    min-width: 5px;
    position: absolute;
    right: 5px;
}

.attribute-card-scroll-handle {
    position: relative;
    min-width: 5px;
    background-color: #d9dde5;
    border-radius: 6px;
}

.card-menu-buttons-container {
    height: 28px;
}

.toggle-container {
    margin: 0 20px 0 0;
    white-space: nowrap;
    height: 41px;
}

.sort-toggle {
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #465664;
}

.mt-10 {
    margin-top: 10px;
}

.component-droppable-container {
    min-height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sortable-hoc-drag-handle {
    cursor: grab;
}

.component-placeholder {
    padding-top: 30px;
}

.component-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}
.report-placeholder {
    padding-top: 30px;
}

.report-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.modal-video-button-exit {
    width: 22px;
    height: 30px;
    margin-right: 20px;
}

.modal-video-content {
    background-color: #eceef5;
    border-radius: 4px;
}

.header-modal-video {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 75px;
}

.modal-video-title {
    color: #242c5c;
    font-size: 26px;
    height: 33px;
    margin-left: 20px;
}

.components-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.add-attribute-button:disabled {
    opacity: 0.5;
}

.add-attribute-button.generate-attribute {
    display: flex;
    justify-content: center;
    background-color: #f9e9ec;
    color: #cc4b4f;
    padding-right: 10px;
    padding: 0px 10px;
}

.add-attribute-button.generate-component {
    background-color: #f9e9ec;
    color: #cc4b4f;
    margin-right: 10px;
    width: 124px;
    padding-left: 10px;
}

.add-attribute-button.generate-component:hover {
    color: white;
    background-color: #cc4b4f;
}

.add-attribute-button.add-new-attribute {
    background-color: #c2d4fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ebeef5;
    width: 50%;
}

.add-attribute-button.add-new-attribute:hover {
    border: 2px #3e6fd9 solid;
    color: #3e6fd9;
}

.generate-openai-count {
    height: 38px;
    color: #cc4b4f;
    font-size: 30px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.add-component-button-title {
    background-color: #c2d4fa;
    outline: none !important;
    color: #3e6fd9;
    margin-right: 10px;
    height: 38px;
    /* width: 100px; */
    width: 40%;
    padding-right: 40px;
    margin-left: 10px;
    padding-left: 11px;
    padding-right: 10px;
    border-radius: 3px;
    border: none;
}

.add-component-button-title:hover {
    border: 2px #3e6fd9 solid;
}

.add-text-attributes {
    font-size: 15px;
    text-align: start;
    color: #3e6fd9;
    margin-bottom: 4px;
}

.warning-delete-modal {
    height: 250px;
}

.warning-delete-modal span {
    height: 100%;
}

.delete-popup.warning-delete-modal button span {
    font-size: 18px;
}

.dashboard-header-vantage-point {
    width: 80%;
    font-size: 18px;
    margin-bottom: 16px;
}

.component-card-tooltip {
    color: #242c5c;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}

.components-dashboard-settings {
    margin: 10px 0 10px 0;
}

@media (min-height: 400px) {
    .component-card-body-dashboard {
        height: 60%;
    }
}

@media (min-height: 570px) {
    .component-card-body-dashboard {
        height: 70%;
    }
}

@media (min-height: 700px) {
    .component-card-body-dashboard {
        height: 81%;
    }
}

@media (min-height: 900px) {
    .component-card-body-dashboard {
        height: 90%;
    }
}

.suggested-attribute-scrollbar-host {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    width: 100%;
    height: 90%;
}

.suggested-attribute-modal-title-container {
    height: 100%;
    width: 90%;
    justify-content: flex-start;
    white-space: nowrap;
    align-items: center;
    text-align: left;
    letter-spacing: 1.4px;
}
.suggested-attribute-modal-title {
    color: #242c5c;
    overflow: hidden;
    font-weight: bold;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    width: 95%;
    top: 5px;
    margin-bottom: 10px;
}

.suggest-attribute-dialog-body {
    display: flex;
    flex-grow: 1;
    padding: 37px 32px 26px;
    width: 100%;
    height: 80%;
}

.generate-attribute-dialog-body {
    display: flex;
    flex-grow: 1;
    padding: 26px 32px;
    width: 100%;
    height: 77%;
}

.add-attribute-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 60px;
}

.generate-modal-title {
    background-color: #fce8e9;
}

.suggest-attribute-button.generage-button {
    width: 522px;
    height: 50px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    background-color: #3e6fd9;
    color: white;
}

.add-attribute-button.generate-attribute:hover {
    color: white;
    background-color: #cc4b4f;
}

.attribute-generate-dialog-header {
    width: 100%;
    height: 158px;
    background-color: #fde8e9;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
}

.attribute-project-type {
    display: flex;
    justify-content: space-between;
    width: 550px;
    height: 49px;
    border: #f4d4d6 2px solid;
    border-radius: 5px;
    margin-bottom: 26px;
    padding: 0 28px;
}

.attribute-project-type div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
}

.type-separator {
    display: flex;
    width: 22px;
    position: absolute;
    top: 83px;
    left: 310px;
}

.attribute-project-type span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 19px;
    width: 200px;
    max-width: 200px;
    font-weight: 500;
    line-height: 33px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
    margin-left: 10px;
}

.suggest-item-attributes {
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 1;
    margin: 0 5px;
    width: 47%;
    height: 50px;
    max-width: 193px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
    justify-content: flex-start;
    text-align: start;
    padding-left: 15px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.border-item.suggest-item-attributes,
.suggest-item-attributes:hover {
    border: 2px solid #f1666a;
    padding-top: 9px;
    padding-left: 14px;
    color: #f1666a;
}

.attribute-dialog-wrapper_select-all {
    border-radius: 5px;
    padding: 10px 0 7px 0;
    margin: 0 20px 20px 0;
    border: solid 1px  #3e6fd9;
    box-shadow: 0 1px 1px #3e6fd9;
}

.attribute-dialog-wrapper_select-all.disabled {
    background-color: #3e6fd9;
    opacity: 0.5;
    border: none;
    box-shadow: none;
}

.attribute-dialog-wrapper_checkbox {
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    padding: 0;
}

.attribute-dialog-wrapper_label {
    margin: 0 0 0 10px;
    color: #3e6fd9;
}

.attribute-dialog-wrapper_label.disabled {
    color: #ffffff;
}

.attribute-dialog-wrapper_tooltip {
    color: #212529;
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 0;
}
.suggested-container {
    width: 254px;
    min-width: 264px;
    height: 752px;
    box-shadow: 0 1px 1px #c6cbed;
    background-color: #eceef5;
    border-radius: 4px;
    margin: 10px 10px 10px 0;
    border: 2px solid transparent;
    -webkit-user-select: none;
            user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    transition: all 0.2s ease;
}
.suggested-container.compact {
    height: 772px;
    transition: all 0.1s linear;
}
.suggested-scroll-container {
    height: 170px;
    width: 100%;
}
.suggested-scroll-container.compact {
    height: 580px;
}
.suggested-scrollable-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.suggested-container:hover,
.suggest-item:hover {
    border: 2px solid #f1666a;
    padding-top: 9px;
    padding-left: 14px;
}

.suggest-attribute-button:hover svg {
    color: white;
}
.suggest-item {
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 1;
    margin: 0 5px;
    width: 47%;
    height: 50px;
    max-width: 216px;
    border: 1px solid #c6cbed;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 1px;
    box-shadow: 0 1px 1px #c6cbed;
    justify-content: flex-start;
    text-align: start;
    padding-left: 15px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.suggest-input-container {
    padding-inline: 8px;
    margin-top: 5px;
}
.suggest-input {
    border: 2px solid #cdd1e5;
    height: 40px;
}

.suggest-attribute-button {
    width: 224px;
    height: 39px;
    background-color: #d4def2;
    padding: 5px 10px 5px 20px;
    border: none;
    border-radius: 4px;
    color: #3e6fd9;
    outline: none !important;
    justify-content: space-between;
    margin-bottom: 10px;
}

.suggest-attribute-button:hover {
    color: white;
    background-color: #3e6fd9;
}

.suggest-attribute-button span {
    height: 20px;
    line-height: 20px;
    font-family: 'Inter';
    font-size: 14px;
    text-transform: uppercase;
}

.suggest-attribute-button:disabled {
    opacity: 0.5;
}

.suggest-input-text-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    align-self: center;
}

.suggest-component-tip {
    background-color: #3e6fd9;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: white;
    position: relative;
    top: 5px;
    transition: all 0.2s;
}

.suggest-component-tip:hover {
    background-color: #d4def2;
    color: #3e6fd9;
}

.border-item {
    border: 2px solid #f1666a;
    padding-top: 9px;
    padding-left: 14px;
}

.component-dialog-wrapper {
    width: 1000px;
    height: 537px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.generate-component-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 50%;
    align-content: flex-start;
}

.generate-component-items:first-child {
    border-right: 2px solid #b4b4d8;
}

.generate-component-input {
    margin-top: -25px;
    width: 730px;
    height: 76px;
}

.suggest-attribute-button.generate-component-button {
    width: 50%;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 19px;
    background-color: #3e6fd9;
    color: white;
}

.generate-component-items_component {
    width: 100%;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.suggested-component-modal-title {
    color: #cc4b4f;
    overflow: hidden;
    font-weight: bold;
    text-overflow: ellipsis;
    font-size: 1.6rem;
}
.suggest-input-container.generate-component-input img {
    height: 30px;
    top: 21px;
    right: 27px;
}

.suggested-component-modal-title-container {
    padding: 21px 28px 0;
    height: 20%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
    text-align: left;
    letter-spacing: 1.4px;
}

.components-dialog-header {
    width: 100%;
    height: 200px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
}

.custom-search-input-input.border-color-blue {
    border-color: #fad3d5;
    background-color: #ffe7e9;
    color: #242d60;
    font-size: 24px;
    text-align: start;
    height: 76px;
}

.custom-search-input-input.border-color-blue::-webkit-input-placeholder {
    color: #7385d2;
}

.custom-search-input-input.border-color-blue::placeholder {
    color: #7385d2;
}

.suggested-component-modal-title {
    color: #242d60;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    font-size: 22px;
    letter-spacing: normal;
    width: 95%;
    top: 5px;
    margin-bottom: 10px;
}

.suggest-component-dialog-body {
    display: flex;
    flex-grow: 1;
    padding: 26px 32px;
    width: 100%;
    height: 50%;
    padding-top: 10px;
}

.generate-loader {
    margin: 9% auto;
}

.type-illustration {
    margin-bottom: 25px;
    background: #fad3d5;
    height: 76px;
    width: 200px;
    margin-right: -10px;
    font-size: 24px;
    font-weight: 600;
    color: #242d60;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.type-illustration span {
    margin-left: 5px;
}

.component-dialog-generate-steps {
    height: 817px;
    width: 950px;
}

.component-dialog-generate-steps .components-dialog-header {
    height: 214px;
    padding-bottom: 20px;
}
.generate-radio-input-component.custom-radio-input,
.generate-radio-input-component.custom-radio-input.active {
    border: none;
    background: #fce8e9;
}

.step-item {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    width: 333px;
    border: 1px #c6cbed solid;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 7px;
    border-radius: 3px;
    left: auto;
    top: auto;
    box-shadow: 0 1px 1px #c6cbed;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.step-item::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 323px;
    color: transparent;
}

.step-item:hover {
    border-color: #f1666a;
    color: #f1666a;
}

.step-item.border-generate-steps {
    border-color: #f1666a;
    color: #f1666a;
}

.border-current-steps.step-item::after {
    background-color: #eceef5;
}

.step-item.border-current-steps:hover,
.step-item.border-current-steps {
    border-color: #3e6fd9;
    color: #242c5c;
}

.scrollbar-steps {
    height: 447px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 15px;
    margin-top: 15px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: #242c5c;
}

.steps-container {
    min-height: 300px;
}

.search-generate-image.pointer {
    padding: 25px;
    height: 80px;
    width: 80px;
    min-height: 80px;
    margin-top: -23px;
    margin-right: -18px;
}

.generate-project-type-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    margin-top: 38px;
    padding: 0 28px;
}

.generate-component-items-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.generate-component-items-header_select-all {
    border-radius: 5px;
    padding: 10px 0 7px 0;
    margin: 0 20px 0 0;
}

.generate-component-items-header_select-all__steps {
    margin: 0 0 0 20px;
    border: solid 1px  #3e6fd9;
    box-shadow: 0 1px 1px #3e6fd9;
}

.generate-component-items-header_select-all__blue {
    border: solid 1px  #3e6fd9;
    box-shadow: 0 1px 1px #3e6fd9;
}

.generate-component-items-header_select-all__blue.disabled,
.generate-component-items-header_select-all__steps.disabled {
    background-color:  #3e6fd9;
    opacity: 0.5;
    border: none;
    box-shadow: none;
}

.generate-component-items-header_select-all__red {
    border: solid 1px  #f1666a;
    box-shadow: 0 1px 1px #f1666a;
}

.generate-component-items-header_select-all__red.disabled {
    background-color: #f1666a;
    opacity: 0.5;
    border: none;
    box-shadow: none;
}

.generate-component-items-header_checkbox {
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    padding: 0;
}

.generate-component-items-header_label {
    margin: 0 0 0 10px;
}

.generate-component-items-header_label.disabled {
    color: #ffffff;
}

.generate-component-items-header_label__blue {
    color: #3e6fd9;
}

.generate-component-items-header_label__red {
    color: #f1666a;
}

.custom-class-checkbox {
    border: 2px solid #f1666a;
    background-color: #f1666a;
}

.tooltip-inner-text.generate {
    font-size: 16px;
    height: 23px;
}

.scrollbar-steps-tooltip {
    width: 320px;
    color: #242c5c;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
    text-align: start;
}

.generate-component-items_tooltip {
    color: #212529;
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 0;
}
.report-page-wrapper {
    margin: 91px 40px 0;
    font-family: 'Inter';
    background-color: #f7f8fa;
}

.report-page-title {
    height: 40px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 32px;
    white-space: nowrap;
    margin-right: 20px;
}

.print-report-button {
    width: 151px;
    height: 41px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.print-report-button span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 15px;
}

.print-report-button svg {
    color: #3e6fd9;
}

.print-report-button:hover {
    background-color: #3e6fd9;
}

.print-report-button:hover span {
    color: white;
}

.print-report-button:hover svg {
    color: white;
}

.print-report-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.reports-container-header {
    width: calc(100% - 10px);
    margin: 30px 0 10px 10px;
    padding: 0 20px 0 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.reports-container {
    width: calc(100% - 10px);
    margin-left: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 20px 20px 10px;
    overflow-y: auto;
}

.reports-row-header {
    height: 22px;
}

.reports-row {
    height: 51px;
    background-color: #e8f6ec;
    border-radius: 4px;
    margin-bottom: 5px;
    position: relative;
    border: 2px solid transparent;
}

.reports-table {
    border-radius: 4px;
    min-width: 615px;
}

.reports-row.expanded {
    margin-bottom: 0;
}

.reports-row.green,
.reports-row-expanded.green {
    background-color: #e9f6ed;
}

.reports-row.yellow,
.reports-row-expanded.yellow {
    background-color: #fff4de;
}

.reports-row.red,
.reports-row-expanded.red {
    background-color: #fde6e1;
}

.reports-row.white,
.reports-row-expanded.white {
    background-color: #f8f9fa;
}

.reports-row:hover {
    border: 2px solid #3e6fd9;
}

.reports-row:hover.green {
    background-color: #d0e9d8;
}

.reports-row:hover.yellow {
    background-color: #f3e3c4;
}

.reports-row:hover.red {
    background-color: #f0cec7;
}

.reports-checkbox {
    height: 21px;
    width: 21px;
}

.reports-row-checkbox {
    width: 58px;
    min-width: 6%;
    height: 21px;
}

.reports-row-rating {
    flex: 1 1 82px;
    min-width: 6%;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.reports-row-component {
    flex: 1 1 135px;
    min-width: 8%;
}

.reports-row-first-attribute {
    flex: 1 1 175px;
    min-width: 11%;
    margin-left: 50px;
}

.reports-row-second-attribute {
    flex: 1 1 175px;
    min-width: 11%;
}

.reports-row-name {
    flex: 1 1 235px;
    min-width: 15%;
}

.reports-row-method {
    flex: 1 1 110px;
    width: 30px;
}

.reports-row-header .reports-row-method {
    flex: 1 1 120px;
    width: 30px;
}

.reports-row-header .reports-row-name {
    flex: 1 1 225px;
    min-width: 12%;
}

.border-report {
    height: 110%;
}

.border-right-report {
    border-right: 5px solid white;
    margin-right: -5px;
    margin-left: 5px;
    height: 110%;
}

.border-left-report {
    border-left: 5px solid white;
    padding-left: 5px;
    margin-left: -20px;
    margin-right: 20px;
    height: 110%;
}

.reports-row-benefit {
    justify-content: space-around;
    flex: 1 1 175px;
    min-width: 13%;
}

.reports-row-created {
    flex: 1 1 120px;
    min-width: 8%;
}

.reports-row-created-by {
    justify-content: center;
    flex: 1 1 120px;
    min-width: 8%;
}

.report-idea-score {
    width: 35px;
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #5ac57a;
}

.report-idea-score span {
    height: 35px;
    line-height: 35px;
    color: #ffffff;
    font-size: 21px;
}

.reports-row-text {
    height: 20px;
    line-height: 20px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.reports-row-initials {
    width: 32px;
    height: 26px;
    background-color: #fde8e9;
    border-radius: 2px;
}

.reports-row-initials span {
    height: 20px;
    color: #f1666a;
    font-size: 14px;
}

.padding-0 {
    padding: 0;
}

.abbreviation {
    height: 25px;
    line-height: 25px;
    margin-left: 5px;
    max-width: calc(100% - 55px);
}

.benefits-count {
    font-size: 21px;
    margin-bottom: 5px;
}

.reports-row-color-rating {
    height: calc(100% + 4px);
    width: 7px;
    min-width: 7px;
}

.reports-row-color-rating.expanded {
    height: 100%;
}

.reports-row:hover .reports-row-color-rating {
    height: 100%;
    border-radius: 2px 0 0 2px;
}

.reports-icon-container {
    min-width: 20px;
}

.reports-icon-container img {
    min-width: 20px;
    max-width: 20px;
}

.sort-triangle-icon {
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: #3e6fd9 transparent transparent transparent;
    margin-top: 5px;
}

.sort-triangle-icon.asc {
    border-color: transparent transparent #3e6fd9 transparent;
    margin-top: 0px;
    margin: 0 0 5px;
}

.reports-row-text.active {
    color: #3e6fd9;
}

.reports-row-buttons-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.reports-row-buttons-container:hover {
    opacity: 1;
}

.print-report-button.edit {
    margin-right: 10px;
    width: 77px;
    outline: none;
    padding: 0 10px 0 10px;
}

.print-report-button.matrix {
    margin-right: 10px;
    width: 138px;
    outline: none;
    padding: 0 10px 0 10px;
}

.print-report-button.multiplication {
    margin-right: 10px;
    width: 150px;
    outline: none;
    padding: 0 10px 0 10px;
}

.print-report-button.medium {
    outline: none;
}

.reports-input-container {
    margin: 0 0 8px 10px;
}

.report-page-inputs-settings {
    margin: 10px 0 10px 0;
}

.reports-page-inputs-container {
    min-width: 615px;
}

.reports-input-first {
    margin-left: auto;
    align-items: flex-start;
    white-space: nowrap;
}

.reports-page-inputs-container {
    flex-wrap: wrap;
}

.report-page-inputs {
    min-width: 615px;
}

@media only screen and (max-width: 1080px) {
    .search-input-container.report {
        max-width: 210px;
    }
}

.justify-content-start {
    justify-content: start;
}

.reports-row-expanded {
    width: 100%;
    max-height: 230px;
    margin-bottom: 5px;
    border-left: 7px solid transparent;
    margin-left: 2px;
}

.reports-row-expanded-section {
    flex: 1 1;
    display: flex;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.reports-row-expanded-header {
    padding: 15px 30px 0;
    background-color: #d6e8dc;
    border-radius: 6px 6px 0 0;
    width: 100%;
    min-height: 70px;
}

.reports-row-expanded-section:not(:last-of-type) {
    margin-right: 15px;
}

.reports-row-expanded-header span {
    height: 24px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 18px;
}

.reports-row-expanded-container {
    padding: 20px 30px;
    width: 100%;
}

.reports-row-expanded-content {
    padding: 15px 25px;
    height: 100%;
    overflow: auto;
    text-align: left;
}

.reports-row-comments {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    word-break: break-word;
}

.list-item {
    margin-bottom: 10px;
    align-items: baseline;
}

.list-item span,
.issue-description {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 16.5px;
    text-align: left;
    word-break: break-word;
}

.list-item p {
    margin-bottom: 0;
}

.user-initials

.benefits-list-container {
    align-items: flex-start;
}

.benefits-list-title img {
    margin-top: 5px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin-right: 8px;
}

.benefits-list-title .bullet-point-item {
    width: 12px;
    height: 12px;
    margin-top: 5px;
}

.benefits-list-title span {
    height: auto;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    word-break: break-word;
}

.benefits-list {
    padding-left: 25px;
}

.benefits-list span {
    word-break: break-word;
}

.reports-row-expanded-content.green {
    background-color: #dcefe2;
}

.reports-row-expanded-content.yellow {
    background-color: #f5ead1;
}

.reports-row-expanded-content.red {
    background-color: #f7ddd9;
}

.reports-row-expanded-header.green {
    background-color: #d6e8dc;
}

.reports-row-expanded-header.yellow {
    background-color: #ebdfc4;
}

.reports-row-expanded-header.red {
    background-color: #f4d5d0;
}

.ml-30 {
    margin-left: 35px;
}

.reports-row-benefit div {
    justify-content: center;
}

.issue-expanded-report {
    margin-left: 10px;
}

.message-expanded-report {
    margin-left: 5px;
    max-width: calc(100% - 70px);
}

.compact-row {
    letter-spacing: -0.7px;
}

.letter-spacing-compact {
    letter-spacing: -0.9px;
}

.share-ideas-wrapper {
    width: 622px;
    height: 720px;
    background-color: #ffffff;
    border-radius: 4px;
    font-family: 'Inter';
    position: relative;
}

.share-ideas-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-radius: 4px 4px 0 0;
}

.share-ideas-body {
    padding: 30px 40px 0 40px;
    width: 100%;
}

.share-ideas-radio-text {
    font-family: 'Inter';
    font-size: 23px;
    color: #242c5c;
    line-height: 22px;
    -webkit-user-select: none;
            user-select: none;
}

.share-ideas-label {
    width: 549px;
    height: 50px;
    background-color: #eaf7ee;
    border-radius: 4px;
}

.share-ideas-label span {
    width: 183px;
    height: 32px;
    color: #39ac5b;
    font-family: 'Inter';
    font-size: 22px;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-left: 10px;
}

.share-ideas-button {
    align-self: flex-end;
    width: 243px;
    height: 44px;
    border: none;
    border-radius: 4px;
    background-color: #3e6fd9;
    position: absolute;
    bottom: 40px;
    outline: none !important;
}

.share-ideas-button span {
    height: 24px;
    color: #ffffff;
    font-size: 18px;
    text-transform: uppercase;
}

.custom-radio-option {
    width: 100%;
    height: 94px;
    flex: 1 1;
    margin-right: 20px;
    border-radius: 4px;
    background-color: #f7f8fa;
    border: 2px solid #cdd1e5;
    position: relative;
    padding: 0px 60px;
}

.custom-radio-option:hover {
    border: 2px solid #3e6fd9;
    border-color: currentColor;
}

.custom-radio-option:last-of-type {
    margin-right: 0;
}

.default-radio-option-text {
    height: 33px;
    color: #888da8;
    font-family: 'Inter';
    color: #242c5c;
    font-size: 26px;
    -webkit-user-select: none;
            user-select: none;
}

.radio-button-checkmark {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    border: 2px solid #cdd1e5;
    background-color: #f7f8fa;
    position: absolute;
    top: 15px;
    right: 20px;
}

.custom-radio-option svg {
    position: absolute;
    top: 15px;
    right: 20px;
    -webkit-transform: translate(calc((100% - 25px) / 2), calc(0px - (100% - 25px) / 2));
            transform: translate(calc((100% - 25px) / 2), calc(0px - (100% - 25px) / 2));
}

.custom-radio-option.active {
    border: 2px solid #3e6fd9;
}

.custom-radio-option.active .custom-radio-option-span {
    color: #242c5c;
}

.custom-radio-label {
    height: 33px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    margin-bottom: 15px;
}

.radio-button-tooltip {
    margin-left: 10px;
    margin-bottom: 10px;
}

.user-dropdown-option {
    min-height: 48px;
    border: 2px solid transparent;
    background-color: #f7f8fa;
}

.user-dropdown-option.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.user-dropdown-option.first {
    width: 100%;
}

.user-dropdown-option:not(.first):hover {
    border: 2px solid #3e6fd9;
    background-color: white;
}

.users-option-label-container {
    margin-right: 5px;
}

.user-dropdown-label-text {
    color: #3e6fd9;
    margin-left: 15px;
    font-size: 16px;
    text-align: left;
    height: 22px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-dropdown-divider {
    margin: 0;
    margin-top: auto;
}

.user-dropdown-name {
    height: 46px;
    padding: 0 35px 0 28px;
}

.user-dropdown-name svg {
    position: absolute;
    right: 35px;
    -webkit-transform: translate(calc((100% - 25px) / 2), 0);
            transform: translate(calc((100% - 25px) / 2), 0);
}

.user-round-button {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    border: 2px solid #cdd1e5;
    background-color: #f7f8fa;
    position: absolute;
    right: 35px;
}

.remove-user-button {
    background: transparent;
    height: 22px;
    width: 22px;
    border: none;
    outline: none !important;
}

.remove-user-button img {
    max-width: 14px;
    max-height: 14px;
}

.users-labels-container {
    margin-left: 14px;
    margin-right: 35px;
    max-height: 78px;
    overflow: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.users-labels-container::-webkit-scrollbar {
    display: none;
}

.users-option-label {
    border-radius: 4px;
    white-space: nowrap;
    height: 22px;
    background-color: #cdd1e5;
    padding: 0 5px;
    margin-bottom: 5px;
}

.users-option-label span {
    height: 18px;
    color: #242c5c;
    font-size: 12px;
}

.users-option-label:not(:last-of-type) {
    margin: 0 10px 10px 0;
}

.users-option-label-text {
    height: 27px;
    color: #465664;
    font-size: 20px;
    margin: 20px 0 5px 20px;
    align-self: flex-start;
    text-align: left;
}

.teams-option-label {
    border-radius: 4px;
    white-space: nowrap;
    height: 22px;
    background-color: #cdd1e5;
    padding: 0 5px;
}

.teams-option-label span {
    height: 18px;
    color: #242c5c;
    font-size: 12px;
}

.teams-option-label:not(:last-of-type) {
    margin: 0 10px 10px 0;
}

.teams-option-label-text {
    height: 27px;
    color: #465664;
    font-size: 20px;
    margin: 20px 0 5px 20px;
    align-self: flex-start;
    text-align: left;
}

.teams-dropdown-option {
    min-height: 90px;
    border: 2px solid transparent;
}

.teams-dropdown-option.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.teams-dropdown-option.first {
    width: 100%;
    min-height: 48px;
}

.teams-dropdown-option:not(.first):hover {
    border: 2px solid #3e6fd9;
    background-color: #dbdfed;
}

.teams-option-label-container {
    margin-right: 5px;
}

.teams-dropdown-team-name svg {
    position: absolute;
    right: 18px;
    -webkit-transform: translate(calc((100% - 25px) / 2), 0);
            transform: translate(calc((100% - 25px) / 2), 0);
}

.teams-round-button {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    border: 2px solid #cdd1e5;
    background-color: #f7f8fa;
    position: absolute;
    right: 18px;
}

.teams-labels-container {
    margin-left: 14px;
}

.labels-container.first {
    max-height: 28px;
    max-width: 100%;
    overflow: hidden;
}

.teams-option-divider {
    margin: 0;
    margin-top: auto;
}

.teams-labels-container-first {
    margin-left: 14px;
    margin-right: 35px;
    max-height: 78px;
    overflow: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.teams-labels-container-first::-webkit-scrollbar {
    display: none;
}

:root {
    --active-route-clr: #303c80;
    --active-route-clr: #303c80;
}

.content-wrapper {
    min-width: 768px;
}

.sidebar {
    background-color: #242c5c;
    font-family: 'Inter', sans-serif;
    position: fixed;
    top: 0;
    z-index: 1000;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    font-size: 15px !important;
    line-height: 18px;
    color: #b4bad8;
    font-weight: 500;
    font-family: 'Inter';
}
.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar.compact {
    width: 74px;
    min-width: 74px;
    padding-right: 2px;
}

.main-content {
    width: 100%;
    overflow-x: hidden;
}

.sidenav-item {
    margin: 0 10px;
    /* width: 90%; */
    height: 42px;
    -webkit-user-select: none;
            user-select: none;
}

.language-item {
    width: 100%;
}

.tools {
    font-size: 13px;
    color: #8a93c3;
    font-weight: 500;
    font-family: 'Inter';
    margin-left: 20px;
    margin-bottom: 5px;
    width: 5ch;
}

.tools.compact {
    margin-left: 15px;
}

.sidenav-item div span {
    display: flex;
    align-items: center;
}

.sidenav-item.compact {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidenav-item.active {
    background: #303c80;
    background: var(--active-route-clr);
    color: white;
    transition: background-color 0.1s linear;
}

.sidenav-item:not(.active):not(.flag-item):not(.sidenav-item .sidenav-item):hover {
    background: #171e46;
    transition: background-color 0.1s linear;
}

.sidenav-item.profile .sidenav-title {
    /* color: #8a93c3; */
    line-height: 20px;
}

.triangle-right-icon {
    border: 5px solid;
    border-color: transparent transparent transparent #5b6493;
    margin: 0 0 0 10px;
}

.sidenav-item.active .triangle-right-icon {
    border-color: transparent transparent transparent #6682ce;
}

.sidenav-title {
    /* color: #f7f8fa; */
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    max-width: 105px;
    height: 16px;
}

.sidenav-title.attribute-dependency,
.sidenav-title.username {
    height: auto;
    /* min-width: 87%; */
    /* max-width: 87%; */
}

.sidenav-item svg {
    margin: 10px 12px;
    width: 21px;
    min-width: 21px;
    height: 21px;
    min-height: 21px;
    pointer-events: none;
}

.sidenav-logo {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #242c5c;
    width: 243.7px;
    height: 70px;
    -webkit-user-select: none;
            user-select: none;
    align-self: flex-start;
}

.sidenav-logo.compact {
    align-self: unset;
    margin-left: 0;
    width: 76px;
}

.sidenav-logo img {
    pointer-events: none;
    margin-right: 14px;
}

.sidenav-logo.compact img {
    pointer-events: none;
    margin-right: 0px;
}

.nav-items-container {
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-y: scroll;
}
.nav-items-container::-webkit-scrollbar {
    display: none;
}

.nav-items-container.compact {
    width: 76px;
}

.expand-icon {
    min-width: 21px;
    min-height: 21px;
    height: 21px;
    width: 21px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid #8a93c3;
    margin: 18px 15px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.expand-icon.compact {
    -webkit-transform: none;
            transform: none;
}

.expand-icon-triangle {
    border: 5px solid;
    border-color: transparent transparent transparent #8a93c3;
    position: absolute;
    left: 8px;
    top: 5px;
}

.sidenav-item-tooltip {
    position: absolute;
    left: 74px;
    background-color: #161d4a;
    white-space: nowrap;
    z-index: 1000;
    height: 30px;
    min-height: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
}

.sidenav-item-tooltip::before {
    content: '';
    width: 0;
    height: 0;
    border: 5px solid;
    border-color: transparent #161d4a transparent transparent;
    position: absolute;
    left: -10px;
}

.sidenav-item-tooltip span {
    color: white;
}

.sidenav-item:hover .sidenav-item-tooltip {
    opacity: 1;
}

.sidenav-item-tooltip-container {
    margin-right: 5px;
}

.support-icon {
    min-width: 21px;
    min-height: 21px;
    height: 21px;
    width: 21px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid #8a93c3;
    margin: 18px 15px;
}

.support-icon span {
    font-size: 16px;
    font-family: 'Inter';
    color: #8a93c3;
    height: 30px;
}

.flag-icon-nav-bar {
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin: 18px 9px 18px 3px;
}

.flag-icon-nav-bar.compact {
    margin: 18px 12px 18px 5px;
}

.sidenav-templates-label {
    text-align: start;
    font-size: 13px;
    width: 166px;
    color: #8a93c3;
    font-family: 'Inter';
    margin-top: 10px;
    height: 20px;
}

.attribute-dependency {
    min-width: 100% !important;
}

.attribute-dependency-nav-item {
    width: var(--idea-nav-item-width);
}

.sidenav-hr {
    margin: 8px 0px 10px 0px;
    width: 244px;
    color: #ffffff;
    border-top: 2px solid #2f3771;
}

.sidenav-hr.compact {
    width: 76px;
    border-top: 2px solid #2f3771;
}

.sidenav-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 87px;
    margin-bottom: 10px;
}

.sidenav-items:not(.compact) {
    width: 244px;
}

.en-flag-label {
    height: 25px;
}

.cn-flag-label {
    height: 22px;
}

.dashed-hr {
    border: none;
    border-top: 1px dashed rgba(239, 241, 255, 0.479);
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.notification-counter {
    background-color: #f1666a;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    left: 5px;
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
}
.notification-counter span {
    font-size: 20px;
    font-family: 'Inter';
    color: white;
}

.issue-ongoing-counter {
    border-color: #ffcd67;
    border: 1px solid #ffcd67;
    color: #ffcd67;
}

.issues-modal-background {
    background-color: rgba(0, 0, 0, 0.24);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
}
.issues-modal {
    cursor: auto;
    padding: 26px;
    position: fixed;
    width: 720px;
    min-height: 100px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    top: 45%;
    z-index: 100;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 9px 26px 13px rgba(34, 60, 80, 0.2);
}

.issues-modal-title {
    font-family: 'Inter';
    font-size: 22px;
    color: #242c5c;
}

.issues-modal-text {
    margin-top: 10px;
    text-align: justify;
    font-size: 18px;
    color: black;
    line-height: 24px;
}

.issues-modal-link {
    color: #3e6fd9;
    font-size: 18px;
    cursor: pointer;
}
.issues-modal-button-container {
    margin-top: 10px;
}
.issues-modal-buttons {
    margin-left: 10px;
    margin-right: 10px;
    color: #3e6fd9;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: rgba(62, 111, 217, 0.1);
    outline: none !important;
}
.sidenav-dropdown-item {
    margin: 0;
}

.person-left-panel {
    background-color: #242c5c;
}

.division-nav-item {
    margin-left: 45px;
}

.username-dialog-wrapper {
    width: 622px;
    height: 600px;
    border-radius: 4px;
    position: relative;
    font-family: 'Inter';
}

.username-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 4px 4px 0 0;
}

.username-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    height: 38px;
    margin-left: 10px;
}

.username-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 165px);
}

.username-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.username-dialog-button {
    border: none;
    width: 105px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.Profile_profile__h1ML9 {
    padding-left: 10px;
}

.Profile_text-color__1NE4p {
    color: #242c5c;
}

.Profile_title__VMsNG {
    font-size: 26px;
}

.Profile_name-title__3KE82 {
    font-size: 11px;
    margin-top: 20px;
}

.Profile_email-title__3pBmw {
    font-size: 11px;
    margin-top: 12px;
}

.Profile_email-info__1VjLz {
    font-weight: 500;
    font-size: 16px;
    margin-top: -6px;
}

.Profile_name-info__3_uHW {
    font-size: 20px;
    font-weight: 500;
    margin-top: -6px;
}

.Profile_default-button__3KUPD {
    color: #3e6fd9;
    width: auto;
    min-width: auto;
}

.Profile_default-button__3KUPD:hover {
    color: white;
}

.Profile_reset-password-button__2gHfK {
    margin-top: 15px;
}

.Profile_edit-profile-button__1wZoF {
    margin-top: 20px;
}

.ChangeUserPasswordModal_dialog__3oGQs {
    width: 622px;
    height: 600px;
}

.ChangeUserPasswordModal_body__Ug8dG {
    height: 515px !important;
}

.CancelConformationSubscriptionModal_header-body__3o_E3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
    text-align: center;
    color: #242c5c;
    margin-top: 39px;
}

.CancelConformationSubscriptionModal_table__3PNHD {
    width: 539px;
    height: 258px;
    border-color: #cdd1e5;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    background: #f7f8fa;
    margin-top: 23px;
    padding-top: 38px;
}

.CancelConformationSubscriptionModal_table-item__203gC {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
    margin-left: 49px;
    max-width: 420px;
    display: flex;
}

.CancelConformationSubscriptionModal_circle__HDqio {
    min-width: 16px;
    margin-right: 16px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #cdd1e5;
}

.CancelConformationSubscriptionModal_cancel-button__1QvT7 {
    width: 539px;
    height: 58px;
    font-size: 20px;
    margin-top: 40px;
}

.CancelConformationSubscriptionModal_dialog__m1L4J {
    height: 551px;
    width: 662px;
}

.ManageSubscription_header__1oi2l {
    color: #242c5c;
    font-size: 26px;
}

.ManageSubscription_promo-code__2uOVZ {
    background-color: #e2eafc;
    height: 76px;
    margin-top: 22px;
}

.ManageSubscription_promo-code__2uOVZ span {
    color: #242c5c;
    font-size: 20px;
    padding-left: 33px;
    padding-right: 40px;
    font-weight: 500;
}

.ManageSubscription_promo-code-button__YZSfV {
    color: #3e6fd9;
    margin-left: 5px;
    width: 90px;
    font-weight: 500;
}

.ManageSubscription_promo-code-button__YZSfV:hover {
    color: white;
}

.ManageSubscription_promo-code-field__2H2u0 {
    width: 215px;
    font-weight: 500;
    border: 2px #9eb6ec solid;
}

.ManageSubscription_promo-code-field__2H2u0::-webkit-input-placeholder {
    color: #3e6fd9;
    font-weight: 500;
}

.ManageSubscription_promo-code-field__2H2u0::placeholder {
    color: #3e6fd9;
    font-weight: 500;
}

.ManageSubscription_promo-code-field__2H2u0:focus,
.ManageSubscription_promo-code-field__2H2u0:hover {
    border-color: #3e6fd9;
}

.ManageSubscription_subscription_block__3UGAe {
    min-height: 310px;
    border-color: #c6cbed;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 28px 33px;
    margin-top: 20px;
}

.ManageSubscription_header-active-subscription__2MWld {
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    text-align: start;
    display: flex;
}

.ManageSubscription_body-active-subscription__20Nz2 {
    margin-top: 20px;
    min-height: 220px;
}

.ManageSubscription_left-body-active-subscription__1i7qC {
    min-width: 255px;
}

.ManageSubscription_active-plan-header__1O5Pv {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #242c5c;
    text-align: start;
    display: flex;
}

.ManageSubscription_active-plan-item__1lZ4Z {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #242c5c;
    list-style-type: '– ';
    min-width: 240px;
}

.ManageSubscription_active-plan-items__gNaiX {
    margin-top: 10px;
    padding-left: 13px;
}

.ManageSubscription_credits-header__6eSAb {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
}

.ManageSubscription_credits__2vrtL {
    font-size: 30px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    margin-top: 20px;
}

.ManageSubscription_credits-details__2AiXB {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    width: 120px;
    margin-top: 7px;
}

.ManageSubscription_credits-buy__1DIlM {
    border-color: #f1666a;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #fde8e9;
    font-size: 16px;
    text-align: left;
    color: #f1666a;
    width: 205px;
    height: 43px;
    font-weight: 500;
    padding-left: 12px;
    margin-top: 29px;
}

.ManageSubscription_mb-32__2MFNs {
    margin-bottom: 32px;
}

.ManageSubscription_mt-20__2wWYN {
    margin-top: 20px;
}

.ManageSubscription_credits-buy__1DIlM:hover {
    color: white;
    background-color: #cc4b4f;
}

.ManageSubscription_billing-description__FyBaH {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #242c5c;
    width: 140px;
}

.ManageSubscription_billing-description-right__1W-22 {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-left: -26px;
    width: 140px;
    text-align: right;
    color: #242c5c;
}

.ManageSubscription_active-plan-body__301CM {
    margin-top: 16px;
}

.ManageSubscription_get-billing-details__3_wPZ {
    width: 236px;
    height: 43px;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #3e6fd9;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    margin-top: 10px;
}

.ManageSubscription_cancel-subscription-link__35p-- {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #3e6fd9 !important;
    text-align: start;
    display: flex;
    margin-top: 10px;
}

.ManageSubscription_cancel-subscription-link__35p--:hover {
    cursor: pointer;
}

.ManageSubscription_border-active-subscription__WjYLA {
    border-left: 2px #eceef5 solid;
    padding-left: 25px;
}

.ManageSubscription_border-ai-credits__1HH0T {
    border: 1px #c6cbed solid;
    border-radius: 5px;
    padding: 25px;
}

.ManageSubscription_padding-credits__2nq-p {
    padding-right: 30px;
}

.ManageSubscription_padding-15__jhpdq {
    padding: 15px;
}

.ManageSubscription_bulling-button-dark-blue__22Kc- {
    width: 168px;
    height: 43px;
    font-weight: 500;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #3e6fd9;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
}

.ManageSubscription_bulling-button-blue__5dnOB {
    color: #3e6fd9;
    margin-left: 5px;
    width: 168px;
    height: 43px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 0;
}

.ManageSubscription_bulling-button-blue__5dnOB:hover {
    color: white;
}

.ManageSubscription_billing-left-button__307A4 {
    margin-left: -20px;
}

.ManageSubscription_billing-right-button__2cK8k {
    margin-left: 0 !important;
}

.ManageSubscription_buy-subscription__3icJY {
    margin-top: 27px;
}

.ManageSubscription_page__1JSTO {
    min-width: 1200px;
}

.ManageSubscription_billing-left-blue__2SILR {
    margin-left: -5px;
}

.ManageSubscription_ml-10__147gJ {
    margin-left: 10px;
}

.ManageSubscription_plan__3bs-i {
    width: 335px;
    min-height: 540px;
    padding: 27px 36px 27px;
    border-color: #c6cbed;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    -webkit-filter: drop-shadow(0px 1px 0.5px #c6cbed);
            filter: drop-shadow(0px 1px 0.5px #c6cbed);
    background: #ffffff;
    margin-top: 10px;
    margin-bottom: 20px;
}

.ManageSubscription_plan-header__DUrqt {
    font-size: 20px;
    font-weight: normal;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
    font-weight: 500;
}

.ManageSubscription_plan-amount__2jqE_ {
    font-size: 30px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
}

.ManageSubscription_plan-period__2yC48 {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #242c5c;
    margin-left: 12px;
}

.ManageSubscription_plan-prev__3ppp_ {
    margin-top: 14px;
}

.ManageSubscription_plan-billed__qDXIW {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 4px;
    text-align: left;
    color: #242c5c;
}

.ManageSubscription_plan-buy__8c3CA {
    width: 168px;
    height: 43px;
    font-weight: 500;
    border-color: #3e6fd9;
    border-width: 1px;
    border-style: solid;
    background: #3e6fd9;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    min-width: 100%;
    margin-top: 8px;
}

.ManageSubscription_get-billing-details__3_wPZ:hover,
.ManageSubscription_plan-buy__8c3CA:hover {
    background-color: #3061ae;
    color: white;
}

.ManageSubscription_plan-descriptions__27sgr {
    margin-left: -25px;
    margin-top: 20px;
}

.ManageSubscription_plan-credits-description__2RoOS {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    font-style: normal;
    text-align: left;
    display: flex;
    width: 270px;
    color: #242c5c;
    font-weight: 500;
}

.ManageSubscription_select-amount__3i2LG {
    padding-left: 27px;
    margin-top: 10px;
    font-weight: 500;
}

.ManageSubscription_select-amount__3i2LG:hover {
    border-color: #f1666a;
    color: #f1666a;
    background-color: #fde8e9;
}

.ManageSubscription_plan-credits-buy__2cRE5 {
    border-top: 2px #c6cbed solid;
    padding-top: 15px;
}

.ManageSubscription_select-credits__3xA82 {
    align-items: center;
    height: 39px;
    padding-left: 17px;
    padding-right: 20px;
    background: #fafafa;
    border-color: #f1f1f1;
    border-width: 1px;
    border-style: solid;
    font-size: 17px;
    font-weight: 500;
    line-height: 40px;
    font-style: normal;
    text-align: left;
    color: #242c5c;
}

.ManageSubscription_select-credits__3xA82:hover {
    background-color: #ffffff;
    cursor: pointer;
}

button:active,
button:focus {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}

.ManageSubscription_select-credits-block__31kKh {
    position: absolute;
    bottom: 69px;
    width: 261px;
    -webkit-filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.16));
}

.ManageSubscription_select-amount-active__3fDEm {
    border-color: #f1666a;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background: #fde8e9;
    align-items: center;
    display: flex;
    width: 250px;
    height: 43px;
    -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    border: 0;
    background-color: #fafafa;
}

.ManageSubscription_select-amount-active__3fDEm:hover {
    background-color: #fafafa;
    color: #242c5c;
}

.ManageSubscription_arrow-icon__2DWK9 {
    width: 30px;
    height: 42px;
    position: relative;
}

.ManageSubscription_select-credits-label__32i9l {
    display: flex;
    justify-content: space-between;
    width: 195px;
}

.MyAccount_line__2MEZG {
    background: #eceef5;
    min-width: 2px;
    height: 87vh;
    margin-left: 30px;
    margin-right: 30px;
}

.profile-wrapper {
    margin: 0 40px;
    font-family: 'Inter';
}

.profile-wrapper .header-image-container {
    margin-top: 10px;
}

.profile-header-top {
    height: 84px;
}

.profile-header-title {
    height: 40px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    margin-top: 30px;
}

.profile-wrapper hr {
    width: 100%;
    margin: 0 0 20px 0;
    color: #eceef5;
}

.users-page-wrapper {
    margin: 95px 40px 0;
    font-family: 'Inter';
    background-color: #f7f8fa;
    max-height: calc(100vh - 20px);
    overflow-y: hidden;
}

.users-page-title-container {
    min-width: 150px;
    height: 38px;
    text-align: left;
}

.users-page-count {
    height: 38px;
    color: #838fdb;
    font-size: 30px;
}

.users-page-title {
    height: 40px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 27px;
    margin-right: 5px;
}

.button-triangle-icon {
    border: 5px solid;
    border-color: transparent transparent transparent #3e6fd9;
}

.users-button {
    width: 177px;
    height: 41px;
    border: 1px solid #3e6fd9;
    background-color: #ecf1fc;
    border-radius: 4px;
    padding: 0 10px 0 15px;
    outline: none !important;
    margin-right: 15px;
}

.users-button span {
    height: 20px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 15px;
    text-transform: uppercase;
}

.users-button:hover {
    background-color: #3e6fd9;
}

.users-button:hover span {
    color: white;
}

.users-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.users-button.team-button {
    width: 197px;
    margin-right: 10px;
}

.team-types-count .team-type-indicator {
    height: 40px;
}

.team-types-count .team-type-indicator:not(:last-of-type) {
    margin-right: 10px;
}

.users-copy-link-button {
    margin-right: 10px;
    min-width: 115px;
}

.users-copy-link-button .copy-link-button,
.users-copy-link-button .share-link-button {
    height: 41px;
    max-width: 200px;
}

.users-page-wrapper .custom-search-input-input {
    min-width: 130px;
}

.users-placeholder {
    padding-top: 30px;
}

.users-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.users-row {
    height: 51px;
    background-color: #f3f4f8;
    border-radius: 4px;
    margin-bottom: 2px;
    border: 2px solid transparent;
}

.users-row:hover {
    border: 2px solid #3e6fd9;
    background-color: #eceef5;
}

.users-row-header {
    height: 22px;
    margin-bottom: 2px;
    background-color: transparent;
}

.users-container-header {
    width: calc(100% - 10px);
    margin: 20px 0 10px 10px;
    padding: 0 20px 0 10px;
}

.users-row-color-indicator {
    height: 51px;
    min-width: 7px;
    max-width: 7px;
    background-color: #cdd1e5;
}

.users-row:hover .users-row-color-indicator {
    background-color: #3e6fd9;
    border-radius: 4px 0 0 4px;
}

.users-row-checkbox {
    width: 58px;
    min-width: 58px;
    height: 21px;
    min-width: 5%;
    max-width: 58px;
}

.users-checkbox {
    height: 21px;
    width: 21px;
}

.users-row-initials {
    min-width: 5%;
    max-width: 56px;
    flex: 1 1 56px;
}

.users-row-fullname {
    min-width: 17%;
    flex: 1 1 226px;
}

.users-row-username {
    min-width: 28%;
    flex: 1 1 385px;
}

.users-row-team {
    min-width: 22%;
    overflow: hidden;
    flex: 1 1 298px;
}

.users-row-date {
    min-width: 16%;
    flex: 1 1 272px;
}

.users-row-color-indicator.transparent {
    background-color: transparent;
}

.users-rows-container {
    width: calc(100% - 10px);
    margin-left: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 20px 20px 10px;
    overflow-y: auto;
}

.users-row-initials-block {
    width: 31px;
    height: 26px;
    background-color: #fde8e9;
    border-radius: 2px;
}

.users-row-initials-block span {
    height: 20px;
    color: #f1666a;
    font-size: 14px;
}

.users-row-full-name-text {
    height: 22px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.users-row-text {
    height: 22px;
    color: #242c5c;
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-row-text.active {
    color: #3e6fd9;
}

.users-row-text.pointer {
    -webkit-user-select: none;
            user-select: none;
}

.more-button {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 30px;
    outline: none !important;
    -webkit-user-select: none;
            user-select: none;
}

.more-button img {
    pointer-events: none;
    min-height: 15px;
    min-width: 4px;
}

.team-type-indicator {
    width: 149px;
    height: 34px;
    background-color: #d6e3ff;
    border-radius: 4px;
    padding: 0 15px;
}

.team-type-label {
    height: 23px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 16px;
    white-space: nowrap;
}

.team-type-count {
    height: 23px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    margin-right: 5px;
}

.team-type-indicator svg {
    height: 19px;
    min-height: 19px;
    width: 19px;
    min-width: 19px;
    color: #242c5c;
    margin-right: 5px;
}

.team-type-indicator.individual {
    background-color: #ffdedf;
}

.team-type-indicator.individual svg {
    height: 19px;
    min-height: 19px;
    width: 14px;
    min-width: 14px;
    color: #242c5c;
    margin-right: 10px;
}

.menu-button-placeholder {
    min-height: 30px;
    min-width: 30px;
    width: 30px;
}

.promo-code-button {
    border-radius: 4px;
    border: none;
    width: auto;
    height: 41px;
    background-color: #3e6fd9;
    outline: none !important;
    padding: 0 10px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-left: 20px;
}

.promo-code-button span {
    height: 19px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 15px;
    text-transform: uppercase;
}

.promo-codes-inputs .promo-codes-input-container {
    margin: 0px 0 10px 20px;
    white-space: nowrap;
}

.promo-codes-input-container:first-of-type {
    margin-left: 0;
}

.promo-codes-table-container {
    height: 500px;
    margin-top: 10px;
}

.promo-codes-header-container-header {
    margin: 10px 0 10px 0;
    padding: 0 20px 0 10px;
}

.promo-codes-header-row-header {
    height: 22px;
    margin-bottom: 2px;
}

.promo-codes-header-indicator {
    height: 51px;
    min-width: 7px;
    max-width: 7px;
    background-color: #cdd1e5;
}

.promo-codes-header-indicator.transparent {
    background-color: transparent;
}

.promo-codes-rows-container {
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 20px 20px 10px;
    overflow-y: auto;
}

.promo-codes-row-text {
    height: 22px;
    color: #242c5c;
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.promo-codes-row-text:hover,
.promo-codes-row-fullname-text:hover {
    background-color: #eceef5;
    border-right: 8px solid #eceef5;
    position: absolute;
}

.promo-codes-row.disabled .promo-codes-row-text:hover,
.promo-codes-row.disabled .promo-codes-row-fullname-text:hover {
    background-color: #d6dbec;
    border-right: 8px solid #d6dbec;
}

.promo-codes-row-fullname-text {
    height: 22px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.promo-codes-header-row-date {
    min-width: 9%;
    flex: 1 1 110px;
    padding-left: 10px;
}

.promo-codes-header-row-created-by {
    min-width: 13%;
    flex: 1 1 160px;
}

.promo-codes-header-row-customer {
    min-width: 13%;
    flex: 1 1 160px;
}

.promo-codes-header-row-licenses {
    min-width: 7%;
    flex: 1 1 80px;
}

.promo-codes-header-row-duration-type {
    min-width: 6%;
    flex: 1 1 70px;
}

.promo-codes-header-row-duration-count {
    min-width: 7%;
    flex: 1 1 50px;
}

.promo-codes-header-row-price {
    min-width: 7%;
    flex: 1 1 90px;
}

.promo-codes-header-row-discount {
    min-width: 7%;
    flex: 1 1 60px;
}

.promo-codes-header-row-comments {
    min-width: 15%;
    flex: 1 1 160px;
}

.promo-codes-header-row-code {
    min-width: 9%;
    flex: 1 1 120px;
}

.promo-codes-header-row-auto-renewal {
    min-width: 7%;
    flex: 1 1 60px;
}

.promo-codes-button-container {
    min-height: 30px;
    min-width: 30px;
    position: absolute;
    right: 10px;
    -webkit-user-select: none;
            user-select: none;
}

.promo-codes-button-container:hover {
    z-index: 100;
    opacity: 1;
}

.promo-codes-menu-button {
    background-color: #f7f8fa;
    border-radius: 4px;
    border: none;
    height: 30px;
    width: 30px;
    outline: none !important;
}

.promo-codes-button-container .promo-codes-menu-button {
    opacity: 0;
}

.promo-codes-button-container:hover .promo-codes-menu-button {
    opacity: 1;
}

.promo-codes-row {
    height: 51px;
    background-color: #f3f4f8;
    border-radius: 4px;
    margin-bottom: 2px;
    border: 2px solid transparent;
}

.promo-codes-row:hover {
    border: 2px solid #3e6fd9;
    background-color: #eceef5;
}

.promo-codes-row div[class*='promo-codes-header-row-'] {
    padding-left: 10px;
}

.promo-codes-row.disabled {
    background-color: #d6dbec;
}

.promo-codes-text {
    height: 22px;
    color: #242c5c;
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
            user-select: none;
}

.promo-codes-text:hover {
    background-color: #f7f8fa;
    border-right: 8px solid #f7f8fa;
    position: absolute;
}

.promo-codes-text.active {
    color: #3e6fd9;
}

.promo-codes-triangle-icon {
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: #3e6fd9 transparent transparent transparent;
    margin-top: 5px;
}

.promo-codes-triangle-icon.asc {
    border-color: transparent transparent #3e6fd9 transparent;
    margin-top: 0px;
    margin: 0 0 5px;
}

.manage-user-wrapper {
    font-family: 'Inter';
}

.admin-password-container {
    width: 300px;
}

.manage-user-container {
    align-items: stretch;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 2px solid #cdd1e5;
    border-radius: 4px;
    padding: 20px;
    margin-right: 30px;
    min-height: 385px;
}

.manage-user-label {
    color: #465664;
    font-size: 22px;
}

.manage-users-button {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 48px;
    background-color: #3e6fd9;
    outline: none !important;
}

.manage-users-button span {
    height: 24px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 18px;
}

.information-modal {
    width: 500px;
    height: auto;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    font-family: 'Inter';
    padding: 20px;
}

.information-modal button {
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 56px;
    background-color: #3e6fd9;
}

.information-modal button span {
    height: 30px;
    font-size: 22px;
    text-transform: uppercase;
}

.information-modal-close {
    justify-content: flex-end;
    min-height: 20px;
}

.information-modal-text {
    width: 271px;
    height: 55px;
    color: #465664;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
}

.information-modal-button {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 48px;
    background-color: #3e6fd9;
    outline: none !important;
}
.information-modal-button:disabled {
    opacity: 0.5;
    transition: all 0.2s ease;
}

.information-modal-button span {
    height: 24px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 18px;
}

.information-modal-button-contener {
    width: 100%;
}

.users-table-container {
    height: 720px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.users-header-container-header {
    margin: 10px 0 10px 0;
    padding: 0 20px 0 10px;
}

.users-header-row-header {
    height: 22px;
    margin-bottom: 2px;
}

.users-header-indicator {
    height: 51px;
    min-width: 7px;
    max-width: 7px;
    background-color: #cdd1e5;
}

.users-header-indicator.transparent {
    background-color: transparent;
}

.users-rows-container {
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 20px 20px 10px;
    overflow-y: auto;
}

.users-row-text {
    height: 22px;
    color: #242c5c;
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-row-text:hover,
.users-row-full-name-text:hover {
    background-color: #eceef5;
    border-right: 8px solid #eceef5;
    position: absolute;
}

.users-row.disabled .users-row-text:hover,
.users-row.disabled .users-row-full-name-text:hover {
    background-color: #d6dbec;
    border-right: 8px solid #d6dbec;
}

.users-row-full-name-text {
    height: 22px;
    color: #3e6fd9;
    font-family: 'Inter';
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.users-header-row-date {
    min-width: 9%;
    flex: 1 1 120px;
    padding-left: 10px;
}

.users-header-row-created-by {
    min-width: 13%;
    flex: 1 1 160px;
}

.users-header-row-email {
    min-width: 13%;
    flex: 1 1 160px;
}

.users-header-row-subscription {
    min-width: 7%;
    flex: 1 1 80px;
}

.users-body-row-date {
    min-width: 9%;
    flex: 1 1 110px;
    padding-left: 10px;
}

.users-body-row-created-by {
    min-width: 13%;
    flex: 1 1 160px;
}

.users-body-row-email {
    min-width: 13%;
    flex: 1 1 200px;
}

.users-body-row-subscription {
    min-width: 7%;
    flex: 1 1 80px;
}

.users-button-container {
    min-height: 30px;
    min-width: 30px;
    position: absolute;
    right: 10px;
    -webkit-user-select: none;
            user-select: none;
}

.users-button-container:hover {
    z-index: 100;
    opacity: 1;
}

.users-menu-button {
    background-color: #f7f8fa;
    border-radius: 4px;
    border: none;
    height: 30px;
    width: 30px;
    outline: none !important;
}

.users-button-container .users-menu-button {
    opacity: 0;
}

.users-button-container:hover .users-menu-button {
    opacity: 1;
}

.users-row {
    height: 51px;
    background-color: #f3f4f8;
    border-radius: 4px;
    margin-bottom: 2px;
    border: 2px solid transparent;
}

.users-row:hover {
    border: 2px solid #3e6fd9;
    background-color: #eceef5;
}

.users-row.disabled {
    background-color: #d6dbec;
}

.users-text {
    height: 22px;
    color: #242c5c;
    font-size: 16px;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
            user-select: none;
}

.users-text:hover {
    background-color: #f7f8fa;
    border-right: 8px solid #f7f8fa;
    position: absolute;
}

.users-text.active {
    color: #3e6fd9;
}

.users-triangle-icon {
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: #3e6fd9 transparent transparent transparent;
    margin-top: 5px;
}

.users-triangle-icon.asc {
    border-color: transparent transparent #3e6fd9 transparent;
    margin-top: 0px;
    margin: 0 0 5px;
}

.custom-search-input-users {
    max-width: 300px;
    margin-bottom: 25px;
}

.pagination-container {
    margin-top: 10px;
}

.pagination-container button:hover {
    background-color: #3061ae;
}

.pagination-next {
    border-radius: 4px;
    border: none;
    width: auto;
    height: 39px;
    background-color: #3e6fd9;
    outline: none !important;
    padding: 0 10px;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    margin-left: 20px;
    color: white;
}

.pagination-prev {
    border-radius: 4px;
    border: none;
    width: auto;
    height: 39px;
    background-color: #3e6fd9;
    outline: none !important;
    padding: 0 10px;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    color: white;
}

.message-modal {
    width: 350px;
    height: 300px;
}

.message-modal-header {
    height: 25%;
    align-items: center;
    justify-content: space-between;
    background-color: #fce8e9;
    border-radius: 4px 4px 0 0;
    padding: 10px 28px;
}

.message-modal-header h2 {
    font-size: 1.6rem;
    margin: 0;
    color: #cc4b4f;
}

.message-modal-text {
    height: 100%;
}
.statistics-text {
    height: 22px;
    color: #242c5c;
    font-size: 18px;
    white-space: nowrap;
}

.ml-600 {
    margin-left: 600px;
}

.mr-20 {
    margin-right: 20px;
}

.global-configurations-wrapper {
    font-family: 'Inter';
}

.admin-url-input-container {
    width: 560px;
}

.download-error-message {
    font-size: 35px;
    color: #465664;
}

.global-configuration-container {
    align-items: stretch;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 2px solid #cdd1e5;
    border-radius: 4px;
    padding: 20px;
    margin-right: 30px;
    min-height: 360px;
}

.global-configuration-label {
    height: 32px;
    color: #465664;
    font-size: 24px;
}

.global-configurations-button {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 48px;
    background-color: #3e6fd9;
    outline: none !important;
}

.global-configurations-button span,
.global-configurations-small-button span,
.global-configurations-remove-button span {
    height: 24px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 18px;
}

.global-configurations-small-button,
.global-configurations-remove-button {
    width: 186px;
    height: 48px;
    border: none;
    border-radius: 4px;
    background-color: #3e6fd9;
    outline: none !important;
    margin-top: 10px;
    margin-left: 10px;
}

.global-configurations-remove-button {
    background-color: #ed5e68;
}

.global-configurations-remove-button:disabled {
    background-color: rgb(237, 94, 104, 0.5);
    transition: all 0.15s ease-in;
}

.global-configurations-small-button:disabled {
    background-color: rgba(62, 111, 217, 0.5);
    transition: all 0.15s ease-in;
}

.global-configurations-filename-span {
    font-size: 16px;
    width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}

.admin-url-input-container__dropdown {
   position: absolute;
}
.issue-box {
    width: 100%;
}

.issue-side {
    width: 100px;
    min-height: 120px;
    position: relative;
}
.issue-status {
    margin: 18px 15px;
    width: 54px;
    height: 54px;
    font-size: 35px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-family: 'Inter';
    border-radius: 50%;
    -webkit-user-select: none;
            user-select: none;
}
.issue-fixed {
    background-color: #5ac57a;
    border-color: #5ac57a;
    color: white;
}
.issue-ongoing {
    background-color: #ffcd67;
    border-color: #ffcd67;
    color: white;
}
.issue-line-container {
    height: 100%;
    width: 100%;
}
.issue-line {
    width: 2px;
    background-color: #242c5c;
    height: 100%;
    position: relative;
    left: 51px;
}

.issue-title {
    font-size: 22px;
    text-align: left;
    font-family: 'Inter';
}
.issue-date {
    font-size: 16px;
    font-family: 'Inter';
}

.issue-text-body {
    text-align: justify !important;
    font-family: 'Inter';
}

.issue-info-container {
    width: 100%;
    padding-top: 25px;
}

.withPointer {
    cursor: pointer;
}

.admin-delete-btn {
    position: absolute;
    top: 90px;
    left: 18px;
    background-color: #f0383e;
    border-radius: 5px;
    padding: 5px;
    z-index: 2;
    color: #fff;
    font-family: 'Inter';
    cursor: pointer;
    border: none;
    width: 68.5px;
    overflow: hidden;
}

.admin-delete-btn:disabled {
    background-color: grey;
}

.issues-tab {
    overflow: hidden;
    padding-bottom: 60px;
}
.issue-create-container {
    border-radius: 10px;
    border: 2px solid #cdd1e5;
    padding: 10px;
}

.button-create {
    width: 192px;
    height: 48px;
    border: none;
    border-radius: 4px;
    background-color: #3e6fd9;
    outline: none !important;
    margin-top: 10px;
    margin-left: 10px;
    color: white;
}
.button-create:disabled {
    background-color: grey;
}
.issue-display {
    margin-top: 2em;
}

.prefab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #CDD1E5;
    padding: 15px 15px;
    margin-bottom: 15px;
}

.prefab_deleted {
    background-color: #C2D4FA;;
}

.prefab-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 15px;
}

.prefab-box-text {
    text-align: left;
    word-break: break-all;
}

.prefab-box__textarea {
    width: 100%;
}

.prefab-box-info p {
    margin-bottom: 0;
    margin-right: 10px;
    color: #A6A6A6;
    font-size: 12px;
}

.prefab-button {
    min-width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #3E6FD9;
    color: #3E6FD9;
    background-color: #f8f9fa;
}

.prefab-button_deleted {
    color: #f8f9fa;
    background-color: #3E6FD9;
}

.prefab-button:hover {
    background-color: #3E6FD9;
    color: #fff;
}

.prefab-button.disabled {
    color: #A6A6A6;
    border: 2px solid #A6A6A6;
}

.prefab-button.disabled:hover {
    color: #fff;
    border: 2px solid #A6A6A6;
    background-color: #A6A6A6;
}
.add-new-prefab-modal {
    width: 50vw;
    height: 50vh;
    border-radius: 4px;
    position: relative;
    font-family: "Inter";
    justify-content: space-between;
}

.add-new-prefab-modal-header {
    align-items: center;
    justify-content: space-between;
    background-color: #eceef5;
    padding: 25px 25px;
}

.add-new-prefab-modal-header h2 {
    font-size: 30px;
    color: #242c5c;
    margin: 0;
    font-weight: 400;
}

.add-new-prefab-modal-body {
    margin: 0 25px;
}

.add-new-prefab-modal-body__container {
    margin: 25px 0;
}

.add-new-prefab-modal-body__textarea {
    height: 60%;
}

.add-new-prefab-modal-footer {
    margin: 0 25px 25px 0;
}

.add-new-prefab-modal-footer-buttons__button {
    border: none;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    font-size: 16px;
}

.add-new-prefab-modal-footer-buttons__button_save {
    color: white;
    background-color: #3E6FD9;
}

.add-new-prefab-modal-footer-buttons__button_save:hover {
    background-color: #3061ae;
}

.add-new-prefab-modal-footer-buttons__button_cancel {
    background-color: transparent;
    color: #888da8;
}
.answers-prefab-modal {
  width: 622px;
  height: 662px;
}

.answers-prefab-modal-header {
  height: 13%;
  align-items: center;
  justify-content: space-between;
  background-color: #fce8e9;
  border-radius: 4px 4px 0 0;
  padding: 10px 28px;
}

.answers-prefab-modal-header h2 {
  font-size: 1.6rem;
  margin: 0;
  color: #cc4b4f;
}

.answers-prefab-modal-list {
  margin: 20px;
}

.answers-prefab-modal-list__name {
  border: 2px solid #eceef5;
  border-radius: 4px;
  margin: 0 0 15px 0;
  padding: 5px 25px;
  width: 100%;
}

.answers-prefab-modal-list__name p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  font-weight: 500;
  min-height: 9vh;
  font-size: 20px;
  text-align: left;
}

.answers-prefab-modal__button {
  color: #fff;
  background-color:  #3e6fd9;
  margin: 0 20px 10px;
  border: none;
  border-radius: 4px;
  height: 10%;
  font-size: 20px;
}
.prefabs-chat-gtp {
    padding: 0 15px;
}

.prefabs-chat-gtp-header {
    font-size: 24px;
    text-align: left;
}

.prefabs-chat-gtp__optinon {
    margin-top: 20px;
}

.prefabs-chat-gtp__input {
    margin-right: 20px;
}

.prefabs-chat-gtp__textarea {
    margin-top: 20px;
    width: 50%;
}

.prefabs-chat-gtp-button {
    border: none;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    font-size: 16px;
}

.prefabs-chat-gtp-button.add {
    color: #3E6FD9;
    background-color: #C2D4FA;
}

.prefabs-chat-gtp-button.add:hover {
    border: 2px solid #3E6FD9;
}

.prefabs-chat-gtp-button.save {
    margin-left: 20px;
    color: white;
    background-color: #3E6FD9;
}

.prefabs-chat-gtp-button.save:hover,
.prefabs-chat-gtp-button.delete:hover,
.prefabs-chat-gtp-button.test-button:hover {
    background-color: #3061ae;
}

.prefabs-chat-gtp-button.delete,
.prefabs-chat-gtp-button.test-button {
    margin-right: 20px;
    color: #fff;
    background-color:  #3e6fd9;
    min-width: 180px;
}

.prefabs-chat-gtp-button.test-button {
    margin-left: 20px;
}

.prefabs-chat-gtp-button.delete:disabled {
    opacity: 0.5;
}

.prefabs-chat-gtp-delete-modal button {
    min-width: 240px;
}
.Plans_plan-dialog__3HO3W {
    width: 348px;
    height: 308px;
}

.Plans_header-dialog__2q5Jx {
    font-size: 24px;
    font-weight: 500;
}

.Plans_dialog-close__1vcnS {
    width: 22px;
}

.Plans_top-dialog__3-OUa {
    padding: 30px;
    height: 90px;
    background-color: #eceef5;
}

.Plans_modal-adding-part__1LDQG {
    height: 218px;
    padding: 30px;
    padding-top: 16px;
}

.Plans_number-field__1YcDn {
    margin-top: 20px;
    margin-left: 20px;
}

.Plans_type-plan__2Mh5s {
    width: 371px;
}

.Plans_plan-subscription__36Dj1 {
    min-height: 500px;
    margin-top: 20px;
    padding: 20px 30px;
    width: 300px;
    background-color: white;
}

.Plans_plan-tokens-dialog__1Y8uw {
    width: 346px;
    height: 308px;
}

.Plans_modal-adding-tokens-part__2CZeB {
    height: 220px;
    padding: 30px;
    padding-top: 15px;
}

.Plans_plan-monthly__gLHTA {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 100;
}

.Plans_small-description-input__3_9Ok {
    height: 26px !important;
    border: 0 !important;
    background-color: #f6f7f9 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    text-align: left;
    color: #242c5c !important;
    padding-left: 15px !important;
    margin-left: -15px;
    width: 256px !important;
}

.Plans_save-description__1GDbF {
    background-color: #3e6fd9;
    height: 26px;
    position: absolute;
    outline: 0 !important;
    border: 0;
    left: 286px;
    width: 22px;
}

.Plans_save-description__1GDbF:hover {
    background-color: #3061ae;
}

.Plans_active-plan-item__2KESv:hover {
    cursor: pointer;
    color: #3e6fd9;
}

.Plans_plan__21mG5 {
    margin-bottom: 50px;
}

.Plans_plus-icon__354zv {
    width: 18px;
    position: absolute;
}

.Plans_close-description__1e1X2 {
    background-color: #fde8e9;
    height: 26px;
    position: absolute;
    outline: 0 !important;
    border: 0;
    left: 264px;
    width: 22px;
    z-index: 2;
}

.Plans_close-description__1e1X2:hover {
    fill: white !important;
    background-color: #cc4b4f;
}

.Plans_close-description__1e1X2:hover .Plans_icon-close__32v56 {
    fill: white;
}

.Plans_icon-close__32v56 {
    fill: #f1666a;
}

.Plans_remove-description-field__dA604 {
    height: 43px !important;
    min-height: 43px !important;
    max-height: 43px !important;
    margin-right: 0 !important;
    width: 130px !important;
    font-weight: 500 !important;
    border-radius: 0;
}

.Plans_add-description-field__2_AR1 {
    max-width: 130px !important;
    min-width: 130px !important;
    margin-top: 0 !important;
}

.admin-panel-tabs {
    height: 55px;
    background-color: #eceef5;
}

.PromoCodeModal_radio-input__1-dDR {
    height: 39px;
    width: 100%;
}

.promocode-dialog-wrapper {
    width: 622px;
    height: 640px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
}

.promocode-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
}

.promocode-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    height: 33px;
}

.promocode-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 85px);
}

.promocode-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 17px;
}

.promocode-dialog-buttons {
    position: absolute;
    right: 32px;
    bottom: 17px;
}

.promocode-dialog-button {
    border: none;
    min-width: 180px;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.promocode-dialog-button.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.promocode-dialog-label {
    color: #465664;
    font-size: 16px;
    height: 24px;
}

.mr-20 {
    margin-right: 20px;
}

.promocode-input-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
}

.mr-30 {
    margin-right: 30px;
}

.promocode-dialog-label.error-message {
    color: #ce3030;
}

.promocode-copy-button {
    border: none;
    min-width: 50px;
    height: 25px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 4px;
    outline: none !important;
}

.admin-panel-wrapper {
    margin: 0 40px;
    font-family: 'Inter';
}

.admin-panel-header-top {
    height: 84px;
}

.admin-panel-header-title {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    margin-top: 30px;
}

.admin-panel-wrapper hr {
    width: 100%;
    margin: 0 0 20px 0;
    color: #eceef5;
}

.user-dialog-wrapper {
    width: 622px;
    height: 710px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    font-family: 'Inter';
}

.user-dialog-header {
    width: 100%;
    height: 85px;
    background-color: #eceef5;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
}

.user-dialog-title {
    color: #242c5c;
    font-family: 'Inter';
    font-size: 26px;
    height: 33px;
}

.user-dialog-body {
    padding: 26px 32px;
    width: 100%;
    height: calc(100% - 85px);
}

.mr-20 {
    margin-right: 20px;
}

.modal-user-change-pass-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.modal-user-change-password {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.modal-users-button {
    width: 230px;
    border: none;
    border-radius: 4px;
    height: 42px;
    background-color: #3e6fd9;
    outline: none !important;
    margin-left: 30px;
    margin-top: 24px;
}

.modal-users-button span {
    height: 24px;
    color: #ffffff;
    font-family: 'Inter';
    font-size: 18px;
}

.user-modal-change-roles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.user-modal-change-features {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.modal-user-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    font-size: 18px;
    color: #242c5c;
}

.feature-hover-style {
    margin-left: 30px;
    border-bottom: 1px dotted #242c5c;
}

.terms-of-service-container {
    text-align: left;
    padding: 50px;
}

.center {
    text-align: center;
}

.terms-of-service-container p:not(.center) {
    text-align: justify;
}

.privacy-policy-container {
    text-align: left;
    padding: 50px;
}

.privacy-policy-header {
    font-weight: bolder;
    text-decoration: underline;
}

.privacy-policy-container p:not(.center) {
    text-align: justify;
}

.terms-of-service-container {
    text-align: left;
    padding: 50px;
}

.center {
    text-align: center;
}

.terms-of-service-container p:not(.center) {
    text-align: justify;
}

.image-container-faq {
    width: 90mm;
    height: 85mm;
    right: 3.6em;
    top: 0;
    border-radius: 3px;
    margin: 40px 150px;
}

.image-container-faq img {
    max-width: 100%;
    max-height: 100%;
}
.article-title {
    font-size: 30px;
}

.success-confirm-email {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 90px;
    margin-top: 80px;
}

.issues-main {
    padding-bottom: 60px;
}
.issue-display-container {
    align-self: center;
    width: 90%;
}
.issue-display-container h2 {
    font-family: 'Inter';
}
.issue-display-title {
    font-size: 24px;
    text-align: left;
    width: 95%;
    align-self: center;
    font-family: 'Inter';
}

.dependency-matrix-wrapper {
    margin: 90px 40x 0;
    font-family: 'Inter';
    min-height: 500px;
}

.view-dependency-matrix-wrapper {
    margin: 10px 0 0;
}

.component-cell-container:hover span {
    color: #3e6fd9;
}

.dependency-matrix-header-tutorial {
    margin: 10px 0 20px 0;
}

.dependency-matrix-title {
    height: 42px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 30px;
    white-space: nowrap;
    margin-right: auto;
}

.dependency-matrix-table-wrapper {
    margin-top: 50px;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 190px);
}

.dependency-matrix-container {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    overflow-x: auto;
    overflow-y: auto;
}

.table-cell-container {
    width: 63px;
    height: 34px;
    border: 1px solid #d7dbe8;
}

.table-cell {
    border-radius: 4px;
    width: 63px;
    height: 34px;
    background-color: #f7f8fa;
}

.table-cell.pointer.hover {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    box-shadow: 0 3px 6px rgba(36, 44, 92, 0.4);
    border: 1px solid #3e6fd9;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
}

.attribute-cell {
    width: 120px;
    height: 34px;
    justify-content: flex-start;
    border: 1px solid transparent;
    z-index: 1;
}

.attribute-cell:hover {
    border: 1px solid #3e6fd9;
    border-radius: 4px;
}

.matrix-attribute-title {
    height: auto;
    min-height: 13px;
    color: #5b6185;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    max-height: 34px;
    max-width: 100%;
    word-break: break-word;
    overflow: hidden;
}

.matrix-attribute-title.column {
    text-align: center;
}

.component-cell {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    width: 123px;
    height: 100%;
}

.component-cell:hover {
    box-sizing: border-box;
    text-decoration: underline;
    color: #3e6fd9;
    cursor: pointer;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #3e6fd9;
}

.matrix-component-title {
    display: block;
    min-height: 16px;
    height: auto;
    max-height: 34px;
    line-height: 18px;
    color: #5b6185;
    font-size: 13px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 130px;
}

.matrix-component-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.matrix-component-title-container:hover {
    background-color: white;
    border-radius: 4px;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
    border: 1px solid #3e6fd9;
}

.matrix-component-title-container:hover .matrix-component-title {
    color: #3e6fd9;
}

.matrix-attribute-title-container {
    width: 63px;
    height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.matrix-attribute-title-container:hover {
    background-color: white;
    border-radius: 4px;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
    border: 1px solid #3e6fd9;
}

.matrix-attribute-title-container:hover span {
    color: #3e6fd9;
}

.dependency-matrix-table td {
    border: 1px solid #d7dbe8;
}

.dependency-matrix-table tr td:last-of-type {
    border-right: none;
}

.dependency-matrix-table tr td:first-of-type {
    border-right: none;
    border-left: none;
}

.dependency-matrix-table tr:first-of-type td {
    border-top: none;
    border-bottom: none;
    z-index: 3;
}

.dependency-matrix-table tr:nth-of-type(2) td {
    border-top: none;
}

.dependency-matrix-table tr:last-of-type td {
    border-bottom: none;
}

.component-type-indicator-container {
    background: #f7f8fa;
    margin: 0;
    width: 87px;
    min-width: 87px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    padding-top: 72px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;
}

.dependency-matrix-table tr:nth-of-type(1) td:not(:first-of-type) {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 87px;
}

.dependency-matrix-table tr:nth-of-type(2) td {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    top: 36px;
    left: 87px;
    box-shadow: 0px 2px 0px -1px #d7dbe8;
}

td.component-cell-container {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 87px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

tr td:first-of-type.component-cell-container {
    border-bottom: none;
}

td.attribute-cell-container {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 209px;
    box-shadow: 2px 0px 0px -1px #d7dbe8;
    z-index: 1;
}

.attribute-cell-container:hover {
    background-color: white;
    color: #3e6fd9;
    text-decoration: underline;
    cursor: pointer;
}

.attribute-cell-container:hover span {
    color: #3e6fd9;
}

.internal-type-indicator {
    height: 120px;
    width: 27px;
    border-radius: 4px;
    background-color: #eceef5;
}

.external-type-indicator {
    height: 120px;
    width: 27px;
    border-radius: 4px;
    background-color: #dfe4f4;
}

.component-type-text span {
    height: 49px;
    color: rgba(36, 44, 92, 0.75);
    font-size: 14px;
}

.transform-90-deg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.component-type-text:hover {
    position: absolute;
}

td.highlighted {
    background-color: #d9e2f6 !important;
}

.matrix-corner-cell {
    background-color: #f7f8fa;
    position: -webkit-sticky;
    position: sticky;
    left: 87px;
    top: 0;
    box-shadow: 2px 2px 0px -1px #d7dbe8;
    z-index: 1;
}

.table-cell-container {
    position: relative;
}

.cell-mark span {
    font-size: 18px;
    font-family: 'Inter';
    color: black;
}

.matrix-input-container {
    margin: 0 0 10px 20px;
}

.matrix-input-container.first {
    margin-left: auto;
}

@media only screen and (max-width: 980px) {
    .matrix-input-container .custom-search-input .custom-search-input-input {
        max-width: 220px;
    }

    .matrix-input-container {
        margin-left: 10px;
    }
}

.cell-mark span.cell-binary-text {
    color: #242c5c;
    white-space: pre-wrap;
}

.matrix-placeholder {
    padding-top: 30px;
}

.matrix-placeholder span {
    height: 38px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 22px;
}

.cell-dropdown {
    position: absolute;
    width: 415px;
    left: calc(50% - 415px / 2);
    top: 50px;
    background-color: white;
    border: 2px solid lightblue;
    border-radius: 5px;
    z-index: 3;
}

.cell-dropdown::before {
    content: '';
    height: 0;
    width: 0;
    top: -21px;
    left: calc(50% - 17px);
    position: absolute;
    border: 17px solid transparent;
    border-bottom: 20px solid lightblue;
    border-top: 0;
}

.cell-dropdown::after {
    content: '';
    height: 0;
    width: 0;
    top: -18px;
    left: calc(50% - 15px);
    position: absolute;
    border: 15px solid transparent;
    border-bottom: 18px solid white;
    border-top: 0;
}

.cell-dropdown-row {
    height: 40px;
    width: 400px;
    margin-top: 5px;
    background-color: #e8f6ec;
    border-radius: 4px;
    position: relative;
    border: 2px solid transparent;
}

.cell-dropdown-row-text {
    height: 21px;
    color: #242c5c;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-dropdown-row.green {
    background-color: #e9f6ed;
}

.cell-dropdown-row.yellow {
    background-color: #fff4de;
}

.cell-dropdown-row.red {
    background-color: #fde6e1;
}

.cell-dropdown-row.white {
    background-color: #ffffff;
    border: 1px solid #cdd1e5;
}

.cell-dropdown-row:hover {
    border: 2px solid #3e6fd9;
}

.cell-dropdown-row:hover.green {
    background-color: #d0e9d8;
}

.cell-dropdown-row:hover.yellow {
    background-color: #f3e3c4;
}

.cell-dropdown-row:hover.red {
    background-color: #f0cec7;
}

.cell-dropdown-row-rating {
    flex: 1 1 50px;
    min-width: 5%;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.cell-dropdown-row-name {
    flex: 1 1 345px;
    min-width: 25%;
}

.cell-dropdown-idea-score {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #5ac57a;
}

.cell-dropdown-idea-score span {
    height: 26px;
    color: #ffffff;
    font-size: 20px;
    margin-left: 1.5px;
}

.cell-dropdown-row-color-rating {
    height: 100%;
    width: 7px;
    min-width: 7px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.cell-dropdown-row:hover .cell-dropdown-row-color-rating {
    height: 100%;
    border-radius: 2px 0 0 2px;
}

.cell-dropdown-row-buttons-container {
    height: 51px;
    position: absolute;
    right: 0;
    opacity: 0;
}

.cell-dropdown-row:hover .cell-dropdown-row-buttons-container {
    opacity: 1;
}

.open-details-button {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #dfe7fa;
    padding: 0 10px 0 20px;
    justify-content: space-between;
    align-items: center;
    outline: none;
}

.open-details-button span {
    font-family: 'Inter';
    color: #3e6fd9;
    font-size: 14px;
}

.open-details-button svg {
    color: #3e6fd9;
}

.open-details-button:hover {
    background-color: #3e6fd9;
}

.open-details-button:hover span {
    color: white;
}

.open-details-button:hover svg {
    color: white;
}
.open-details-button .button-triangle-icon {
    margin-left: 5px;
}

.open-details-button:hover .button-triangle-icon {
    border-color: transparent transparent transparent white;
}

.open-details-button.edit {
    margin-right: 10px;
    width: 77px;
    height: 30px;
    outline: none;
    padding: 0 10px 0 10px;
}

.open-details-button.medium {
    outline: none;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #3e6fd9;
}

.header {
  width: 100%;
  background-color: rgb(36, 44, 92);
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: 600;
}

.header-logo {
  img {
    height: 25px;
    width: 25px;
    margin-top: 3px;
    margin-right: 5px;
  }
}

.header-title {
  font-weight: 500;
}
