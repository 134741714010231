.dialog {
    height: 360px;
    width: 500px;
}

.header {
    height: 80px;
    padding: 0px 42px;
}

.close-icon {
    padding: 20px;
    margin-right: -20px;
}

.close-icon:hover {
    cursor: pointer;
}

.title-header {
    color: #242c5c;
    font-weight: 600;
    margin-left: 10px;
    font-size: 20px;
}

.close-button {
    border: none;
    background: none;
}

.buy-button {
    margin: 0 !important;
}

.body-label {
    font-size: 20px;
    font-weight: 500;
    padding: 30px 20px 42px 20px;
}

.purchase-button {
    margin-top: 18px;
    border: 2px #3e6fd9 solid !important;
    background-color: white !important;
    color: #3e6fd9 !important;
}

.purchase-button:hover {
    background-color: #f5f5f5 !important;
}

.default-button {
    height: 42px !important;
    width: 80% !important;
    text-align: center;
    font-weight: 600;
}

.icon-generate {
    color: #cc4b4f;
    height: 24px;
    width: 24px;
}
